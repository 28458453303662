/* import __COLOCATED_TEMPLATE__ from './install-an-app-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  appService: service(),
  onboardingHomeExternalStepService: service(),
  router: service(),
  intercomEventService: service(),

  step: null,
  completionCallback: null,

  isBetaStep: equal('step.identifier', 'check_out_app_store'),

  trackStartedTourAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.step.analyticsMetadata,
      action: 'started',
      object: `${this.step.identifier}_tour`,
      section: 'index',
    });
  },

  visitAppStore: action(function () {
    this.trackStartedTourAnalyticsEvent();

    this.onboardingHomeExternalStepService.startAfterTransition(this.step.identifier, {
      source: 'home/install-an-app-component visitAppStore',
      method: 'startAfterTransition',
    });

    this.router.transitionTo('apps.app.appstore', {
      queryParams: { category: null, capability: null, shouldShowStepBanner: true },
    });
  }),
});
