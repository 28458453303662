/* import __COLOCATED_TEMPLATE__ from './security.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import { action } from '@ember/object';
import Component from '@glimmer/component';

interface WorkspaceSettingsArgs {
  attachmentSettings: $TSFixMe;
  userSources: $TSFixMe;
  pingDomains: $TSFixMe;
  settings: $TSFixMe;
  idvSettings: $TSFixMe;
  reminder: $TSFixMe;
  apiSecrets: {
    ios: string;
    android: string;
  };
  initialProvisioningMethod: string;
  selectedProvisioningMethod: string;
  section: string;
  changeTab: (tab: string, section: string) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: WorkspaceSettingsArgs;
}

export default class Security extends Component<Signature> {
  @action
  selectTab(tab: string, sectionId = '') {
    this.args.changeTab(tab, sectionId);
  }

  @action
  changeTabAndOpenSection(tab: string, sectionId: string) {
    this.selectTab(tab, sectionId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security': typeof Security;
    'new-settings/workspace/security': typeof Security;
  }
}
