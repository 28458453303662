/* import __COLOCATED_TEMPLATE__ from './forward-emails-with-tech-bundle.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { isValidEmail } from '@intercom/pulse/lib/computed-properties';
import { timeout } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import storage from 'embercom/vendor/intercom/storage';
import { action } from '@ember/object';

const LOCALSTORAGE_EMAIL_KEY = 'forward-emails-component-email';

export default class ForwardEmailsWithTechBundleComponent extends Component {
  @service store;
  @service realTimeEventService;
  @service intercomEventService;
  @service appService;

  @tracked email = this.args?.email || '';
  @tracked emailSet = false;
  @tracked verification = null;

  @isValidEmail('email') isValidEmail;

  maxRetryAttempts = 10;

  get app() {
    return this.appService.app;
  }

  get inboxEmailAddress() {
    return this.app?.inbox_email;
  }

  get hasForwardingEmail() {
    return this.email !== '';
  }

  get inboxAddress() {
    if (this.inboxEmailAddress) {
      return this.inboxEmailAddress;
    } else {
      return this.args.incomingEmailAddress;
    }
  }

  constructor() {
    super(...arguments);
    this.restoreEmailFromStorage();
    this.realTimeEventService.on('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
  }

  willDestroy() {
    this.realTimeEventService.off('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
    super.willDestroy(...arguments);
  }

  @dropTask
  *verifyEmailForwarding() {
    this._trackAnalyticsEvent({ action: 'clicked', object: 'send_test_email' });
    let verification;
    if (this.args.onTechSetupPage) {
      verification = yield this._verifyEmailForwardingTechBundle();
      verification = verification['verified'];
    } else {
      yield this._createSetupAttempt();
      yield this._sendForwardingEmail();

      verification = yield this._verifyEmailForwarding();
      try {
        while (!verification && this.retryAttempts < this.maxRetryAttempts) {
          timeout(ENV.APP._1000MS); // wait for nexus event
          this.retryAttempts++;
          verification = yield this._verifyEmailForwarding();
        }
      } catch (e) {
        captureException(e, {
          fingerprint: ['forward-emails', 'verify_email_forwarding'],
        });
        this.retryAttempts = 0;
      }
    }

    if (!this.verification) {
      this._updateVerification(verification);
      this._trackAnalyticsEvent({
        action: verification ? 'completed' : 'failed',
        context: 'verify_endpoint',
        object: 'send_test_email',
      });
    }
  }

  _updateVerification(status) {
    this.verification = status;
    if (this.verification) {
      this.args.completionCallback?.();
    }
  }

  async _verifyEmailForwardingTechBundle() {
    this._trackAnalyticsEvent({ action: 'clicked', object: 'send_test_email' });
    let token = this.args.authToken;
    return await ajax({
      url: `/ember/tech_setup_invite_validations/${token}?method=verify_email_forwarding&email=${this.args.techBundleEmail}&valid_until=${this.args.validUntil}&forwarded_email=${this.email}`,
      type: 'GET',
    });
  }

  async _verifyEmailForwarding() {
    if (!this.app?.id) {
      return;
    }
    return await ajax({
      url: '/ember/forward_mail_setup_attempts/verify',
      type: 'GET',
      data: {
        app_id: this.app.id,
        forwarding_email_address: this.email,
      },
    });
  }

  async _createSetupAttempt() {
    if (!this.app?.id) {
      return;
    }
    this.forwardMailSetupAttempt = await this.store
      .createRecord('forward-mail-setup-attempt', {
        appId: this.app.id,
        forwardingEmailAddress: this.email,
      })
      .save();
  }

  async _sendForwardingEmail() {
    if (!this.app?.id) {
      return;
    }
    await ajax({
      url: '/ember/forward_emails',
      data: JSON.stringify({
        app_id: this.app.id,
        email: this.email,
      }),
      type: 'POST',
      contentType: 'application/json',
      dataType: 'JSON',
    });
  }

  restoreEmailFromStorage() {
    if (this.email !== '') {
      return;
    }
    let email = storage.get(LOCALSTORAGE_EMAIL_KEY);
    if (!email) {
      return;
    }
    this.email = email;
    this.emailSet = true;
  }

  @action
  setEmail() {
    if (this.isValidEmail) {
      storage.set(LOCALSTORAGE_EMAIL_KEY, this.email);
      this.emailSet = true;
      this._trackAnalyticsEvent({
        action: 'clicked',
        object: 'set_email',
      });
    }
  }

  @action
  unsetEmail() {
    storage.remove(LOCALSTORAGE_EMAIL_KEY);
    this.verification = null;
    this.emailSet = false;
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'unset_email',
    });
  }

  @action
  markAsCompleted() {
    this.args.completionCallback?.('completed');
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'mark_as_completed',
    });
  }

  _handleForwardMailVerifiedMessage() {
    this._updateVerification(true);
    this._trackAnalyticsEvent({
      action: 'completed',
      object: 'send_test_email',
      context: 'nexus_event',
    });
  }

  _trackAnalyticsEvent(options) {
    this.intercomEventService.trackAnalyticsEvent({
      email: this.email,
      ...options,
      ...this.args.step.analyticsMetadata,
    });
  }
}
