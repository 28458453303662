/* import __COLOCATED_TEMPLATE__ from './alias-email-address-deletion.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';

interface Args {
  toggleCustomDeletionModal: () => void;
  showAliasEmailAddressModal: boolean;
  emailAddress: string;
  brand: string;
  admins: AdminSummary[];
}

export default class AliasEmailAddressDeletion extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get modalSections() {
    return [
      {
        containerClass: 'new-settings__home-section flex flex-col gap-2 cursor-pointer',
        headerKey: 'new-settings.channels.email.addresses.sidebar.delete-modal.brand.header',
        bodyKey: 'new-settings.channels.email.addresses.sidebar.delete-modal.brand.body',
        params: { brand: this.args.brand, htmlSafe: true },
        shouldRedirect: true,
      },
      {
        containerClass: 'border rounded-3xl p-6 mb-4 flex flex-col gap-2',
        headerKey: 'new-settings.channels.email.addresses.sidebar.delete-modal.alias.header',
        bodyKey: 'new-settings.channels.email.addresses.sidebar.delete-modal.alias.body',
        params: {},
        shouldRedirect: false,
      },
    ];
  }

  @action
  redirectUrl() {
    let url = this.router.urlFor('apps.app.settings.workspace.brands', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }

  @action
  closeAliasEmailAddressDeletion() {
    this.args.toggleCustomDeletionModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::AliasEmailAddressDeletion': typeof AliasEmailAddressDeletion;
    'new-settings/channels/email/addresses/alias-email-address-deletion': typeof AliasEmailAddressDeletion;
  }
}
