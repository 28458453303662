/* import __COLOCATED_TEMPLATE__ from './setup-custom-data-attributes-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  INSTALL_METHODS as MESSENGER_INSTALL_METHODS,
  INSTALL_METHOD_ANALYTICS_NAMES,
} from 'embercom/lib/messenger/installation-constants';
import { captureException } from 'embercom/lib/sentry';
import ajax from 'embercom/lib/ajax';
import { restartableTask } from 'ember-concurrency-decorators';

export const USE_CASE_DEFAULT_ATTRIBUTES = {
  marketing: [{ name: 'Pricing plan' }, { name: 'Subscription status' }], // eslint-disable-line @intercom/intercom/no-bare-strings
  support: [{ name: 'Power user' }, { name: 'On trial' }], // eslint-disable-line @intercom/intercom/no-bare-strings
  other: [{ name: 'Home address' }], // eslint-disable-line @intercom/intercom/no-bare-strings
};

export const INSTALL_TYPES = [
  {
    text: 'with-code',
    value: 'code',
  },
  {
    text: 'with-app',
    value: 'third-party',
  },
];

export const INSTALL_METHODS = {
  code: [
    MESSENGER_INSTALL_METHODS.JAVASCRIPT,
    MESSENGER_INSTALL_METHODS.SINGLE_PAGE,
    MESSENGER_INSTALL_METHODS.RAILS,
    MESSENGER_INSTALL_METHODS.IOS,
    MESSENGER_INSTALL_METHODS.ANDROID,
    MESSENGER_INSTALL_METHODS.CORDOVA,
  ],
  'third-party': [
    MESSENGER_INSTALL_METHODS.SEGMENT,
    MESSENGER_INSTALL_METHODS.WORDPRESS,
    MESSENGER_INSTALL_METHODS.SHOPIFY,
  ],
};

export const VERIFICATION_ERROR_TEXT =
  'onboarding.home.steps.setup-custom-data-attributes-component.verification-error-text';

export const VERIFICATION_REQUEST_FAILED_TEXT =
  'onboarding.home.steps.setup-custom-data-attributes-component.verification-request-failed-text';

export default class SetupCustomDataAttributesComponent extends Component {
  @service intl;
  @service router;
  @service appService;
  @service intercomEventService;

  @tracked selectedInstallType = 'code';
  @tracked selectedInstallMethod = null;
  @tracked attributes = null;
  @tracked verified = this.args.verified;
  @tracked showCodeInstructions = this.args.showCodeInstructions;
  @tracked errorMessage = null;
  @tracked useCase = Object.keys(USE_CASE_DEFAULT_ATTRIBUTES)[0];

  constructor() {
    super(...arguments);
    this.resetAttributes(this.useCase);
    this.selectedInstallMethod = this.defaultSelectedInstallMethod;
  }

  get app() {
    return this.appService.app;
  }

  get installTypes() {
    return INSTALL_TYPES;
  }

  get installMethodComponentName() {
    return `onboarding/home/steps/setup-custom-data-attributes/install-methods/${this.selectedInstallType}`;
  }

  get installMethodOptions() {
    return INSTALL_METHODS[this.selectedInstallType];
  }

  get defaultSelectedInstallMethod() {
    return INSTALL_METHODS[this.selectedInstallType][0].value;
  }

  get installationMethodAnalyticsMetadata() {
    return {
      ...this.args.step.analyticsMetadata,
      install_language: this.showCodeInstructions
        ? INSTALL_METHOD_ANALYTICS_NAMES[this.selectedInstallMethod]
        : undefined,
      install_with: this.selectedInstallType,
    };
  }

  @action
  resetAttributes(useCase) {
    this.attributes = USE_CASE_DEFAULT_ATTRIBUTES[useCase].slice();
  }

  trackClickedAnalyticsEvent(object, params = {}) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      ...params,
      action: 'clicked',
      object,
    });
  }

  @action
  trackInstallationMethodAnalyticsEvent(object) {
    this.trackClickedAnalyticsEvent(object, this.installationMethodAnalyticsMetadata);
  }

  @action
  focusLastAttributeInput() {
    let attributeWrappers = document.querySelectorAll('[data-attribute-input]');
    attributeWrappers.item(attributeWrappers.length - 1).focus();
  }

  @action
  addAttribute() {
    this.attributes.pushObject({ name: '' });
    scheduleOnce('afterRender', this, this.focusLastAttributeInput);
    this.trackClickedAnalyticsEvent('add_attribute_button');
  }

  @action
  onUseCaseChange(useCase) {
    this.useCase = useCase;
    this.resetAttributes(useCase);
  }

  @action
  showCode() {
    this.trackClickedAnalyticsEvent('show_code_button', { context: this.useCase });
    this.showCodeInstructions = true;
  }

  @action
  onInstallTypeChange(installMethod) {
    this.selectedInstallType = installMethod;
    this.selectedInstallMethod = this.defaultSelectedInstallMethod;
  }

  @action
  complete() {
    this.args.completionCallback?.();
  }

  @action
  removeAttribute(attribute) {
    this.attributes.removeObject(attribute);
  }

  @restartableTask
  *verify() {
    this.errorMessage = null;
    this.verified = false;

    try {
      let result = yield ajax({
        url: '/ember/onboarding/home/steps/setup_custom_data_attributes/verify',
        type: 'GET',
        data: {
          app_id: this.app.id,
        },
      });

      this.verified = result.verified;

      if (!this.verified) {
        this.errorMessage = this.intl.t(VERIFICATION_ERROR_TEXT);
      }
    } catch (e) {
      this.errorMessage = this.intl.t(VERIFICATION_REQUEST_FAILED_TEXT);
      this.verified = false;
      captureException(e);
    }

    this.trackInstallationMethodAnalyticsEvent('check_tracking');
  }
}
