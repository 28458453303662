/* import __COLOCATED_TEMPLATE__ from './messenger.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type TabItem, type Tooltip } from '../common/standard-base';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import { INSTALLATION_STATUS } from 'embercom/components/installation-new/constants';
import type Router from '@ember/routing/router-service';

const TABS: TabItem[] = [
  {
    label: 'new-settings.channels.messenger.tabs.web',
    value: 'web',
  },
  {
    label: 'new-settings.channels.messenger.tabs.mobile-sdk',
    value: 'mobile-sdk',
  },
  {
    label: 'new-settings.channels.messenger.tabs.conversations',
    value: 'conversations',
  },
  {
    label: 'new-settings.channels.messenger.tabs.general',
    value: 'general',
  },
];

const INSTALL_TAB: TabItem = {
  label: 'new-settings.channels.messenger.tabs.install',
  value: 'install',
};

interface Signature {
  Args: {
    selectedTab?: string;
    setSelectedTab: (value: string) => void;
    settings: any;
    identity: any;
  };
  Blocks: {
    default: Array<unknown>;
  };
}

export default class Messenger extends Component<Signature> {
  @service declare messengerInstallation: MessengerInstallation;
  @service declare appService: $TSFixMe;
  @service declare router: Router;

  get app() {
    return this.appService.app;
  }

  get groups(): TabItem[] {
    let tabItems = TABS;

    let installTab = INSTALL_TAB;

    if (this.shouldShowIdvTooltip) {
      let idvTooltip: Tooltip = {
        text: 'new-settings.channels.messenger.tabs.recommend-idv-tooltip',
        icon: 'alert-circle',
        iconColor: 'notice-fill',
      };

      installTab = { ...INSTALL_TAB, tooltip: idvTooltip };
    }

    let index = tabItems.findIndex((item) => item.value === installTab.value);
    if (index === -1) {
      tabItems.push(installTab);
    } else {
      tabItems[index] = installTab;
    }

    return tabItems;
  }

  get shouldShowIdvTooltip() {
    let installationStatuses = [
      this.messengerInstallation.webStatus,
      this.messengerInstallation.iosStatus,
      this.messengerInstallation.androidStatus,
    ];
    return installationStatuses.any(
      (status) => status === INSTALLATION_STATUS.INSECURELY_INSTALLED,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Messenger': typeof Messenger;
    'new-settings/channels/messenger': typeof Messenger;
  }
}
