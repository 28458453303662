/* import __COLOCATED_TEMPLATE__ from './respond-faster-using-macros-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { post } from 'embercom/lib/ajax';

export default class RespondFasterUsingMacrosComponent extends Component {
  @service intercomEventService;
  @service onboardingHomeExternalStepService;
  @service router;
  @service permissionsService;
  @service appService;
  @service onboardingHomeService;

  constructor() {
    super(...arguments);
    this.dependentStepIdentifier = 'setup_first_macro';
    this.openStepCallback = this.args.openStepCallback || (() => {});
  }

  get showDependentStepWarning() {
    let setupFirstMacroStep = this.onboardingHomeService.guide.findStepByIdentifier(
      this.dependentStepIdentifier,
    );
    return !setupFirstMacroStep?.completed;
  }

  @dropTask
  *startTour() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      action: 'clicked',
      object: `${this.args.step.identifier}_tour_${this.args.step.productTourId}`,
    });

    let params = {
      app_id: this.appService.app.id,
    };
    let response = yield post(
      '/ember/onboarding/customer_support_channels/create_demo_conversation',
      params,
    );

    this.onboardingHomeExternalStepService.startAfterTransition(this.args.step.identifier, {
      source: 'home/respond-faster-using-macros-component',
      method: 'startAfterTransition',
    });
    this.onboardingHomeExternalStepService.completeExternalStep();

    return this.router.transitionTo(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      this.appService.app.id,
      'shared',
      'all',
      response.conversation_id,
      {
        queryParams: {
          external_step: 'respond_faster_using_macros',
          shouldShowStepBanner: true,
        },
      },
    );
  }
}
