/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import {
  APP_TEMPLATE_WORKFLOWS_ID,
  PROACTIVE_SUPPORT,
} from 'embercom/lib/operator/custom-bots/constants';
import { WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS } from 'embercom/lib/operator/custom-bots/constants-2';
import type Template from 'embercom/models/template';
import { APP_TEMPLATES } from 'embercom/components/outbound/new-content-modal/workflows/app-templates';

interface Args {
  templates: Template[];
  selectTemplate: (template: Template) => void;
  toggleTemplateModal: () => void;
  displayInstallAppPackageModal?: (appPackageCode: string) => void;
  createWorkflow: (
    contentType: string | null,
    _deliveryChannel: null,
    _audienceType: null,
    templateId: string | null,
  ) => void;
}

const EMPTY_STATE_TEMPLATES = [{ id: '2015' }, { id: '2014' }, { id: '2028' }];

export default class EmptyState extends Component<Args> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get areAllTemplatesPaywalled() {
    return this.showcaseTemplateConfigs.every(
      ({ template }) =>
        !this.app.canUseFeature(
          template.ruleset.rulesetLinks.firstObject.object.config.requiredBillingFeature,
        ),
    );
  }

  get showcaseTemplateConfigs() {
    if (this.args.templates.length === 0) {
      return [];
    }

    let templates = EMPTY_STATE_TEMPLATES;

    return templates.map((config) => ({
      ...config,
      template: this.args.templates.find((template) => template.id === config.id) as Template,
    }));
  }

  get templateGroups() {
    return WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS;
  }

  get appTemplateWorkflowsId() {
    return APP_TEMPLATE_WORKFLOWS_ID;
  }

  get appTemplates() {
    return APP_TEMPLATES;
  }

  get recommendedGroups() {
    return WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS.filter((group) => {
      return group.id !== PROACTIVE_SUPPORT.id;
    });
  }

  get workflowsSetupTemplates() {
    let templates = this.args.templates.filter((template) =>
      EMPTY_STATE_TEMPLATES.some((config) => config.id === template.id),
    );
    return [
      templates.find((template) => template.id === EMPTY_STATE_TEMPLATES[0].id),
      templates.find((template) => template.id === EMPTY_STATE_TEMPLATES[1].id),
      templates.find((template) => template.id === EMPTY_STATE_TEMPLATES[2].id),
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::EmptyState': typeof EmptyState;
  }
}
