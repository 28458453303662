/* import __COLOCATED_TEMPLATE__ from './expanded-header.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  completedSteps: number;
  totalSteps: number;
  collapseChecklist: () => void;
}

const ExpandedHeader = templateOnlyComponent<Args>();
export default ExpandedHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::Checklist::ExpandedHeader': typeof ExpandedHeader;
    'onboarding/floating-widget/checklist/expanded-header': typeof ExpandedHeader;
  }
}
