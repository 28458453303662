/* import __COLOCATED_TEMPLATE__ from './install-use-case-app.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  intercomEventService: service(),
  completionCallback: null,
  recommendedApps: readOnly('step.modelData.recommendedApps'),
  subtitle: readOnly('step.modelData.subtitle'),

  _dispatchAnalyticEvent({ action, object }) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.step.analyticsMetadata,
      section: 'inline_step',
      action,
      object,
    });
  },

  openModal: action(function (modalAppPackage) {
    this.onSelectAppPackage(modalAppPackage);
    this._dispatchAnalyticEvent({
      action: 'clicked',
      object: modalAppPackage,
    });
  }),

  closeModal: action(function (modalAppPackage) {
    if (modalAppPackage.isInstalled && !this.step.completed) {
      this.completionCallback();
    }
    this.onCloseAppPackageModal();
  }),
});
