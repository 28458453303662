/* import __COLOCATED_TEMPLATE__ from './setup-office-hours.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { loadMessengerSettings } from 'embercom/lib/home/load-messenger-settings';

export default class SetupOfficeHours extends Component {
  @service appService;
  @service intl;
  @service intercomEventService;
  @service notificationsService;

  get messengerSettings() {
    let messengerSettingsFromModel = this.args.step.modelData?.messengerSettings;
    if (messengerSettingsFromModel) {
      return messengerSettingsFromModel;
    }
    return loadMessengerSettings(this);
  }

  get settingsAreDirty() {
    return this.messengerSettings.availability.hasDirtyAttributes;
  }

  get availabilityValid() {
    return this.messengerSettings.availability.isValid;
  }

  get adminCanAccessProductSettings() {
    return this.appService.app.currentAdmin.canAccessProductSettings;
  }

  get isSaveEnabled() {
    if (!this.adminCanAccessProductSettings) {
      return false;
    }

    return this.availabilityValid && (this.args.step.available || this.settingsAreDirty);
  }

  @dropTask
  *completeStep() {
    try {
      yield this.messengerSettings.availability.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: 'availability_settings',
        ...this.args.step.analyticsMetadata,
      });
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.set-expectations.default-error'),
      });
    }

    if (this.args.step.completed) {
      return this.args.completionCallback({
        stepCompletedText: this.intl.t('onboarding.home.steps.set-expectations.settings-saved'),
      });
    }
    yield this.args.completionCallback();
  }
}
