/* import __COLOCATED_TEMPLATE__ from './try-outbound-demo-message-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { objectNames, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { OUTBOUND_MESSAGE_IDS } from 'embercom/lib/outbound/constants';
import { tracked } from '@glimmer/tracking';
import type { TemplateType } from './empty-state';

interface Args {
  modal: any;
  onClose: () => void;
  createContent: () => void;
  templates: TemplateType[];
  onClickExploreAllMessages: () => void;
}

export type DemoOutboundMessageType = {
  action: () => void;
  isAvailable: boolean;
  title: any;
  objectType: number;
  thumbnailAsset: string;
  thumbnail?: string;
  id?: string;
};

export default class TryOutboundDemoMessageModal extends Component<Args> {
  @service appService: any;
  @service intercomEventService: any;
  @service declare notificationsService: any;
  @service declare outboundHomeService: $TSFixMe;
  @service intl: any;

  @tracked showContentCreationModal = false;
  @tracked bannerSentWithinLastMinute = false;

  get app() {
    return this.appService.app;
  }

  get demoMessageTypes(): DemoOutboundMessageType[] {
    let demoMessageTypes = [
      {
        action: () => {
          this.startProductTour();
          this.args.onClose();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-product-tour'),
        objectType: objectTypes.tour,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/product-tour.png',
      },
      {
        action: () => {
          this.startSurvey();
          this.args.onClose();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-survey'),
        objectType: objectTypes.survey,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/survey.png',
      },
      {
        action: () => {
          this.startChecklist();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-checklist'),
        objectType: objectTypes.checklist,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/checklist.png',
      },
      {
        action: () => {
          this.startBanner();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-banner'),
        objectType: objectTypes.banner,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/banner.png',
      },
      {
        action: () => {
          this.startChat();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-chat'),
        objectType: objectTypes.chat,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/chat.png',
      },
      {
        action: () => {
          this.startEmail();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-email'),
        objectType: objectTypes.email,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/email.png',
      },
      {
        action: () => {
          this.startPost();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-post'),
        objectType: objectTypes.post,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/post.png',
      },
      {
        action: () => {
          this.startWorkflow();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-workflow'),
        objectType: objectTypes.workflow,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/workflow.png',
      },
      {
        action: () => {
          this.startNewsItem();
        },
        isAvailable: true,
        title: this.intl.t('outbound.all.learn.try-news'),
        objectType: objectTypes.newsItem,
        // eslint-disable-next-line
        thumbnailAsset: '/assets/images/outbound/demo-types/news-item.png',
      },
    ];
    return demoMessageTypes;
  }

  startSurvey = () => {
    this.trackAnalytics(objectNames[objectTypes.survey], OUTBOUND_MESSAGE_IDS.survey);
    window.Intercom('startSurvey', OUTBOUND_MESSAGE_IDS.survey);
  };

  startChecklist = () => {
    this.trackAnalytics(objectNames[objectTypes.checklist], OUTBOUND_MESSAGE_IDS.checklist);
    window.Intercom('startChecklist', OUTBOUND_MESSAGE_IDS.checklist);
  };

  startProductTour = () => {
    this.trackAnalytics(objectNames[objectTypes.tour], OUTBOUND_MESSAGE_IDS.productTour);
    window.Intercom('startTour', OUTBOUND_MESSAGE_IDS.productTour);
  };

  startBanner() {
    this.trackAnalytics(objectNames[objectTypes.banner], OUTBOUND_MESSAGE_IDS.banner);

    if (this.outboundHomeService.outboundDemoBannerSentWithinLastMinute) {
      this.notificationsService.notifyWarning(this.intl.t('outbound.all.learn.wait-minute'));
      return;
    }

    window.Intercom('trackEvent', 'outbound-demo-banner');

    this.outboundHomeService.outboundDemoBannerSentWithinLastMinute = true;
    setTimeout(() => {
      this.outboundHomeService.outboundDemoBannerSentWithinLastMinute = false;
    }, 60000);
  }

  startChat = () => {
    this.trackAnalytics(objectNames[objectTypes.chat], OUTBOUND_MESSAGE_IDS.chat);

    if (this.outboundHomeService.outboundDemoChatSentWithinLastMinute) {
      this.notificationsService.notifyWarning(this.intl.t('outbound.all.learn.wait-minute'));
      return;
    }

    window.Intercom('trackEvent', 'outbound-demo-chat');

    this.outboundHomeService.outboundDemoChatSentWithinLastMinute = true;
    setTimeout(() => {
      this.outboundHomeService.outboundDemoChatSentWithinLastMinute = false;
    }, 60000);
  };

  startEmail = () => {
    this.trackAnalytics(objectNames[objectTypes.email], OUTBOUND_MESSAGE_IDS.email);

    if (this.outboundHomeService.outboundDemoEmailSentWithinLastMinute) {
      this.notificationsService.notifyWarning(this.intl.t('outbound.all.learn.wait-minute'));
      return;
    }

    window.Intercom('trackEvent', 'outbound-demo-email');

    this.outboundHomeService.outboundDemoEmailSentWithinLastMinute = true;

    this.notificationsService.notifyConfirmation(
      this.intl.t('outbound.all.learn.demo-email-sent-success'),
    );

    setTimeout(() => {
      this.outboundHomeService.outboundDemoEmailSentWithinLastMinute = false;
    }, 60000);
  };

  startPost = () => {
    this.trackAnalytics(objectNames[objectTypes.post], OUTBOUND_MESSAGE_IDS.post);

    if (this.outboundHomeService.outboundDemoPostSentWithinLastMinute) {
      this.notificationsService.notifyWarning(this.intl.t('outbound.all.learn.wait-minute'));
      return;
    }

    window.Intercom('trackEvent', 'outbound-demo-post');

    this.outboundHomeService.outboundDemoPostSentWithinLastMinute = true;
    setTimeout(() => {
      this.outboundHomeService.outboundDemoPostSentWithinLastMinute = false;
    }, 60000);
  };

  startWorkflow = () => {
    this.trackAnalytics(objectNames[objectTypes.workflow], OUTBOUND_MESSAGE_IDS.workflow);
    window.Intercom('trackEvent', 'outbound-demo-workflow');
  };

  startNewsItem = () => {
    this.trackAnalytics(objectNames[objectTypes.newsfeed], 'latest_news_in_messenger');
    window.Intercom('showSpace', 'news');
  };

  trackAnalytics(contentType: string, id: number | string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'outbound_demo_messages_modal',
      place: 'outbound_all',
      content_type: contentType,
      link_to: id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::TryOutboundDemoMessageModal': typeof TryOutboundDemoMessageModal;
    'outbound/try-outbound-demo-message-modal': typeof TryOutboundDemoMessageModal;
  }
}
