/* import __COLOCATED_TEMPLATE__ from './change-guide.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';

export default class OnboardingHomeChangeGuideComponent extends Component {
  @service appService;
  @service onboardingHomeService;
  @tracked confirmationModalOpen = false;
  @tracked selectedGuide = null;
  @tracked loadingMessage = null;
  @tracked errorMessage = null;
  @service intl;

  constructor() {
    super(...arguments);
    this.availableGuidesTask.perform();
    this.resetState();
  }

  @action
  resetState() {
    this.selectedGuide = this.currentGuideIdentifier;
    this.errorMessage = null;
    this.confirmationModalOpen = false;
  }

  get availableGuides() {
    if (
      !this.availableGuidesTask.lastSuccessful ||
      !this.availableGuidesTask.lastSuccessful.value
    ) {
      return [];
    }

    return this.availableGuidesTask.lastSuccessful.value.map((item) => {
      return { text: item.display_name, value: item.identifier };
    });
  }

  get currentGuideIdentifier() {
    return get(this.onboardingHomeService, 'guide.identifier');
  }

  get isLoading() {
    return this.availableGuidesTask.isRunning || this.changeGuideTask.isRunning;
  }

  displayError(message) {
    this.errorMessage = this.intl.t('onboarding.home.change-guide.something-went-wrong', {
      message,
    });
  }

  @dropTask
  *availableGuidesTask() {
    this.loadingMessage = this.intl.t('onboarding.home.change-guide.loading-guides');
    return yield this.onboardingHomeService.getAvailableGuides().catch((error) => {
      this.displayError(this.intl.t('onboarding.home.change-guide.fail-load-guide'));
    });
  }

  @dropTask
  *changeGuideTask() {
    this.loadingMessage = this.intl.t('onboarding.home.change-guide.guide-change');
    this.confirmationModalOpen = false;
    yield this.onboardingHomeService
      .changeGuide({ identifier: this.selectedGuide })
      .then(() => {
        if (!Ember.testing) {
          window.location.reload();
        }
      })
      .catch((error) => {
        let errorMessage = this.intl.t('onboarding.home.change-guide.failed-to-change-guide', {
          error: error.errorThrown,
        });
        this.displayError(errorMessage);
      });
  }

  @action
  onSelectedGuideChange(selectedGuideIdentifier) {
    if (selectedGuideIdentifier !== this.currentGuideIdentifier) {
      this.selectedGuide = selectedGuideIdentifier;
      this.confirmationModalOpen = true;
    }
  }
}
