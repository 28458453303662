/* import __COLOCATED_TEMPLATE__ from './setup-email-forwarding.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { type DomainEmailAddress } from 'embercom/services/domain-service';

interface Signature {
  Args: {
    emailAddress: DomainEmailAddress | undefined;
    changeStep: (step: string | null, place: string) => void;
    reloadModels: () => TaskGenerator<void>;
    openInviteTeammateModal: () => void;
  };
}

export default class SetupEmailForwarding extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare mailForwardingValidationService: any;
  @service declare realTimeEventService: $TSFixMe;
  @service declare router: RouterService;

  place = 'setup-email-forwarding';

  @tracked forwardingTaskFailed = false;

  @action
  showEmailForwardingArticle() {
    let articleUrl =
      'https://www.intercom.com/help/en/articles/9744849-connect-your-email-support-channel#h_bad0df24e4';
    safeWindowOpen(articleUrl, '_blank');
  }

  unregisterForwardMailVerifiedListener() {
    this.realTimeEventService.off('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
  }

  willDestroy() {
    this.unregisterForwardMailVerifiedListener();
    super.willDestroy();
  }

  get intercomEmailAddress() {
    return this.appService.app.inbox_email;
  }

  get verificationTask() {
    return taskFor(this.mailForwardingValidationService.verifyEmailForwarding);
  }

  get verification() {
    return this.mailForwardingValidationService.verification;
  }

  @action
  trackCopyButtonClick() {
    this.trackEvent('clicked', 'copy_intercom_email_address');
  }

  @action
  async verifyEmailForwarding() {
    if (!this.args.emailAddress) {
      return;
    }
    this.realTimeEventService.on('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');

    await this.mailForwardingValidationService.verifyEmailForwarding.perform(
      this.args.emailAddress.email,
      'domains-and-addresses',
      true,
    );
    if (!this.verification) {
      this.forwardingTaskFailed = true;
      this.unregisterForwardMailVerifiedListener();
      this.trackEvent('failed', 'check_email_forwarding');
    }
    this.mailForwardingValidationService.unset();
  }

  @action
  requestForwardingVerification() {
    this.verifyEmailForwarding();
    this.trackEvent('clicked', 'send_test_email');
  }

  @action
  retryForwardingVerification() {
    // Keeping this seperated as we might want to add some logic here in the future
    this.verifyEmailForwarding();
    this.trackEvent('clicked', 'resend_test_email');
  }

  @action
  openUnassignedInbox(linkContext: string) {
    let object = `go_to_unassigned_inbox_link_on_${linkContext}`;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place: this.place,
      context: 'email-setup-sidebar',
    });
    safeWindowOpen(
      this.router.urlFor(
        'inbox.workspace.inbox.inbox',
        this.appService.app.id,
        InboxCategory.Shared,
        InboxType.Unassigned,
      ),
      '_blank',
    );
  }

  @action
  openSpamInbox(linkContext: string) {
    let object = `go_to_spam_inbox_link_on_${linkContext}`;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place: this.place,
      context: 'email-setup-sidebar',
    });
    safeWindowOpen(
      this.router.urlFor(
        'inbox.workspace.inbox.inbox',
        this.appService.app.id,
        InboxCategory.Shared,
        InboxType.Spam,
      ),
      '_blank',
    );
  }

  @action
  trackEvent(eventAction: string, event: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: eventAction,
      object: event,
      place: this.place,
      context: 'email-setup-sidebar',
    });
  }

  async _handleForwardMailVerifiedMessage() {
    this.forwardingTaskFailed = false;
    await taskFor(this.args.reloadModels).perform();
    this.args.changeStep('overview', this.place);
    this.trackEvent('success', 'check_email_forwarding');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::SetupEmailForwarding': typeof SetupEmailForwarding;
    'new-settings/channels/email/domains-and-addresses/setup-email-forwarding': typeof SetupEmailForwarding;
  }
}
