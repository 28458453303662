/* import __COLOCATED_TEMPLATE__ from './navigation.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import type GLInboxOnboardingStep from 'embercom/lib/inbox2/guide-library/step';

interface Args {
  isFirstStep: boolean;
  isLastStep: boolean;
  step: GLInboxOnboardingStep;
  nextStep: () => unknown;
  onFinish: () => unknown;
  onSkipAll: () => unknown;
  totalStepCount: number;
  activeStepIndex: number;
  showAnimation: boolean;
}

interface Signature {
  Args: Args;
  Element: never;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class Navigation extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::InboxTour::Navigation': typeof Navigation;
  }
}
