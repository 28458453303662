/* import __COLOCATED_TEMPLATE__ from './ew-chatbot-try-fin-with-content.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { type StepData } from '../../wizard';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import EnqueueApifyImportApi from 'embercom/lib/external-content/enqueue-apify-import-api';
import { getOwner } from '@ember/application';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import type ContentImportService from 'embercom/services/content-import-service';
import { perform } from 'ember-concurrency-ts';

interface Args {
  stepData: StepData;
  step: any;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class EwChatbotTryFinWithContent extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare contentImportService: ContentImportService;

  @tracked submissionError = false;
  @tracked submissionErrorTranslationKey = '';
  @tracked url = '';
  @tracked submitted = false;
  @tracked addingAdditionalContent = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    perform(this.fetchContentImportSources);
    this.contentImportService.subscribeToContentImportRunStatusUpdates();
    this.contentImportService.assignCompletionCallback(
      this.triggerContentImportStatusAnalytics.bind(this, 'success'),
    );
    this.contentImportService.assignErrorCallback(
      this.triggerContentImportStatusAnalytics.bind(this, 'failure'),
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'preview_fin_with_your_content_step',
      context: `onboarding_guide_library.wizard.ai_bot.preview_fin_with_your_content`,
      place: 'ai_bot_wizard',
      section: 'ai_bot_preview_fin_with_your_content',
    });
  }

  get isNotAllowedContinue() {
    return this.isUrlInvalid && !this.hasPreviousImports;
  }

  get isUrlInvalid(): boolean {
    if (this.url === undefined || this.url === '') {
      return true;
    }

    if (!this.url.startsWith('http://') && !this.url.startsWith('https://')) {
      return true;
    }

    return false;
  }

  get contentImportSources() {
    return this.contentImportService?.contentImportSources;
  }

  get hasPreviousImports(): boolean {
    return Boolean(this.contentImportSources?.length);
  }

  get hasOneSuccessfulImport(): boolean {
    return this.contentImportService.successfulRuns?.length > 0;
  }

  @action
  saveAndContinue() {
    if (this.url.length > 7 && this.isUrlInvalid) {
      perform(this.enqueueApifyImport);
    } else if (this.hasOneSuccessfulImport) {
      this.args.stepData.continueCallback();
    }
  }

  @dropTask
  *enqueueApifyImport() {
    this.trackAnalyticsEvent('submitted', 'url_submit_button');

    if (this.isNotAllowedContinue) {
      return;
    }

    if (!this.isUrlInvalid) {
      let api = new EnqueueApifyImportApi(getOwner(this));
      let { submitted, submissionError, submissionErrorTranslationKey } =
        yield api.enqueueApifyImport(this.url, true);

      this.submitted = submitted;
      this.submissionError = submissionError;
      this.submissionErrorTranslationKey = submissionErrorTranslationKey;
      this.addingAdditionalContent = false;
    }

    if (this.submitted || this.hasPreviousImports) {
      this.args.stepData.continueCallback();
    }
  }

  @dropTask
  *fetchContentImportSources() {
    yield this.contentImportService.fetchContentImportSources();
  }

  @action trackInputAnalytics(): void {
    this.trackAnalyticsEvent('inputed', 'url_form_input');
  }

  trackAnalyticsEvent(action: any, object: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'onboarding_guide_library.wizard.ai_bot.preview_fin_with_your_content',
      place: 'ai_bot_wizard',
    });
  }

  async triggerContentImportStatusAnalytics(importStatus: 'success' | 'failure') {
    this.intercomEventService.trackAnalyticsEvent({
      action: `import_${importStatus}`,
      object: 'preview_fin_with_your_content_import',
      context: 'onboarding_guide_library.wizard.ai_bot.preview_fin_with_your_content',
      place: 'ai_bot_wizard',
      section: 'ai_bot_preview_fin_with_your_content',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::EwChatbotTryFinWithContent': typeof EwChatbotTryFinWithContent;
  }
}
