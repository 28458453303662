/* import __COLOCATED_TEMPLATE__ from './ew-install-messenger.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { type StepData } from '../../wizard';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';
import { INSTALL_METHODS } from 'embercom/lib/messenger/installation-constants';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Signature {
  Element: never;
  Args: {
    step: any;
    stepData: StepData;
  };
}

export default class EWInstallMessenger extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare appService: any;

  @tracked messengerSettings: any;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    taskFor(this.loadMessengerSettings).perform();
  }

  noCodeRedirectLinks = [
    {
      keyword: 'gtm',
      method: INSTALL_METHODS.GOOGLE_TAG_MANAGER.value,
      iconAssetURL: assetUrl(
        '/assets/images/onboarding/installation-methods/google-tag-manager.png',
      ),
    },
    {
      keyword: 'segment',
      method: INSTALL_METHODS.SEGMENT.value,
      iconAssetURL: assetUrl('/assets/images/onboarding/installation-methods/segment.png'),
    },
    {
      keyword: 'wordpress',
      method: INSTALL_METHODS.WORDPRESS.value,
      iconAssetURL: assetUrl('/assets/images/onboarding/installation-methods/wordpress.png'),
    },
    {
      keyword: 'shopify',
      method: INSTALL_METHODS.SHOPIFY.value,
      iconAssetURL: assetUrl('/assets/images/onboarding/installation-methods/shopify.png'),
    },
  ];

  assistanceItems = [
    {
      key: 'invite-teammates',
      icon: 'person',
      text: 'onboarding.guide-library.steps.ew-install-messenger.assistance.invite-teammate',
    },
    {
      key: 'show-messenger',
      icon: 'messenger',
      text: 'onboarding.guide-library.steps.ew-install-messenger.assistance.contact-us',
    },
    {
      key: 'community-link',
      icon: 'multiple-people',
      text: 'onboarding.guide-library.steps.ew-install-messenger.assistance.browse-intercom-community',
    },
  ] as const;

  resourceItems = [
    {
      link: 'https://www.intercom.com/help/en/collections/2094823-installing-the-intercom-messenger',
      icon: 'article',
      text: 'onboarding.guide-library.steps.ew-install-messenger.resources.ways-to-connect',
      key: 'ways-to-connect',
    },
    {
      link: 'https://www.intercom.com/help/en/collections/2094776-faqs-and-troubleshooting',
      icon: 'article',
      text: 'onboarding.guide-library.steps.ew-install-messenger.resources.faq-troubleshooting',
      key: 'faq-troubleshooting',
    },
    {
      link: 'https://developers.intercom.com/installing-intercom/',
      icon: 'code',
      text: 'onboarding.guide-library.steps.ew-install-messenger.resources.dev-documentation',
      key: 'dev-documentation',
    },
    {
      link: 'https://academy.intercom.com/set-up-intercom-for-your-business',
      icon: 'article',
      text: 'onboarding.guide-library.steps.ew-install-messenger.resources.setup-intercom-business',
      key: 'intercom-for-business',
    },
    {
      link: 'https://www.youtube.com/watch?v=DudTr7mY74g&t=203s',
      icon: 'video',
      text: 'onboarding.guide-library.steps.ew-install-messenger.resources.cs-feature-demo',
      key: 'service-demo-video',
    },
  ] as const;

  get totalStepCount() {
    return this.args.stepData.totalStepCount;
  }

  get currentStepIndex() {
    return this.args.stepData.currentStepIndex - 1;
  }

  get messengerEnabled() {
    return (
      this.messengerSettings?.visibility?.messengerEnabledForUsers &&
      this.messengerSettings?.visibility?.messengerEnabledForVisitors
    );
  }

  @action
  toggleMessengerVisibility() {
    let newState = !this.messengerEnabled;
    this.messengerSettings.visibility.messengerEnabledForUsers = newState;
    this.messengerSettings.visibility.messengerEnabledForVisitors = newState;
    this.messengerSettings.save();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `show_messenger_toggle`,
      place: 'messenger-installation-wizard.ew-install-messenger',
      context: 'wizard_connect_to_intercom',
    });
  }

  @action
  redirectToInstallationPage(key: string) {
    taskFor(this.updateStep).perform();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `messenger_installation_cards_${key}`,
      place: 'messenger-installation-wizard.ew-install-messenger',
      context: 'wizard_connect_to_intercom',
    });
    this.router.transitionTo('apps.app.settings.channels.messenger.install', {
      queryParams: { installationType: key },
    });
  }

  @action
  onResourcesItemClicked(key: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `resource_faq_links_${key}`,
      place: 'messenger-installation-wizard.ew-install-messenger',
      context: 'wizard_connect_to_intercom',
    });
  }

  @action
  onAssistanceItemClicked(key: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `assistance_links_${key}`,
      place: 'messenger-installation-wizard.ew-install-messenger',
      context: 'wizard_connect_to_intercom',
    });
    if (key === 'invite-teammates') {
      this.router.transitionTo('apps.app.settings.workspace.teammates.invite.new');
    } else if (key === 'community-link') {
      safeWindowOpen('https://community.intercom.com/', '_blank');
    } else {
      window.Intercom('showMessages');
    }
  }

  @dropTask
  private *updateStep() {
    this.args.step.set('state', 'completed');
    yield this.args.step.saveAndRefreshGuide();
  }

  @dropTask
  *loadMessengerSettings(): TaskGenerator<void> {
    this.messengerSettings = yield this.store.findRecord(
      'messenger-settings/all',
      this.appService.app.id,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::MessengerInstallationWizard::EWInstallMessenger': typeof EWInstallMessenger;
  }
}
