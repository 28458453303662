/* import __COLOCATED_TEMPLATE__ from './survey-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { captureException } from 'embercom/lib/sentry';
import { get } from 'embercom/lib/ajax';
import type RouterService from '@ember/routing/router-service';

interface Args {
  onSurveyAlreadyCompleted: (id: number) => void;
  onSurveyCompletion?: () => Promise<void>;
  showSubmittingScreenOnCompletion?: boolean;
}

interface Answer {
  identifier: string;
  selected: boolean;
  follow_up_question?: any;
}

interface Question {
  identifier: string;
  answers: Answer[];
  state: string;
  type: string;
}

interface Survey {
  id: string;
  admin_id: number;
  identifier: string;
  questions: Question[];
  completed_at?: any;
  state: string;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class SurveyWrapper extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare onboardingHomeService: $TSFixMe;
  @service declare router: RouterService;

  @tracked displayWelcomeStep = true;
  @tracked displayModal = false;
  @tracked displaySubmittingScreen = false;
  @tracked survey: Survey = {} as Survey;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.app.canSeeProfilingSurvey) {
      taskFor(this.loadSurvey).perform();
    }
  }

  get app() {
    return this.appService.app;
  }

  get displaySurvey() {
    return this.displayModal && this.app.canSeeProfilingSurvey;
  }

  @action
  async closeModal() {
    await this.args.onSurveyCompletion?.();
    if (this.args.showSubmittingScreenOnCompletion) {
      this.displaySubmittingScreen = true;
      setTimeout(() => {
        this.completeSurvey();
      }, 3000);
    } else {
      this.completeSurvey();
    }
  }

  completeSurvey() {
    this.displayModal = false;
    this.displaySubmittingScreen = false;
    this.app.currentAdmin.set('teammate_role_data_completed', true);
  }

  @action
  onWelcomeButtonClick() {
    this.displayWelcomeStep = false;
  }

  @dropTask
  *loadSurvey(): Generator<any, void, any> {
    let appId = this.app.id;

    if (!appId) {
      return;
    }

    try {
      this.survey = yield get('/ember/onboarding/lead_profile_survey', {
        app_id: appId,
      });
    } catch (error) {
      console.error(error);
      captureException(error, {
        fingerprint: ['lead_profile_survey', error.message],
        extra: {
          admin_id: this.app.currentAdmin.id,
        },
        tags: {
          responsibleTeam: 'team-self-serve',
        },
      });
    }

    this.displayModal = this.survey.state === 'not_started' || this.survey.state === 'in_progress';

    if (this.survey.state === 'completed' || this.survey.state === 'partially_completed') {
      this.args.onSurveyAlreadyCompleted(this.survey.admin_id);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::ProfilingSurvey::SurveyWrapper': typeof SurveyWrapper;
  }
}
