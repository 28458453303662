/* import __COLOCATED_TEMPLATE__ from './save-time-with-automation.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class SaveTimeWithAutomation extends Component {
  @service appService;
  @service router;

  get hasWorkflowsBillingFeature() {
    return this.appService.app.canUseFeature('workflows_core_billing_feature');
  }

  @action
  playVideo(target) {
    target.play();
  }

  @action
  triggerCompletionAndTransition() {
    let route = 'apps.app.automation.basics';
    if (this.hasWorkflowsBillingFeature) {
      route = 'apps.app.automation.workflows-overview';
    }
    this.args.completionCallback();
    this.router.transitionTo(route);
  }
}
