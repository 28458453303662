/* import __COLOCATED_TEMPLATE__ from './reply-addresses.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type DomainService from 'embercom/services/domain-service';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
interface Args {}
import type { DomainEmailAddress } from 'embercom/services/domain-service';
import type { Domain } from 'embercom/services/domain-service';
import { getDomainName } from 'embercom/lib/email';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';

const CAN_EDIT_SENDER_DOMAIN = 'can_settings__default_sender__manage';

interface Args {
  setReplyAddressValue?: $TSFixMe;
}

interface ReplyAddressItem {
  value: string | number;
  text: string;
  component: string;
  componentAttrs?: {
    verified?: boolean;
    isSelected?: boolean;
    isAuthenticated?: any;
  };
  isDisabled?: boolean;
}

export default class ReplyAddresses extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: $TSFixMe;
  @service declare intercomEventService: any;
  @service declare domainService: DomainService;
  @service declare permissionsService: $TSFixMe;
  @service declare brandService: $TSFixMe;
  @service declare router: RouterService;
  @service declare store: Store;

  @tracked hasPermission: boolean;
  @tracked senderDomainValue = this.app.use_admin_app_email;
  @tracked replyAddressValue = this.replyAddressValueFromModel;
  @tracked selectedSenderEmailAddressSettingsId?: string;

  senderEmailAddressSettings: DomainEmailAddress[];

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.args.setReplyAddressValue(this.replyAddressValueFromModel);
    // needed for default address
    this.selectedSenderEmailAddressSettingsId =
      this.domainService.defaultSenderEmailAddressSettings?.id;
    this.senderEmailAddressSettings = this.domainService.domains.flatMap(
      (domain: Domain) => domain.addresses,
    );
    this.hasPermission = this.permissionsService.currentAdminCan(CAN_EDIT_SENDER_DOMAIN);
  }

  // carried from reply addresses -- start

  async updateDefaultAddress() {
    try {
      await this.appService.app.updateDefaultNotificationEmailAddress(
        this.selectedSenderEmailAddressSettingsId,
      );
      await this.store.findAll('sender-email-address-settings');
      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.channels.email.advanced.default-reply-address.update-success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'new-settings.channels.email.advanced.default-reply-address.update-error',
        ),
      });
      this.selectedSenderEmailAddressSettingsId =
        this.domainService.defaultSenderEmailAddressSettings?.id;
    }
  }

  @action
  openBrandsSettingsPage() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'open_brands_settings_page',
      place: 'default-reply-address',
      context: 'email-settings',
    });
    let url = this.router.urlFor('apps.app.settings.workspace.brands', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }

  get showBrandsLink() {
    if (!this.appService.app.canUseMultiBrandEmailOpenBeta) {
      return false;
    }
    return this.brandService.isAppOnRightMultibrandPlan;
  }

  @action
  showPermissionsModal() {
    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(CAN_EDIT_SENDER_DOMAIN);
  }

  get selectedEmail(): string {
    let selectedEmail = this.senderEmailAddressSettings.find(
      (email: DomainEmailAddress) => email.id === this.selectedSenderEmailAddressSettingsId,
    );
    return selectedEmail?.email || '';
  }

  @action
  async onSelectSenderEmailAddressSetting(selectedItem: string): Promise<void> {
    this.selectedSenderEmailAddressSettingsId = selectedItem;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'selected',
      object: 'default_reply_address_dropdown',
      place: 'default-reply-address',
      context: 'email-settings',
    });
    await this.updateDefaultAddress();
  }

  isItemDisabled(address: DomainEmailAddress) {
    return !this.isEmailDomainAuthenticated(address.email) || !address.verified;
  }

  isEmailDomainAuthenticated(email: string) {
    let emailDomain = getDomainName(email);
    return this.domainService.domains.find((domain: Domain) => domain.name === emailDomain)
      ?.isAuthenticated;
  }

  get defaultReplyAddressItems(): ReplyAddressItem[] {
    let senderEmailAddressSettings = this.senderEmailAddressSettings;
    let items = senderEmailAddressSettings?.length
      ? senderEmailAddressSettings.map((address: DomainEmailAddress) => ({
          value: address.senderEmailAddressSettings?.id ?? 0,
          text: address.senderEmailAddressSettings?.email ?? '',
          isDisabled: this.isItemDisabled(address),
          component: 'new-settings/workspace/brands/brand-email-dropdown-item',
          componentAttrs: {
            verified: address.verified,
            id: address.senderEmailAddressSettings?.id,
            isSelected:
              this.selectedSenderEmailAddressSettingsId === address.senderEmailAddressSettings?.id,
            isAuthenticated: address.senderEmailAddressSettings
              ? this.isEmailDomainAuthenticated(address.senderEmailAddressSettings.email)
              : false,
          },
        }))
      : [
          {
            value: 0,
            text: '',
            component: 'new-settings/channels/email/advanced/email-dropdown-empty-list',
            isDisabled: true,
          },
        ];
    return items;
  }

  // carried from reply addresses -- end

  get replyAddressValueFromModel() {
    if (this.senderDomainValue) {
      return this.app.admin_reply_default_address_id ? 'inbound' : 'teammate';
    } else {
      return 'workspace';
    }
  }

  get app() {
    return this.appService.app;
  }

  get isReplyAddressInboundDisabled(): boolean {
    return (
      !this.app.admin_reply_default_address_id &&
      this.domainService.verifiedAndAuthenticatedCustomEmailAddresses.length === 0
    );
  }

  @action
  handleClick(value: string, event: PointerEvent) {
    if (!this.hasPermission) {
      event.preventDefault();
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        CAN_EDIT_SENDER_DOMAIN,
      );
    }
    if (value === 'workspace') {
      return this.handleClickWorkspace();
    } else if (value === 'inbound') {
      return this.handleClickInbound();
    } else {
      return this.handleClickTeammate();
    }
  }

  @action
  async handleClickWorkspace() {
    this.senderDomainValue = false;
    this.replyAddressValue = 'workspace';
    await this.updateReplyAddress();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_sender_domain',
      section: 'settings',
      inbound_address: 'workspace',
    });
  }

  @action
  async handleClickTeammate() {
    this.senderDomainValue = true;
    this.replyAddressValue = 'teammate';
    await this.updateReplyAddress();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_sender_domain',
      section: 'settings',
      inbound_address: 'teammate',
    });
  }

  @action
  async handleClickInbound() {
    this.senderDomainValue = true;
    this.replyAddressValue = 'inbound';
    await this.updateReplyAddress();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'email_sender_domain',
      section: 'settings',
      inbound_address: 'inbound',
    });
  }

  private get defaultReplyAddress(): SenderEmailAddressSettings | undefined {
    return (
      this.domainService.defaultSenderEmailAddressSettings ??
      this.domainService.verifiedAndAuthenticatedCustomEmailAddresses[0]?.senderEmailAddressSettings
    );
  }

  async updateReplyAddress() {
    try {
      if (this.replyAddressValue === 'inbound') {
        await this.updateWhenInboundIsSelected();
      } else {
        await this.app.updateDefaultEmailSenderSetting(this.senderDomainValue, '');
      }
    } catch (error) {
      console.error('update error', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'new-settings.channels.email.customisation.sender-domain.update-error',
        ),
      });
      return;
    }
    this.args.setReplyAddressValue(this.replyAddressValueFromModel);
    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.channels.email.customisation.sender-domain.update-success'),
    );
  }

  async updateWhenInboundIsSelected() {
    if (!this.domainService.defaultSenderEmailAddressSettings) {
      await this.app.updateDefaultNotificationEmailAddress(this.defaultReplyAddress?.id);
    }
    let senderEmailAddressId =
      this.defaultReplyAddress?.senderEmailAddresses?.firstObject?.id ?? '0';
    await this.app.updateDefaultEmailSenderSetting(this.senderDomainValue, senderEmailAddressId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::ReplyAddresses': typeof ReplyAddresses;
  }
}
