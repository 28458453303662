/* import __COLOCATED_TEMPLATE__ from './nav-progress-badge.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

interface Args {
  appId: string;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class NavProgressBadge extends Component<Signature> {
  @service declare greatGuidanceService: GreatGuidanceService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.greatGuidanceService.steps.length === 0) {
      this.greatGuidanceService.fetchSteps(this.args.appId);
    }
  }

  get totalSteps() {
    return this.greatGuidanceService.steps.length;
  }

  get completedSteps() {
    return this.greatGuidanceService.steps.filter((step) => step.state === 'completed').length;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::NavProgressBadge': typeof NavProgressBadge;
  }
}
