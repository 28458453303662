/* import __COLOCATED_TEMPLATE__ from './customize-messenger-exp-one-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class CustomizeMessengerExpOneComponent extends Component {
  @service intercomEventService;
  @service onboardingHomeExternalStepService;
  @service router;
  @service permissionsService;
  @service appService;

  @action
  async startTour() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      action: 'clicked',
      object: `${this.args.step.identifier}_tour_${this.args.step.productTourId}`,
    });

    this.onboardingHomeExternalStepService.startAfterTransition(this.args.step.identifier, {
      source: 'home/customize-messenger-exp-one-component',
      method: 'startAfterTransition',
    });

    return this.router.transitionTo('apps.app.messenger.settings', this.appService.app.id, {
      queryParams: { shouldShowStepBanner: true },
    });
  }
}
