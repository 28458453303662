/* import __COLOCATED_TEMPLATE__ from './wizard.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import { dropTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import type ContentImportService from 'embercom/services/content-import-service';

interface Args {
  stepId: string | null;
  wizard: any;
}

interface Signature {
  Args: Args;
  Element: never;
}

export interface StepData {
  totalStepCount: number;
  currentStepIndex: number;
  continueCallback: (moveToGuideLibrary?: boolean) => void;
  completeAndExitToGuideLibraryCallback: () => void;
  onSkipCallback: () => void;
  transitionToStep: (id: string) => void;
}

export default class Wizard extends Component<Signature> {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare onboardingHomeService: any;
  @service declare onboardingHomeExternalStepService: any;
  @service declare router: Router;
  @service declare contentImportService: ContentImportService;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  get currentStep(): any {
    if (this.args.stepId) {
      return this.steps.find((step: any) => step.id === this.args.stepId);
    } else {
      return this.steps.objectAt(0);
    }
  }

  get wizard(): any {
    return this.args.wizard;
  }

  get guide(): any {
    return this.onboardingHomeService.guide;
  }

  get steps(): { id: string; [key: string]: any }[] {
    return this.wizard.steps.filter((step: any) => !step.stepData.isDisplayOnlyStep);
  }

  get stepData(): StepData {
    return {
      totalStepCount: this.steps.length,
      currentStepIndex: this.steps.indexOf(this.currentStep) + 1,
      continueCallback: this.continueCallback,
      completeAndExitToGuideLibraryCallback: this.completeAndExitToGuideLibraryCallback,
      onSkipCallback: this.onSkipCallback,
      transitionToStep: this.transitionToStep,
    };
  }

  @action continueCallback(moveToGuideLibrary = true) {
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'continue',
      step_identifier: this.currentStep.id,
    });
    taskFor(this.updateStep).perform('completed');
    this.nextStep(moveToGuideLibrary);
  }

  @action completeAndExitToGuideLibraryCallback() {
    taskFor(this.updateStep).perform('completed');
    this.onboardingHomeExternalStepService.startAfterTransition(
      this.wizardCompleteTourDetailStepId,
      {
        source: this.wizard.identifier,
        method: 'startAfterTransition',
      },
    );
    this.router.transitionTo('apps.app.guide-library', this.appService.app.id);
  }

  @action transitionToStep(identifier: string) {
    this.router.transitionTo({ queryParams: { stepId: identifier } });
  }

  @dropTask
  private *updateStep(state: string) {
    this.currentStep.set('state', state);
    yield this.currentStep.saveAndRefreshGuide();
  }

  private _areAllStepsCompleted() {
    return this.steps.every((step: any) => step.state === 'completed');
  }

  private _markWizardAsComplete() {
    this._trackAnalyticsEvent({
      action: 'completed',
      object: 'completed',
    });
    this.intercomEventService.trackEvent(`${this.wizard.identifier}-completed`);
  }

  get wizardCompleteTourDetailStepId() {
    switch (this.wizard.identifier) {
      case 'evaluation_wizard_helpdesk':
        return 'display_only_tour_the_helpdesk';
      case 'evaluation_wizard_proactive_support':
        return 'display_only_learn_about_proactive_support';
      case 'evaluation_wizard_chatbot':
        return 'display_only_test_fin';
      default:
        return null;
    }
  }

  @action nextStep(moveToGuideLibrary = true) {
    let index = this.steps.indexOf(this.currentStep);
    if (this.steps.length > index + 1) {
      this.router.transitionTo({ queryParams: { stepId: this.steps.objectAt(index + 1)!.id } });
    } else {
      if (this._areAllStepsCompleted()) {
        this._markWizardAsComplete();
      }
      if (moveToGuideLibrary) {
        if (!this.wizard.identifier.includes('_v2')) {
          this.onboardingHomeExternalStepService.startAfterTransition(
            this.wizardCompleteTourDetailStepId,
            {
              source: this.wizard.identifier,
              method: 'startAfterTransition',
            },
          );
        }
        this.router.transitionTo('apps.app.guide-library', this.appService.app.id);
      }
    }
  }

  @action onSkipCallback() {
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'skip',
      step_identifier: this.currentStep.id,
    });
    this.intercomEventService.trackEvent(`${this.wizard.identifier}-skipped`, {
      step_identifier: this.currentStep.id,
    });
    this.onboardingHomeExternalStepService.startAfterTransition(this.currentStep.id, {
      source: `${this.wizard.identifier}/${this.currentStep.id}`,
      method: 'startAfterTransition',
    });
    this.router.transitionTo('apps.app.guide-library', this.appService.app.id);
  }

  private _trackAnalyticsEvent(metaData: any) {
    this.intercomEventService.trackAnalyticsEvent({
      ...metaData,
      context: 'onboarding_guides_library.wizards',
      place: 'guide_library',
      wizard_identifier: this.wizard.identifier,
      guide_identifier: this.guide.identifier,
      section: 'wizard',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Wizard': typeof Wizard;
  }
}
