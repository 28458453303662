/* import __COLOCATED_TEMPLATE__ from './email.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TabItem } from 'embercom/components/new-settings/common/standard-base';
import type Brand from 'embercom/models/brand';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type BrandService from 'embercom/services/brand-service';
import { type Domain } from 'embercom/services/domain-service';
import type IntlService from 'embercom/services/intl';

interface Args {
  model: $TSFixMe;
  tab: string;
  step: string | null;
  email: string | null;
  changeTab: (tab: string) => void;
  changeStep: (step: string | null, place: string) => void;
  setEmail: (email: string | null) => Promise<void>;
  domains: Domain[];
  setDomainName: (domainName: string | null) => void;
  domain: Domain | null;
  previousStepForDomainSetup: string | null;
  setPreviousStepForDomainSetup: (step: string | null) => void;
}

const CAN_MANAGE_MESSAGES_SETTINGS = 'can_manage_messages_settings';

export default class Email extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;
  @service declare brandService: BrandService;

  @tracked isAddressSidebarOpen = false;
  @tracked isAddressSidebarInUpdateMode = false;
  @tracked UNVERIFIED_LIMIT = 10;
  @tracked forceEmailSetupExperience = false;
  @tracked brands: Brand[] = [];
  @tracked senderEmailAddressSettings: SenderEmailAddressSettings[] = [];

  get shouldShowNewEmailAddressButtonInHeader() {
    return this.args.domains.some((domain) => domain.addresses.length > 0);
  }

  get canUseNewEmailSetupExperience() {
    return this.forceEmailSetupExperience || this.app.canUseNewEmailSetupExperience;
  }

  get tabs(): Array<TabItem & { canAccess: boolean }> {
    if (this.canUseNewEmailSetupExperience) {
      return [
        {
          label: 'new-settings.channels.email.tabs.domains-and-addresses',
          value: 'domains-and-addresses',
          canAccess: true,
        },
        {
          label: 'new-settings.channels.email.tabs.email-settings',
          value: 'email-settings',
          canAccess: true,
        },
      ];
    } else if (this.app.canUseMultiBrandEmailOpenBeta) {
      return [
        {
          label: 'new-settings.channels.email.tabs.addresses',
          value: 'addresses',
          canAccess: this.app.canUseMultiBrandEmailOpenBeta,
        },
        {
          label: 'new-settings.channels.email.tabs.customisation',
          value: 'customisation',
          canAccess:
            this.args.model.canAccessProactiveSettings ||
            this.args.model.canAccessProductSettings ||
            this.args.model.canAccessWorkspaceSettings,
        },
        {
          label: 'new-settings.channels.email.tabs.advanced',
          value: 'advanced',
          canAccess: this.app.canUseMultiBrandEmailOpenBeta,
        },
      ];
    } else {
      return [
        {
          label: 'new-settings.channels.email.tabs.email-forwarding',
          value: 'email-forwarding',
          canAccess: this.args.model.canAccessProactiveSettings,
        },
        {
          label: 'new-settings.channels.email.tabs.sender-email-addresses',
          value: 'sender-email-addresses',
          canAccess: true,
        },
        {
          label: 'new-settings.channels.email.tabs.domains',
          value: 'domains',
          canAccess: true,
        },
        {
          label: 'new-settings.channels.email.tabs.customisation',
          value: 'customisation',
          canAccess:
            this.args.model.canAccessProactiveSettings ||
            this.args.model.canAccessProductSettings ||
            this.args.model.canAccessWorkspaceSettings,
        },
        {
          label: 'new-settings.channels.email.tabs.advanced',
          value: 'advanced',
          canAccess: true,
        },
        {
          label: 'new-settings.channels.email.tabs.addresses',
          value: 'addresses',
          canAccess: this.app.canUseMultiBrandEmailOpenBeta,
        },
      ];
    }
  }

  get isMultiBrandEmailOpenBetaEnabled() {
    return this.app.canUseMultiBrandEmailOpenBeta;
  }

  get filteredTabs() {
    return this.tabs.filter((tab) => !!tab.canAccess);
  }

  get selectedTab() {
    if (this.filteredTabs.find((tab) => tab.value === this.args.tab)) {
      return this.args.tab;
    } else {
      return this.filteredTabs[0].value;
    }
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.brandsLoader).perform();
    taskFor(this.senderEmailAddressSettingsLoader).perform();
    if (!this.args.tab || !this.filteredTabs.find((tab) => tab.value === this.args.tab)) {
      this.setSelectedTab(this.filteredTabs[0].value);
    }
  }

  get isLoadBrandsRunning() {
    return taskFor(this.brandsLoader).isRunning;
  }

  get isLoadSenderEmailAddressSettingsRunning() {
    return taskFor(this.senderEmailAddressSettingsLoader).isRunning;
  }

  @dropTask
  *senderEmailAddressSettingsLoader(): TaskGenerator<SenderEmailAddressSettings[]> {
    if (this.app.canUseMultiBrandEmailOpenBeta) {
      this.senderEmailAddressSettings = yield this.store.findAll('sender-email-address-settings');
      return this.senderEmailAddressSettings;
    } else {
      return [];
    }
  }

  @dropTask
  *brandsLoader(): TaskGenerator<Brand[]> {
    if (!this.brandService.isAppOnRightMultibrandPlan) {
      return [];
    }

    if (this.app.canUseMultiBrandEmailOpenBeta) {
      try {
        this.brands = yield this.store.findAll('brand');
        return this.brands;
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('channels.email.settings.signature.load-error'),
        });
        return [];
      }
    } else {
      return [];
    }
  }

  get canSetupEmailForwarding() {
    return this.permissionsService.currentAdminCan(CAN_MANAGE_MESSAGES_SETTINGS);
  }

  get app() {
    return this.appService.app;
  }

  get customBounceSettings() {
    return this.app.customBounceSettings;
  }

  get dkimSettings() {
    return this.app.dkim_settings;
  }

  get customEmailAddresses() {
    return this.app.customEmailAddresses;
  }

  get totalUnverifiedEmailsLimit() {
    if (!this.app.customEmailAddresses) {
      return false;
    }
    let unverifiedEmailsCount = this.app.customEmailAddresses.filter(
      (email: CustomEmailAddress) => !email.verified,
    ).length;
    return unverifiedEmailsCount >= this.UNVERIFIED_LIMIT;
  }

  @action
  setSelectedTab(value: string): void {
    this.args.changeTab(value);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: `${value}-tab`,
      place: 'apps.app.settings.channels.email',
      section: 'ia-settings',
      context: `new-settings.channels.email.tabs.${value}`,
    });
  }

  @action
  openAddressSidebar(updatingExistingEmail: boolean) {
    this.isAddressSidebarOpen = true;
    this.isAddressSidebarInUpdateMode = updatingExistingEmail;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'address_sidebar',
      place: 'apps.app.settings.channels.email',
      updateMode: updatingExistingEmail,
    });
  }

  @action
  closeAddressSidebar() {
    this.isAddressSidebarOpen = false;
  }

  @action
  openArticle(id: number, name: string) {
    window.Intercom('showArticle', id);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: `learn_about_${name}`,
      place: 'settings_email_channels',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email': typeof Email;
  }
}
