/* import __COLOCATED_TEMPLATE__ from './billing.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { type TabItem } from '../common/standard-base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { dropTask } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { isEqual } from 'underscore';

interface Signature {
  Args: {
    selectedTab?: string;
    setSelectedTab: (value: string) => void;
    customer: $TSFixMe;
    addressModalIsOpen: boolean | null;
    closeAddressModal: () => void;
    creditCardModalIsOpen: boolean | null;
    closeCreditCardModal: () => void;
  };
  Blocks: {
    default: Array<unknown>;
  };
}

const TABS: TabItem[] = [
  {
    label: 'new-settings.workspace.billing.tabs.summary',
    value: 'summary',
  },
  {
    label: 'new-settings.workspace.billing.tabs.invoices',
    value: 'invoices',
  },
];

const MANAGE_USAGE: TabItem = {
  label: 'new-settings.workspace.billing.tabs.manage-usage',
  value: 'manage-usage',
};

const SETTINGS: TabItem = {
  label: 'new-settings.workspace.billing.tabs.settings',
  value: 'settings',
};

const MANAGE_SUBSCRIPTION: TabItem = {
  label: 'new-settings.workspace.billing.tabs.manage-subscription',
  value: 'manage-subscription',
};

const UPDATE_SUBSCRIPTION: TabItem = {
  label: 'new-settings.workspace.billing.tabs.update-subscription',
  value: 'update-subscription',
};

const BILLING_SUMMARY_REDESIGN: TabItem = {
  label: 'new-settings.workspace.billing.tabs.current-billing-period-charges',
  value: 'current-billing-period-charges',
};

const SUBSCRIPTION_DETAILS: TabItem = {
  label: 'new-settings.workspace.billing.tabs.subscription-details',
  value: 'subscription-details',
};

export default class Billing extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare purchaseAnalyticsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked isSecondaryWorkspace = false;
  @tracked contract: $TSFixMe;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.loadWorkspacesContracted).perform();
  }

  get stripeMigration() {
    return this.customerService.peekStripeMigration;
  }

  get hasMultiWorkspaces() {
    return this.subscription.numberOfSecondarySubscriptions > 0;
  }

  get hasAccessToPrimaryWorkspace() {
    return this.app.currentAdmin.apps.some((app: any) => app.id === this.primaryWorkspaceAppId);
  }

  get primaryWorkspaceAppId() {
    return this.contract?.primaryWorkspaceAppIdCode;
  }

  get primaryWorkspaceAppName() {
    return this.contract.primaryWorkspaceAppName;
  }

  get tabs(): TabItem[] {
    let currentTabs = [...TABS];

    if (this.app.canUseBillingSummaryRedesign) {
      currentTabs.shift();
      currentTabs.unshift(BILLING_SUMMARY_REDESIGN, SUBSCRIPTION_DETAILS);
    }

    if (!this.hideFinManageUsageLink) {
      currentTabs.push(MANAGE_USAGE);
    }

    if (!this.isSecondaryWorkspace && !taskFor(this.loadWorkspacesContracted).isRunning) {
      currentTabs.push(SETTINGS);
    }

    if (this.canManageAnnualSubscription) {
      currentTabs.push(MANAGE_SUBSCRIPTION);
    }

    if (this.canUpdateEarlyStageSubscription) {
      currentTabs.push(UPDATE_SUBSCRIPTION);
    }

    return currentTabs;
  }

  get app() {
    return this.appService.app;
  }

  get customer() {
    return this.customerService.customer;
  }

  get hideFinManageUsageLink() {
    return (
      !this.app.hasActiveSubscription || (this.app.onPricing5 && !this.customer.inSelfServeTrial)
    );
  }

  get canManageAnnualSubscription() {
    return (
      this.customerService.customer.hasActiveSubscription &&
      this.customerService.customer.hasDefaultPaymentMethod &&
      this.customerService.isSelfServeAnnualCustomer &&
      this.app.onPricing5
    );
  }

  get canUpdateEarlyStageSubscription() {
    return (
      this.app.canUseNewProgressionExperience &&
      this.customerService.earlyStageGraduation?.pricing5_X_Graduation &&
      !this.customerService.customer.cancellationRequestedOutsideMigration &&
      !this.customerService.earlyStageGraduation.lessThanOneDayBeforeGraduation
    );
  }

  get subscription() {
    return this.customer.subscription;
  }

  get isFrozenForNonPayment() {
    return this.app.isFrozenForNonPayment;
  }

  get isPrimaryWorkspace() {
    return this.subscription.isAnnualContractPrimarySubscription;
  }

  get displayEditSubscriptionButton() {
    return (
      !this.isFrozenForNonPayment &&
      !this.customer.currentlyOnEarlyStage &&
      ((this.app.isSalesforceContracted && this.isPrimaryWorkspace) ||
        this.customer.hasCustomPricing)
    );
  }

  get showLearnLinks() {
    return (
      this.app.canUsePerProductPricingFlow ||
      (!this.app.canUsePerProductPricingFlow && this.app.isOnValueBasedPricing) ||
      (this.app.onPricing5 &&
        !this.customer.currentlyOnEarlyStage &&
        !this.app.canUsePerProductPricingFlow &&
        !this.app.isOnValueBasedPricing)
    );
  }

  get isShowAddressModal() {
    return isEqual(this.args.addressModalIsOpen, 'true');
  }

  get isShowCreditCardModal() {
    return isEqual(this.args.creditCardModalIsOpen, 'true');
  }

  @action
  openFinPricingArticle() {
    safeWindowOpen(this.app.finPricingLearnMoreUrl, '_blank');
  }

  @action
  openPricingPage() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'how_pricing_works_link',
      place: 'billing_summary',
      context: 'learn-dropdown',
    });
    safeWindowOpen(this.app.pricingPageUrl, '_blank');
  }

  @action openInAppMessenger() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'talk_to_sales',
      place: 'billing_summary',
      context: 'hero banner', // eslint-disable-line @intercom/intercom/no-bare-strings
    });

    let textForMessenger;
    if (this.customer.currentlyOnEarlyStage) {
      textForMessenger = this.intl.t(
        'billing.summary.header.question-about-early-stage-subscription',
      );
    } else if (this.app.isSalesforceContracted) {
      textForMessenger = this.intl.t(
        'billing.summary.header.question-about-annual-contract-subscription',
      );
    } else {
      textForMessenger = this.intl.t('billing.summary.header.question-about-custom-subscription');
    }
    showNewMessageInIntercomWidget(textForMessenger);
  }

  @dropTask
  *loadWorkspacesContracted(): Generator<Promise<void>> {
    if (this.app.isSalesforceContracted) {
      this.contract = yield this.store.findRecord('billing/contract', this.app.id);
      this.isSecondaryWorkspace = this.contract?.isSecondarySubscription;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Billing': typeof Billing;
  }
}
