/* import __COLOCATED_TEMPLATE__ from './quick-invite-modal.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Router } from '@ember/routing';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type QuoteService from 'embercom/services/quote-service';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';
import { FULL_ACCESS_ROLE_NAME } from 'embercom/components/table-cells/teammate-list/standalone-access-level-selector';
import { TeammatesInviter } from 'embercom/lib/teammates-inviter';

interface Signature {
  Args: {
    isWorkEmail: boolean;
    closeModal: () => void;
    isStandalone?: boolean;
    roles?: any[];
  };
  Element: HTMLDivElement;
}

export default class QuickInviteModal extends Component<Signature> {
  @service declare appService: any;
  @service declare onboardingHomeService: any;
  @service declare router: Router;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare permissionsService: $TSFixMe;
  @service declare quoteService: QuoteService;
  @service declare customerService: any;

  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showModal = false;
  @tracked selectedStandaloneRoleId = this.fullAccessStandaloneRole?.id;

  teammatesInviter: TeammatesInviter;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.loadServices();
    this.intercomEventService.trackAnalyticsEvent({
      object: 'quick_invite_btn',
      action: 'viewed',
      locale: 'quick-invite-modal',
      success: true,
    });

    this.teammatesInviter = new TeammatesInviter({
      app: this.app,
      router: this.router,
      store: this.store,
      guideLibraryService: this.guideLibraryService,
    });
  }

  async loadServices() {
    await this.customerService.ensureDataIsLoaded.perform();
    if (this.customerService.activePlans?.length) {
      await taskFor(this.quoteService.getQuotes).perform([
        {
          ...DEFAULT_REQUEST_PARAMS,
          planIds: [this.customerService.activePlans[0].id],
          source: 'library-home',
        },
      ]);
    }
  }

  get app() {
    return this.appService.app;
  }

  private get fullAccessStandaloneRole() {
    return this.args.roles?.find((role: any) => role.name === FULL_ACCESS_ROLE_NAME);
  }

  get inTrial() {
    return (
      this.customerService.customer?.inSelfServeTrial ||
      this.customerService.customer?.currentEarlyStageCustomer
    );
  }

  get title() {
    if (this.args.isStandalone) {
      return this.intl.t(
        'onboarding.home.steps.invite-teammates.quick-invite-modal.standalone-title',
      );
    }
    return this.intl.t('onboarding.home.steps.invite-teammates.quick-invite-modal.title');
  }

  get formattedPrice() {
    if (!this.customerService.activePlanIds.length) {
      return '';
    }
    let plan = this.customerService.activePlanIds[0];
    let quote = this.quoteService.getQuoteById([plan]);
    let price = quote?.fullSeatPrice(plan, Metric.core_seat_count);

    if (!price) {
      return '';
    }

    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get priceString() {
    return this.intl.t(
      'onboarding.home.steps.invite-teammates.quick-invite-modal.permissions-and-pricing-explanation',
      {
        inTrial: this.inTrial,
        price: this.formattedPrice,
        htmlSafe: true,
      },
    );
  }

  @action async performInvite() {
    let success: boolean;
    try {
      let numberOfTeammatesInvited = this.teammatesInviter.validEmails.length;
      await this.teammatesInviter.performInvite(this.selectedStandaloneRoleId);
      success = true;

      this.notificationsService.notifyConfirmation(
        this.intl.t('onboarding.home.steps.invite-teammates.quick-invite-modal.success-banner', {
          numberOfTeammatesInvited,
        }),
      );
      this.args.closeModal();
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('onboarding.home.developer-invite.error'));
      success = false;
    }

    this.intercomEventService.trackAnalyticsEvent({
      object: 'quick_invite_btn',
      action: 'clicked',
      locale: 'quick-invite-modal',
      success,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::QuickInviteModal': typeof QuickInviteModal;
  }
}
