/* import __COLOCATED_TEMPLATE__ from './inbox-tour.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import OnboardingState from 'embercom/lib/inbox2/guide-library/state';
import { DefaultSteps } from 'embercom/lib/inbox2/guide-library/steps';
import { cached } from 'tracked-toolbox';
import { later } from '@ember/runloop';
import { getOwner, setOwner } from '@ember/application';
import ENV from 'embercom/config/environment';
import type Session from 'embercom/services/session';
// @ts-ignore
import { globalRef } from 'ember-ref-bucket';
import type ApplicationInstance from '@ember/application/instance';
import type RouterService from '@ember/routing/router-service';
import { OnboardingType } from 'embercom/lib/inbox2/onboarding/steps';
import confetti from 'canvas-confetti';
import { type StepData } from '../wizard';
import { type EmberKeyboardEvent } from 'embercom/lib/inbox2/types';
import platform from 'embercom/lib/browser-platform';

interface Args {
  stepData: StepData;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class InboxTour extends Component<Signature> {
  @service declare session: Session;
  @service intercomEventService: any;
  @service declare router: RouterService;
  @service onboardingHomeExternalStepService: any;
  @service declare inboxSidebarService: any;

  @globalRef('guide-library-confetti-canvas') declare confettiCanvas?: HTMLCanvasElement;

  @tracked activeStepIndex = 1;
  @tracked showConfettiCanvas = false;
  @tracked showAnimation = false;
  @tracked showBiggerPulse = false;

  @tracked didPressRightKeys = false;

  // We keep all the state contained within a single object so that it's easy
  // to copy and restore a previous object.
  @tracked state: OnboardingState;

  // History keeps a stack of the state object. Every time you go to the next
  // step, we push to this, and every time you go to the previous step, we pop
  // from it. This ensures we can restore state from a previous step.
  @tracked history: OnboardingState[] = [];

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    this.setup();
    this.state = new OnboardingState(getOwner(this) as any, OnboardingType.Default);
    this.history.pushObject(this.state.clone());
    this.activeStep.enter(this.state);
    later(
      this,
      () => this.activeStep.clickBinder(this.state, this.nextStep.bind(this)),
      ENV.APP._500MS,
    );
  }

  get steps() {
    return DefaultSteps;
  }

  @cached
  get activeStep() {
    let Class = this.steps[this.activeStepIndex];
    let instance = new Class(getOwner(this) as ApplicationInstance);
    setOwner(instance, getOwner(this));

    return instance;
  }

  get isFirstStep() {
    return this.activeStepIndex === 0;
  }

  get isLastStep() {
    return this.activeStepIndex === this.steps.length - 1;
  }

  get totalStepCount() {
    return this.steps.length;
  }

  get currentStepIndex() {
    return this.activeStepIndex + 1;
  }

  async setup() {}

  @action nextStep() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'next_button',
      place: 'onboarding',
      section: 'onboarding',
      step_index: this.activeStepIndex,
      onboarding_type: OnboardingType.Default,
    });

    this.moveToNextStep();
  }

  private moveToNextStep() {
    this.showBiggerPulse = false;
    if (this.isLastStep) {
      this.args.stepData.continueCallback();
    } else {
      this.activeStepIndex += 1;
      this.history.pushObject(this.state.clone());
      this.activeStep.enter(this.state);
      this.showAnimation = true;
    }

    // If a step has a transition state, we transition to the next step,
    // wait for a second before binding click.
    if (this.activeStep.hasTransitionState) {
      later(
        this,
        () => this.activeStep.clickBinder(this.state, this.nextStep.bind(this)),
        ENV.APP._100MS,
      );
    } else {
      this.activeStep.clickBinder(this.state, this.nextStep.bind(this));
    }

    if (this.isLastStep) {
      this.showConfettiCanvas = true;
      setTimeout(() => {
        this.fireConfettiAnimation();
      }, ENV.APP._500MS);
    }

    setTimeout(() => {
      this.showAnimation = false;
    }, ENV.APP._1000MS);
  }

  private fireConfettiAnimation() {
    if (!this.confettiCanvas) {
      return;
    }
    let confettiObj = confetti.create(this.confettiCanvas, {
      resize: true,
    });
    confettiObj({
      particleCount: 600,
      spread: 300,
      origin: { y: 0.3 },
      ticks: 2000,
      gravity: 2,
    });
    setTimeout(() => {
      this.showConfettiCanvas = false;
      this.args.stepData.continueCallback(false);
    }, ENV.APP._2000MS);
  }

  @action
  onClickInsideContent(event: PointerEvent) {
    event.preventDefault();
    event.stopPropagation();
    if (
      event.target &&
      event.target instanceof Element &&
      (event.target.classList.contains('guide-library-el-active') ||
        event.target.classList.contains('inbox2__guide-library-focused'))
    ) {
      return;
    }
    this.showBiggerPulse = true;
  }

  @action
  handleKeys(event: KeyboardEvent, kev: EmberKeyboardEvent) {
    // When you press cmd or shift, it sends three events. We can ignore the
    // first two which are just cmd or shift, because we do not have anything
    // that responds to just those keys.
    if (event.key === 'Meta' || event.key === 'Shift' || event.key === 'Control') {
      return;
    }

    if (!this.activeStep?.click?.keyCode) {
      return;
    }

    event.preventDefault();
    kev.stopPropagation();
    kev.stopImmediatePropagation();

    let code = this.activeStep.click.keyCode;
    if (!this.isValidCode(code, event)) {
      return;
    }

    this.didPressRightKeys = true;
  }

  @action handleKeyUp() {
    if (this.didPressRightKeys) {
      this.didPressRightKeys = false;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'key_pressed',
        object: 'keyboard_shortcut',
        place: 'onboarding',
        context: 'ai_helpdesk_wizard.inbox_tour.keyboard_shortcut',
        section: 'inbox-tour',
        sub_step_identifier: this.activeStep.id,
        onboarding_type: OnboardingType.Default,
      });
      this.activeStep.handleKeys(this.state, this.nextStep);
    }
  }

  private isValidCode(code: string, event: KeyboardEvent) {
    let keys = code.split('+');
    let isValid = true;

    keys.forEach((key) => {
      if (key === 'cmd') {
        isValid = this.cmdKey === 'meta' ? !!event.metaKey : !!event.ctrlKey;
      } else if (key === 'shift') {
        isValid = !!event.shiftKey;
      } else {
        // If isValid is already false, we shouldn't set it to true again.
        isValid = isValid && (key.toLowerCase() === event.key.toLowerCase() || key === event.code);
      }
    });

    return isValid;
  }

  private get cmdKey() {
    return platform.isMac ? 'meta' : 'ctrl';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::InboxTour': typeof InboxTour;
  }
}
