/* import __COLOCATED_TEMPLATE__ from './display-only-step-component-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    withTooltip: boolean;
    step: any;
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class DisplayOnlyStepComponentWrapper extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Common::DisplayOnlyStepComponentWrapper': typeof DisplayOnlyStepComponentWrapper;
  }
}
