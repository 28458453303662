/* import __COLOCATED_TEMPLATE__ from './library-home.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Router } from '@ember/routing';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type QuoteService from 'embercom/services/quote-service';
import checkEmailIsPersonal from 'embercom/utils/check-email-domain';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
}

const moreResources = [
  {
    id: 'helpCenter',
    link: 'https://www.intercom.com/help/',
    icon: 'article',
    title: 'onboarding.guide-library.more-resources.intercom-helpcenter.title',
    description: 'onboarding.guide-library.more-resources.intercom-helpcenter.description',
  },
  {
    id: 'zendeskSwitch',
    link: '#',
    performOnlyCallback: true,
    callback: () => window.Intercom('showArticle', 4393790), // https://www.intercom.com/help/en/articles/4393790-switching-from-zendesk-to-intercom
    icon: 'zendesk',
    title: 'onboarding.guide-library.more-resources.zendesk.title',
    description: 'onboarding.guide-library.more-resources.zendesk.description',
  },
  {
    id: 'academy',
    link: 'https://academy.intercom.com/',
    icon: 'academy',
    title: 'onboarding.guide-library.more-resources.academy.title',
    description: 'onboarding.guide-library.more-resources.academy.description',
  },
  {
    id: 'communityForum',
    link: 'https://community.intercom.com/',
    icon: 'multiple-people',
    title: 'onboarding.guide-library.more-resources.communityforum.title',
    description: 'onboarding.guide-library.more-resources.communityforum.description',
  },
  {
    id: 'webinars',
    link: 'https://www.intercom.com/webinars',
    icon: 'video',
    title: 'onboarding.guide-library.more-resources.webinars.title',
    description: 'onboarding.guide-library.more-resources.webinars.description',
  },
  {
    id: 'blog',
    link: 'https://www.intercom.com/blog/',
    icon: 'news-filled',
    title: 'onboarding.guide-library.more-resources.blog.title',
    description: 'onboarding.guide-library.more-resources.blog.description',
  },
  {
    id: 'developerHub',
    link: 'https://developers.intercom.com/',
    icon: 'code',
    title: 'onboarding.guide-library.more-resources.devhub.title',
    description: 'onboarding.guide-library.more-resources.devhub.description',
  },
  {
    id: 'askAQuestion',
    link: '#',
    performOnlyCallback: true,
    callback: () => window.Intercom('showNewMessage'),
    icon: 'chat-filled',
    title: 'onboarding.guide-library.more-resources.ask_a_question.title',
    description: 'onboarding.guide-library.more-resources.ask_a_question.description',
  },
] as const;

const PROHIBITED_LEVELS = ['evaluation_wizard_install_messenger'];
export default class LibraryHome extends Component<Signature> {
  @service declare appService: any;
  @service declare onboardingHomeService: any;
  @service declare router: Router;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare permissionsService: $TSFixMe;
  @service declare quoteService: QuoteService;

  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showModal = false;
  @tracked resetModalOpen = false;
  @tracked resetSurveyModalOpen = false;
  @tracked isWorkEmail = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.setIsWorkEmail();
  }

  async setIsWorkEmail() {
    let personal_email_domain = await checkEmailIsPersonal(
      this.app.currentAdmin.email.split('@')[1],
    );
    this.isWorkEmail = !personal_email_domain;
  }

  get app() {
    return this.appService.app;
  }

  get guide() {
    return this.onboardingHomeService.guide;
  }

  get foundationalSteps(): Array<any> {
    let foundationalLevel = this.guide.levels.find((level: any) => level.levelData?.isFoundational);
    if (foundationalLevel) {
      return foundationalLevel.steps;
    }
    return [];
  }

  get levels() {
    return this.guide.levels.filter(
      (level: any) =>
        !level.levelData?.isFoundational && !PROHIBITED_LEVELS.includes(level.identifier),
    );
  }

  get moreResources() {
    return moreResources;
  }

  get wizardStarted() {
    return this.onboardingHomeService.hasWizardsInProgress();
  }

  get showMoreResourcesCards() {
    return this.onboardingHomeService.hasWizardsInProgress();
  }

  get messengerTourId() {
    return '530135';
  }

  @action
  onFoundationalCardClick(transitionTo: string) {
    return this.router.transitionTo(transitionTo);
  }

  @action
  onInviteTeammateCardClick(transitionTo: string) {
    if (!this.app.onPricing5) {
      this.router.transitionTo(transitionTo);
    } else if (!this.permissionsService.currentAdminCan('can_manage_teammates')) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_teammates');
    } else {
      this.showModal = true;
    }
  }

  @action toggleResetModal() {
    this.resetModalOpen = !this.resetModalOpen;
  }

  @action
  async closeQuickInviteModal() {
    this.showModal = false;
    await this.onboardingHomeService.loadGuide();
  }

  @action
  toggleResetSurveyModal() {
    this.resetSurveyModalOpen = !this.resetSurveyModalOpen;
  }

  @action
  onSendInviteButtonPressed() {}
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::LibraryHome': typeof LibraryHome;
  }
}
