/* import __COLOCATED_TEMPLATE__ from './channel-targeting-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import ChannelTargetingDropdownItem from './channel-targeting-dropdown-item';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    channelGroups: $TSFixMe;
    readOnly: boolean;
  };
}

export default class ChannelTargetingDropdown extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare contentEditorService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  get targetChannels() {
    return this.contentEditorService.activeObject.targetChannels || [];
  }

  get multiselectChannels() {
    if (!this.args.channelGroups) {
      return {};
    }

    let channels: $TSFixMe = [];

    let messengerChannels = this._getChannelGroup(
      this.intl.t('operator.workflows.channels.groupings.messenger'),
      this.args.channelGroups.intercom,
      true,
    );
    let socialChannels = this._getChannelGroup(
      this.intl.t('operator.workflows.channels.groupings.social'),
      this.args.channelGroups.social,
      false,
    );
    let otherChannels = this._getChannelGroup(
      this.intl.t('operator.workflows.channels.groupings.other'),
      this.args.channelGroups.other,
      false,
    );

    channels.push(messengerChannels, socialChannels, otherChannels);

    return channels;
  }

  get selectedChannels() {
    if (this.targetChannels.length === 0) {
      return this.intl.t('operator.workflows.channels.select');
    }

    let channels = this.targetChannels.map((channel: string) => {
      return this.intl.t('operator.workflows.channels.type', {
        channel,
      });
    });
    return channels.join(', ');
  }

  @action
  toggleChannel(channel: string) {
    if (this.targetChannels.includes(channel)) {
      this.removeChannel(channel);
    } else {
      this.addChannel(channel);
    }
    this.resetClientPredicateGroup();
  }

  @action
  updateSelectedChannels(channels: $TSFixMe) {
    this.multiselectChannels.forEach((channelGroup: $TSFixMe) => {
      channelGroup.items.forEach((channel: $TSFixMe) => {
        if (channels.includes(channel.value) && !this.targetChannels.includes(channel.value)) {
          this.addChannel(channel.value);
        } else if (
          !channels.includes(channel.value) &&
          this.targetChannels.includes(channel.value)
        ) {
          this.removeChannel(channel.value);
        }
      });
    });
    this.resetClientPredicateGroup();
  }

  @action
  addChannel(channel: string) {
    this.contentEditorService.activeObject.set('targetChannels', [...this.targetChannels, channel]);
  }

  @action
  removeChannel(channel: string) {
    let updatedTargetChannels = this.targetChannels.filter(
      (previousChannel: string) => channel !== previousChannel,
    );
    this.contentEditorService.activeObject.set('targetChannels', updatedTargetChannels);
  }

  @action
  resetClientPredicateGroup() {
    this.contentEditorService.ruleset.set('clientPredicateGroup', { predicates: [] });
  }

  @action
  openAppStore(channel: string, appId: string) {
    let url = this.router.urlFor('apps.app.appstore', {
      queryParams: { search: channel, app_package_code: appId },
    });
    safeWindowOpen(url, '_blank');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'click',
      object: 'channel_install_button',
      place: 'channel_targeting',
      channel,
    });
  }

  @action
  openSmsSettings() {
    let url = this.router.urlFor('apps.app.settings.channels.sms');
    safeWindowOpen(url, '_blank');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'click',
      object: 'channel_install_button',
      place: 'channel_targeting',
      channel: 'sms',
    });
  }

  _getChannelGroup(groupName: string, channels: $TSFixMe, isIntercomChannels: boolean) {
    let group: { heading: string; items: any[] } = {
      heading: groupName,
      items: [],
    };

    channels.forEach((c: $TSFixMe) => {
      let channel = {
        text: this.intl.t('operator.workflows.channels.type', {
          channel: c.customLabelKey ?? c.value,
        }),
        value: c.value,
        icon: c.icon,
        isSelected: this.targetChannels.includes(c.value),
        isDisabled: this._areMessengerSwitchesDisabled(isIntercomChannels) || c.isDisabled,
        onSelectItem: () => {
          if (!c.isDisabled) {
            this.toggleChannel(c.value);
          }
        },
        component: ChannelTargetingDropdownItem,
        componentShouldReplaceItem: false,
        componentAttrs: {
          channel: c,
          openAppStore: this.openAppStore,
          openSmsSettings: this.openSmsSettings,
          isIntercomChannel: isIntercomChannels,
        },
        componentClasses:
          'content-editor__panels__custom__triggerable-custom-bot__channel-targeting-group__channel-targeting-dropdown-item',
      };
      group.items.push(channel);
    });
    return group;
  }

  _areMessengerSwitchesDisabled(isIntercomChannels: boolean) {
    return isIntercomChannels && !this._isMessengerInstalled();
  }

  _isMessengerInstalled() {
    return !!(
      this.appService.app?.hasAnyInstalledAtDate ||
      this.appService.app?.first_identified_request_at ||
      this.appService.app?.first_anonymous_request_at ||
      this.appService.app?.first_android_request_at ||
      this.appService.app?.first_ios_request_at
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::ChannelTargetingDropdown': typeof ChannelTargetingDropdown;
  }
}
