/* import __COLOCATED_TEMPLATE__ from './library-home-header.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Signature {
  Args: {};
  Element: HTMLDivElement;
}

const DropDownOptions = {
  helpCenter: 'onboarding.guide-library.page-header.dropdown.help-center',
  developerHub: 'onboarding.guide-library.page-header.dropdown.developer-hub',
  communityForum: 'onboarding.guide-library.page-header.dropdown.community-forum',
  academy: 'onboarding.guide-library.page-header.dropdown.academy',
  webinars: 'onboarding.guide-library.page-header.dropdown.webinars',
  blog: 'onboarding.guide-library.page-header.dropdown.blog',
};

export default class LibraryHomeHeader extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare onboardingHomeService: any;
  @service declare appService: any;

  get dropDownItems(): Array<any> {
    return [
      {
        items: [
          {
            text: this.intl.t(DropDownOptions.helpCenter),
            icon: 'article',
            value: 'helpCenter',
          },
          {
            text: this.intl.t(DropDownOptions.developerHub),
            icon: 'code',
            value: 'developerHub',
          },
          {
            text: this.intl.t(DropDownOptions.communityForum),
            icon: 'multiple-people',
            value: 'communityForum',
          },
          {
            text: this.intl.t(DropDownOptions.academy),
            icon: 'academy',
            value: 'academy',
          },
          {
            text: this.intl.t(DropDownOptions.webinars),
            icon: 'video',
            value: 'webinars',
          },
          {
            text: this.intl.t(DropDownOptions.blog),
            icon: 'megaphone',
            value: 'blog',
          },
        ],
      },
    ];
  }

  get showLearnDropdown() {
    return this.onboardingHomeService.hasWizardsInProgress();
  }

  @action
  onDropdownSelect(option: string) {
    this.triggerAnalytics(option);
    switch (option) {
      case 'helpCenter':
        safeWindowOpen('https://www.intercom.com/help/', '_blank');
        break;
      case 'developerHub':
        safeWindowOpen('https://developers.intercom.com/', '_blank');
        break;
      case 'communityForum':
        safeWindowOpen('https://community.intercom.com/', '_blank');
        break;
      case 'academy':
        safeWindowOpen('https://academy.intercom.com/', '_blank');
        break;
      case 'webinars':
        safeWindowOpen('https://www.intercom.com/webinars', '_blank');
        break;
      case 'blog':
      default:
        safeWindowOpen('https://www.intercom.com/blog/', '_blank');
        break;
    }
  }

  @action
  triggerAnalytics(option: string, action = 'clicked') {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: `learn_dropdown.${option}`,
      place: 'guide_library',
      context: 'onboarding_guide_library.library',
      section: 'guide_library_header',
    });
  }

  @action openSwitchFromZendeskArticle() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'switch_from_zendesk_button',
      context: 'onboarding_guide_library.library.header.switch_from_zendesk',
      place: 'library_home',
      section: 'library_home_header',
    });
    window.Intercom('showArticle', 4393790); // https://www.intercom.com/help/en/articles/4393790-switching-from-zendesk-to-intercom
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::LibraryHomeHeader': typeof LibraryHomeHeader;
  }
}
