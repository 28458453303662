/* import __COLOCATED_TEMPLATE__ from './welcome-screen.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { AppPricing5PriceModels, FIN_AI_COPILOT_BASE_ID, PLAN_DATA } from 'embercom/lib/billing';
import { findLatestVBP2PlanData, findLatestPricing5PlanData } from 'embercom/lib/billing';
import type Plan from 'embercom/models/plan';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type IntlService from 'embercom/services/intl';

interface Args {
  onButtonClick: () => void;
  firstName: string;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class WelcomeScreen extends Component<Signature> {
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare intl: IntlService;
  @service appService: any;

  get surveyProfilingDescriptionWithActivePlans() {
    let activePlans = this.appService.app.allPlansOnPricingModel.filter(
      (plan: Plan) => !plan.billableCustomerPlan && plan.activeTrial,
    );

    let { addons, plans } = activePlans.reduce(
      (acc: $TSFixMe, plan: Plan) => {
        if (PLAN_DATA[plan.id]?.addon) {
          if (
            plan.id !== FIN_AI_COPILOT_BASE_ID ||
            (plan.id === FIN_AI_COPILOT_BASE_ID && this.appService.app.canUseFinAiCopilotAddon)
          ) {
            acc.addons.push({ id: plan.id, key: plan.nameTranslationKey });
          }
        } else {
          acc.plans.push({ id: plan.id, key: plan.nameTranslationKey });
        }
        return acc;
      },
      { addons: [], plans: [] },
    );
    let planName = this.joinPlansAsString(plans);
    let addOnName = this.joinPlansAsString(addons, true);

    let translationKey = 'plan';
    if (addons.length === 1) {
      translationKey = 'plan-addon';
    } else if (addons.length > 1) {
      translationKey = 'multiple-addon';
    }

    return this.intl.t(
      `onboarding.guide-library.profiling-survey.welcome-screen.description-${translationKey}`,
      {
        addOnName,
        planName,
        planLink: this.intercomPlansExplainedHCLink,
        htmlSafe: true,
      },
    );
  }

  get intercomPlansExplainedHCLink() {
    if (this.appService.app.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1) {
      return 'https://www.intercom.com/help/en/articles/9061614-intercom-plans-explained';
    } else {
      return 'https://www.intercom.com/help/en/articles/8420845-intercom-plans-explained';
    }
  }

  get proactiveSupportPlusHCLink() {
    if (this.appService.app.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1) {
      return 'https://www.intercom.com/help/en/articles/9061648-proactive-support-plus';
    } else {
      return 'https://www.intercom.com/help/en/articles/8205747-proactive-support-plus';
    }
  }

  get trialPlanName() {
    let trialPlanData;
    let trialPlanIds = this.cardlessTrialService.activeOrExpiredPlanTrialIds;

    if (this.cardlessTrialService.isPricing5) {
      trialPlanData = findLatestPricing5PlanData(trialPlanIds);
    } else {
      trialPlanData = findLatestVBP2PlanData(trialPlanIds);
    }
    return this.intl.t(trialPlanData.nameTranslationKey);
  }

  private joinPlansAsString(plans: { id: string; key: string }[], isAddon = false): string {
    let hasCopilotAddon = false;
    if (isAddon) {
      hasCopilotAddon = plans.some((e) => e.id === FIN_AI_COPILOT_BASE_ID);
    }
    if (plans.length === 1) {
      return this.intl.t(plans[0].key);
    } else if (plans.length > 1) {
      let finalPlanTranslation = '';
      if (isAddon && hasCopilotAddon) {
        plans = plans.filter((p) => p.id !== FIN_AI_COPILOT_BASE_ID);
        finalPlanTranslation = this.intl.t(
          'onboarding.guide-library.profiling-survey.welcome-screen.desc-copilot-addon',
        );
      } else {
        let finalPlan = plans.pop();
        finalPlanTranslation = finalPlan?.key ? this.intl.t(finalPlan.key) : '';
      }
      return [
        plans.map((p) => this.intl.t(p.key)).join(', '),
        this.intl.t(
          'onboarding.guide-library.profiling-survey.welcome-screen.description-1-connector',
        ),
        finalPlanTranslation,
      ].join(' ');
    }
    return isAddon
      ? ''
      : this.intl.t(
          'onboarding.guide-library.profiling-survey.welcome-screen.description-1-fallback',
        );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::ProfilingSurvey::WelcomeScreen': typeof WelcomeScreen;
  }
}
