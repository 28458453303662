/* import __COLOCATED_TEMPLATE__ from './identity-verification-secrets.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type IdvSetting from 'embercom/models/messenger-security/idv-setting';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import { deleteRequest } from 'embercom/lib/inbox/requests';
const MAX_SECRET_KEYS = 10;

export default class IdentityVerificationSecrets extends Component {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @tracked isLoading = true;
  @tracked showCreateIdvSecretKeyModal = false;
  @tracked secretKeyName = '';
  @tracked isWebEnabled = false;
  @tracked isIosEnabled = false;
  @tracked isAndroidEnabled = false;
  @tracked selectedSecretKey?: IdvSetting;
  @tracked showDeleteIdvSecretKeyModal = false;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.loadIdentitySettingsTokens();
  }

  async loadIdentitySettingsTokens() {
    this.isLoading = true;
    await this.store.findAll('messenger-security/idv-setting');
    this.isLoading = false;
  }

  get sortedTokens() {
    return this.store
      .peekAll('messenger-security/idv-setting')
      .sortBy('createdAt')
      .reverse()
      .sortBy('deleted');
  }

  get canCreateSecretKey() {
    return this.sortedTokens.filter((token) => !token.deleted).length < MAX_SECRET_KEYS;
  }

  @action
  availablePlatforms(idvSetting: IdvSetting) {
    let platforms = [
      {
        label: this.intl.t('apps.app.settings.security.idv-setting-table.android'),
        value: 'android',
        checked: idvSetting.supportsAndroid,
      },
      {
        label: this.intl.t('apps.app.settings.security.idv-setting-table.ios'),
        value: 'ios',
        checked: idvSetting.supportsIos,
      },
      {
        label: this.intl.t('apps.app.settings.security.idv-setting-table.web'),
        value: 'web',
        checked: idvSetting.supportsWeb,
      },
    ];

    if (platforms.every((platform) => platform.checked)) {
      return this.intl.t('apps.app.settings.security.idv-setting-table.all');
    }

    return platforms
      .filter((platform) => platform.checked)
      .map((platform) => platform.label)
      .join(', ');
  }

  @action
  async createSecretKey() {
    let newToken = this.store.createRecord('messenger-security/idv-setting', {
      name: this.secretKeyName,
      supportsAndroid: this.isAndroidEnabled,
      supportsIos: this.isIosEnabled,
      supportsWeb: this.isWebEnabled,
    });

    try {
      await newToken.save();
      this.resetForm();
      this.showCreateIdvSecretKeyModal = false;
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'apps.app.settings.security.idv-create-secret-key-modal.success-creating-secret-key',
        ),
      );
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyError(
        this.intl.t(
          'apps.app.settings.security.idv-create-secret-key-modal.error-creating-secret-key',
        ),
      );
    }
  }

  @action
  toggleDeleteIdvSecretKeyModal(idvSetting: IdvSetting) {
    this.selectedSecretKey = idvSetting;
    this.showDeleteIdvSecretKeyModal = !this.showDeleteIdvSecretKeyModal;
  }

  @action
  async deleteSecretKey(idvSetting: IdvSetting) {
    await deleteRequest(`/ember/messenger_security/idv_settings/${idvSetting.id}`, {
      app_id: this.appService.app.id,
    });
    idvSetting.deleted = true;
    this.showDeleteIdvSecretKeyModal = false;
    this.notificationsService.notifyConfirmation(
      this.intl.t('apps.app.settings.security.idv-setting-table.deactivate-success'),
    );
    this.selectedSecretKey = undefined;
  }

  resetForm() {
    this.secretKeyName = '';
    this.isWebEnabled = false;
    this.isIosEnabled = false;
    this.isAndroidEnabled = false;
  }

  get activeTokens() {
    return this.sortedTokens.filter((token) => !token.deleted);
  }

  @action
  isLastTokenForPlatform(idvSetting: IdvSetting) {
    let isLastTokenForAndroid = false;
    let isLastTokenForIos = false;
    let isLastTokenForWeb = false;
    if (idvSetting.supportsAndroid) {
      isLastTokenForAndroid =
        this.activeTokens.filter((token) => token.supportsAndroid).length === 1;
    }
    if (idvSetting.supportsIos) {
      isLastTokenForIos = this.activeTokens.filter((token) => token.supportsIos).length === 1;
    }
    if (idvSetting.supportsWeb) {
      isLastTokenForWeb = this.activeTokens.filter((token) => token.supportsWeb).length === 1;
    }
    return isLastTokenForAndroid || isLastTokenForIos || isLastTokenForWeb;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::IdentityVerificationSecrets': typeof IdentityVerificationSecrets;
    'new-settings/workspace/security/identity-verification-secrets': typeof IdentityVerificationSecrets;
  }
}
