/* import __COLOCATED_TEMPLATE__ from './ew-chatbot-fin-preview.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ContentImportService from 'embercom/services/content-import-service';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';

interface Signature {
  Element: never;
  Args: {
    transitionToStep: (id: string) => void;
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class EwChatbotFinPreview extends Component<Signature> {
  @service declare contentImportService: ContentImportService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare onboardingHomeExternalStepService: any;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    taskFor(this.fetchContentImportSources).perform();
  }

  get contentImportSources() {
    return this.contentImportService.contentImportSources;
  }

  get showFinPreview() {
    return this.contentImportService.successfulRuns?.length > 0;
  }

  get showAddMoreContentBtn() {
    return (
      this.contentImportService.failedRuns?.length > 0 &&
      !this.contentImportService.inProgressRunExists
    );
  }

  @dropTask
  *fetchContentImportSources() {
    yield this.contentImportService.fetchContentImportSources();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Common::AutomationWizard::EwChatbotFinPreview': typeof EwChatbotFinPreview;
  }
}
