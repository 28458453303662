/* import __COLOCATED_TEMPLATE__ from './standard-base.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type ComponentLike } from '@glint/template';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type Breadcrumb } from 'embercom/components/settings/standard-header';
import type PALETTE from '@intercom/pulse/lib/palette';

export interface TabItem {
  label: string;
  value: string;
  tooltip?: Tooltip;
  pill?: {
    text: string;
    color: 'blue' | 'sky' | 'transparent' | 'green' | 'gray' | 'yellow-light' | 'yellow' | 'red';
    stylingClass?: string;
  };
}

export interface Tooltip {
  text: string;
  icon: InterfaceIconName;
  iconColor: keyof typeof PALETTE;
}

interface Signature {
  Element: HTMLDivElement;
  Args: {
    tabs?: TabItem[] | undefined;
    title: string;
    onTabSelected?: (tabId: string) => void;
    selectedTab?: string;
    tabStyles?: string;
    contentContainerStylingClass?: string;
    allowCenterAlignment?: boolean; // default: false | Responsible for centering the content in large screens with max width of container set to 1440px
    smallerWidth?: boolean; // default: false | Responsible for centering content with max width set to 1028px
    breadcrumbs?: Breadcrumb[];
    customHeaderTitle?: ComponentLike;
    additionalTabContainerClass?: string;
    hideBottomBorder?: boolean;
    hideMenuToggle?: boolean;
  };
  Blocks: {
    headerBody?: [];
    settingsContent?: [];
    customHeader?: [];
  };
}

export default class StandardBase extends Component<Signature> {
  @service declare appService: any;

  get hasNoTabs(): boolean {
    return !this.args.tabs || this.args.tabs.length < 1;
  }

  get shouldHideBottomBorder() {
    if (this.args.hideBottomBorder !== undefined) {
      return this.args.hideBottomBorder;
    }
    return !this.hasNoTabs;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Common::StandardBase': typeof StandardBase;
  }
}
