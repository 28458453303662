/* import __COLOCATED_TEMPLATE__ from './get-started-with-product-tours.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { openOverflowMenuIfLinkNotVisible } from 'embercom/lib/primary-nav/open-overflow-menu';

const PRODUCT_TOURS_TARGET_NAME = 'PrimaryNav Product Tours link';

export default Component.extend({
  router: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  onboardingHomeService: service(),
  onboardingHomeExternalStepService: service(),
  intercomEventService: service(),
  step: null,
  completionCallback: null,

  trackStartedTourAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.step.analyticsMetadata,
      action: 'started',
      object: 'get_started_with_product_tours_tour',
      section: 'index',
    });
  },

  getStarted: action(function () {
    this.trackStartedTourAnalyticsEvent();
    openOverflowMenuIfLinkNotVisible(PRODUCT_TOURS_TARGET_NAME);
    this.onboardingHomeExternalStepService.start('get_started_with_product_tours');
  }),
});
