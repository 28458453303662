/* import __COLOCATED_TEMPLATE__ from './instructions-component-with-tech-bundle.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import ajax from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';

export default class InstructionsComponentWithTechBundle extends Component {
  @service appService;
  @service intercomEventService;
  @service realTimeEventService;
  @service intl;
  @service router;

  @tracked confirmationCode;
  @tracked confirmationLink;
  @tracked selectedEmailProvider;
  @tracked emailProviderOptions = [
    { value: 'google', label: this.intl.t('onboarding.home.steps.forward-emails.Google') },
    { value: 'microsoft', label: this.intl.t('onboarding.home.steps.forward-emails.Microsoft') },
    { value: 'other', label: this.intl.t('onboarding.home.steps.forward-emails.Other') },
  ];

  constructor() {
    super(...arguments);
    this.realTimeEventService.on(
      'ReceivedForwardEmailConfirmationCode',
      this,
      'fetchEmailForwardingConfirmationCode',
    );
    if (this.args.onSettingsPage) {
      this.realTimeEventService.on(
        'ReceivedForwardEmailConfirmationLink',
        this,
        'fetchEmailForwardingConfirmationLink',
      );
    }
    this.selectedEmailProvider = this.emailProviderOptions[0].value;
  }

  get inboxLink() {
    return this.router.urlFor(
      'inbox.workspace.inbox.inbox',
      this.appService.app.id,
      InboxCategory.Shared,
      InboxType.Unassigned,
    );
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.realTimeEventService.off(
      'ReceivedForwardEmailConfirmationCode',
      this,
      'fetchEmailForwardingConfirmationCode',
    );
    if (this.args.onSettingsPage) {
      this.realTimeEventService.off(
        'ReceivedForwardEmailConfirmationLink',
        this,
        'fetchEmailForwardingConfirmationLink',
      );
    }
  }

  @action
  trackClickEvent(action, value) {
    let object;
    if (action === 'selected') {
      object = `${value}_email_provider`;
    } else {
      object = `inbox_link_on_${value}`;
    }

    this.intercomEventService.trackAnalyticsEvent({
      action,
      context: 'setup_email_forwarding_section',
      place: 'settings_channels_email',
      object,
    });
  }

  @action
  handleUnsetEmail() {
    if (this.confirmationLink) {
      this.confirmationLink = '';
    }
    this.args.unsetEmail();
  }

  @action
  async fetchEmailForwardingConfirmationCode() {
    let request = {};
    request = {
      url: '/ember/forward_email_confirmation_code',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    };

    let code = await ajax(request);

    if (code) {
      this.confirmationCode = code['confirmation_code'];
    }
  }

  @action
  async fetchEmailForwardingConfirmationLink() {
    let request = {};
    if (this.args.onSettingsPage) {
      request = {
        url: '/ember/forward_email_confirmation_code',
        type: 'GET',
        data: {
          app_id: this.appService.app.id,
        },
      };
    }
    let link = await ajax(request);
    if (link) {
      this.confirmationLink = link['confirmation_code'];
    }
  }

  @action
  verifyEmailForwarding() {
    return this.args.verifyEmailForwarding.perform(this.args.email);
  }
}
