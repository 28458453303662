/* import __COLOCATED_TEMPLATE__ from './route-support-conversations.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import EmberObject, { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { loadMessengerSettings } from 'embercom/lib/home/load-messenger-settings';

export default class RouteSupportConversations extends Component {
  @service appService;
  @service intl;
  @service intercomEventService;
  @service notificationsService;

  get conversationRouting() {
    return this.args.step.modelData.conversationRouting;
  }

  get conversationRoutingDisabled() {
    return !this.conversationRouting.enabled && !this.args.step.available;
  }

  get messengerSettings() {
    let messengerSettingsFromModel = this.args.step.modelData?.messengerSettings;
    if (messengerSettingsFromModel) {
      return messengerSettingsFromModel;
    }
    return loadMessengerSettings(this);
  }

  get adminCanAccessProductSettings() {
    return this.appService.app.currentAdmin.canAccessProductSettings;
  }

  get settings() {
    return EmberObject.create({ conversationRouting: this.conversationRouting });
  }

  get isSaveEnabled() {
    if (!this.adminCanAccessProductSettings) {
      return false;
    }
    if (this.conversationRoutingDisabled) {
      return false;
    }
    return this.conversationRouting.isValid;
  }

  @dropTask
  *completeStep() {
    try {
      let settingsHaveChanged = this.conversationRouting.hasDirtyAttributes;
      this.conversationRouting.enabled = true;
      yield this.conversationRouting.save();
      if (settingsHaveChanged) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'changed',
          object: 'route_support_conversations',
          ...this.args.step.analyticsMetadata,
        });
      }
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.route-support-conversations.default-error'),
      });
      return;
    }

    if (this.args.step.completed) {
      return this.args.completionCallback({
        stepCompletedText: this.intl.t(
          'onboarding.home.steps.route-support-conversations.operator-settings-saved',
        ),
      });
    }
    yield this.args.completionCallback();
  }

  @action
  markAsCompleted() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'step_skipped',
      object: 'route_support_conversations',
      ...this.args.step.analyticsMetadata,
    });
    this.args.completionCallback();
  }
}
