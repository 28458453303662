/* import __COLOCATED_TEMPLATE__ from './ew-connect-to-intercom.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { type StepData } from '../../wizard';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

interface Signature {
  Element: never;
  Args: {
    step: any;
    stepData: StepData;
  };
}

export default class EWConnectToIntercom extends Component<Signature> {
  @service declare intercomEventService: any;

  @action
  connectToIntercomClicked() {
    this.args.stepData.continueCallback();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'connect_to_intercom_button',
      place: 'messenger-installation-wizard.ew-connect-to-intercom',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::MessengerInstallationWizard::EWConnectToIntercom': typeof EWConnectToIntercom;
  }
}
