/* import __COLOCATED_TEMPLATE__ from './publish-to-web.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import steps from 'embercom/lib/home/steps';

export default Component.extend({
  router: service(),
  appService: service(),
  onboardingHomeService: service(),
  onboardingHomeExternalStepService: service(),
  permissionsService: service(),
  intercomEventService: service(),
  intl: service(),
  dependentStepIdentifier: 'create_short_article',
  dependentStepTitle: computed('intl.locale', 'dependentStepIdentifier', function () {
    if (!this.dependentStepIdentifier || !steps[this.dependentStepIdentifier].title) {
      return '';
    }
    return this.intl.t(`onboarding.home.steps-header.${steps[this.dependentStepIdentifier].title}`);
  }),

  step: null,

  app: readOnly('appService.app'),

  createShortArticleStep: computed(
    'onboardingHomeService.guide.steps.@each.identifier',
    function () {
      return this.get('onboardingHomeService.guide').findStepByIdentifier('create_short_article');
    },
  ),
  needsCreateArticleStepCompletion: true,
  createShortArticleStepCompleted: readOnly('createShortArticleStep.completed'),
  showDependentStepWarning: computed(
    'needsCreateArticleStepCompletion',
    'createShortArticleStepCompleted',
    function () {
      return this.needsCreateArticleStepCompletion && !this.createShortArticleStepCompleted;
    },
  ),

  trackClickedAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.step.analyticsMetadata,
      action: 'clicked',
      object: 'go_to_articles',
      section: 'inline_step',
    });
  },

  async _ensureArticlesExist() {
    await this.onboardingHomeExternalStepService.generateExampleDataForStep(this.step.identifier);
  },

  transitionToTour: action(async function () {
    this.trackClickedAnalyticsEvent();

    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    await this._ensureArticlesExist();

    this.router.transitionTo('apps.app.settings.helpcenter.workspace-helpcenter.collections', {
      queryParams: {
        external_step: this.step.identifier,
        shouldShowStepBanner: true,
      },
    });
  }),
});
