/* import __COLOCATED_TEMPLATE__ from './reset-home-modal-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

interface Signature {
  Args: {
    isOpen: boolean;
    toggleModal: () => void;
    fromGreatGuidance?: boolean;
  };
  Element: never;
}

export default class ResetHomeModalComponent extends Component<Signature> {
  @service declare onboardingHomeService: any;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare appService: $TSFixMe;

  @dropTask
  *resetOnboardingHome() {
    yield this.onboardingHomeService.resetGuide();

    if (this.args.fromGreatGuidance) {
      this.greatGuidanceService.fetchSteps(this.appService.app.id);
    }
    this.args.toggleModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::Home::ResetHomeModalComponent': typeof ResetHomeModalComponent;
  }
}
