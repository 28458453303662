/* import __COLOCATED_TEMPLATE__ from './email-setup-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type DomainService from 'embercom/services/domain-service';
import type { Domain, DomainEmailAddress } from 'embercom/services/domain-service';
import type Brand from 'embercom/models/brand';
import type IntlService from 'embercom/services/intl';

interface Signature {
  Args: {
    email: string | null;
    step: string | null;
    isOpen: boolean;
    onClose: () => void;
    changeStep: (step: string | null, origin: string) => void;
    setEmail: (email: string | null) => Promise<void>;
    domain: Domain | null;
    previousStepForDomainSetup: string | null;
    setPreviousStepForDomainSetup: (step: string | null) => void;
    brands: Brand[];
  };
}

export default class EmailSetupSidebar extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare domainService: DomainService;
  @tracked showInviteTeammateModal = false;

  @action openInviteTeammateModal() {
    if (!this.appService.app.onPricing5) {
      this.router.transitionTo('apps.app.settings.workspace.teammates');
      return;
    }

    if (this.permissionsService.currentAdminCan('can_manage_teammates')) {
      this.showInviteTeammateModal = true;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'invite_engineer',
        place: `${this.args.step}-step`,
        context: 'email-setup-sidebar',
      });
      return;
    }

    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_teammates');
    this.showInviteTeammateModal = false;
  }

  get isOverviewWithVerificationOrForwardingEnabled() {
    return (
      this.args.step === 'overview' &&
      (this.emailAddress?.verified || this.emailAddress?.forwardingEnabled)
    );
  }

  get showBackButton() {
    return !(
      this.args.step === 'add-address' ||
      this.args.step === 'edit-address' ||
      this.args.step === 'setup-domain' ||
      this.args.step === 'access-setup-domain' ||
      this.isOverviewWithVerificationOrForwardingEnabled ||
      this.setupComplete
    );
  }

  get domainSteps() {
    return ['access-setup-domain', 'setup-domain-manually', 'setup-domain'];
  }

  get setupComplete() {
    if (this.args.step === 'overview' && this.emailAddress && this.args.domain) {
      if (this.emailAddress.outboundOnly) {
        return this.emailAddress.verified && this.args.domain.isAuthenticated;
      } else {
        return this.emailAddress.forwardingEnabled && this.args.domain.isAuthenticated;
      }
    }
    return false;
  }

  get isBannerHidden() {
    let stepsWithNoBanner = [
      'add-address',
      'edit-address',
      'setup-domain',
      'setup-email-forwarding',
    ];
    return this.args.step && (stepsWithNoBanner.includes(this.args.step) || this.setupComplete);
  }

  get headerText() {
    if (this.args.step === 'add-address') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.add-address-step.header',
      );
    } else if (this.args.step === 'overview') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.overview-step.header',
      );
    } else if (this.args.step === 'setup-email-forwarding') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.setup-email-forwarding-step.header',
      );
    } else if (
      this.args.step === 'setup-domain-manually' ||
      this.args.step === 'setup-domain' ||
      this.args.step === 'access-setup-domain'
    ) {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.setup-domain-step.header',
      );
    } else if (this.args.step === 'edit-address') {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.edit-address.header',
      );
    } else {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.edit-address.header',
      );
    }
  }

  get subHeaderText() {
    if (this.setupComplete) {
      return this.intl.t(
        'new-settings.channels.email.connect-email.email-setup.email-setup-complete',
      );
    } else if (this.args.step === 'overview' || this.args.step === 'setup-email-forwarding') {
      return this.intl.t('new-settings.channels.email.connect-email.email-setup.email-setup-for', {
        email: this.args.email,
        htmlSafe: true,
      });
    } else if (
      this.args.step === 'setup-domain-manually' ||
      this.args.step === 'access-setup-domain' ||
      this.args.step === 'setup-domain'
    ) {
      return this.intl.t('new-settings.channels.email.connect-email.email-setup.domain-setup-for', {
        domainName: this.args.domain?.name || 'company.com',
        htmlSafe: true,
      });
    } else if (this.args.step === 'edit-address') {
      return this.intl.t('new-settings.channels.email.connect-email.email-setup.editing-address', {
        email: this.args.email,
        htmlSafe: true,
      });
    } else {
      return '';
    }
  }

  get emailAddress() {
    return this.args.domain?.addresses.find(
      (address: DomainEmailAddress) => address.email === this.args.email,
    );
  }

  get isReloadEmailAddressesTaskRunning(): boolean {
    return taskFor(this.reloadModels).isRunning;
  }

  @dropTask
  *reloadModels(): TaskGenerator<void> {
    yield this.domainService.load();
  }

  @action
  handleBackButtonClick() {
    window.history.back();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'back_button',
      place: `${this.args.step}-step`,
      context: 'email-setup-sidebar-header',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::EmailSetupSidebar': typeof EmailSetupSidebar;
    'new-settings/channels/email/domains-and-addresses/email-setup-sidebar': typeof EmailSetupSidebar;
  }
}
