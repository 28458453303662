/* import __COLOCATED_TEMPLATE__ from './template-card.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  objectIcons,
  objectTypes,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import type Template from 'embercom/models/template';
import type SeriesTemplate from 'embercom/models/series/template';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type { DemoOutboundMessageType } from '../try-outbound-demo-message-modal';
import type ThemeService from 'embercom/services/theme-service';
import { capitalize } from '@ember/string';

type TemplateTypes = Template | SeriesTemplate | DemoOutboundMessageType;

interface Signature {
  Args: {
    template: TemplateTypes;
    index: number;
    onSelect: (template: TemplateTypes) => void;
    isEmptyState?: boolean;
    showBackgroundColor?: boolean;
    showContentTypeIcons: boolean;
    removePadding?: boolean;
    inOutboundDemoModal?: boolean;
  };
}

export default class TemplateCard extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare themeService: ThemeService;

  get app() {
    return this.appService.app;
  }

  get objectIcons() {
    return objectIcons;
  }

  get humanReadableObjectNames() {
    return {
      ...humanReadableObjectNames,
      [objectTypes.customBot]: 'Workflow',
      [objectTypes.workflow]: 'Workflow',
      [objectTypes.tour]: 'Product tour',
    };
  }

  get objectTypes() {
    return objectTypes;
  }

  get templateThumbnail() {
    if (!this.args.template?.thumbnailAsset) {
      return (
        this.args.template.thumbnail ||
        'https://downloads.intercomcdn.com/i/o/229700637/1238890dcac35aefd4b30db4/preview-placeholder.png'
      );
    }

    let thumbnailAsset = this.args.template.thumbnailAsset;
    let [fileName, fileExtension] = thumbnailAsset.split('.');
    let thumbnailAssetDark = `${fileName}-dark.${fileExtension}`;

    return this.themeService.darkModeEnabled
      ? assetUrl(thumbnailAssetDark)
      : assetUrl(thumbnailAsset);
  }

  @action getFormattedObjectTypeName(objectType: number): string {
    let splitted = this.humanReadableObjectNames[objectType].split(' ');
    return splitted.map((word) => capitalize(word)).join(' ');
  }

  @action
  onTemplateClick(template: TemplateTypes) {
    if (this.args.isEmptyState) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'message_template',
        context: 'empty_state',
        template_id: template.id,
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: template.id,
        context: 'template_modal',
      });
    }

    if (template.isAvailable) {
      this.args.onSelect(template);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::TemplateCard': typeof TemplateCard;
    'outbound/new-content-modal/template-card': typeof TemplateCard;
  }
}
