/* import __COLOCATED_TEMPLATE__ from './set-up-messenger.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { equal, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import {
  AUDIENCE,
  INSTALL_TYPE,
  LOGGED_IN_CODE_DROPDOWN_ITEMS,
  LOGGED_OUT_CODE_DROPDOWN_ITEMS,
  THIRD_PARTY_DROPDOWN_ITEMS,
} from 'embercom/lib/messenger/installation-constants';

export default Component.extend({
  intercomEventService: service(),
  intl: service(),
  appService: service(),
  app: readOnly('appService.app'),

  completionCallback: null,

  development: false,
  selectedInstallMethod: null,
  selectedAudience: null,
  stepsMerged: null,

  completionText: computed('intl.locale', 'selectedAudience', 'stepsMerged', function () {
    if (this.selectedAudience === AUDIENCE.LOGGED_OUT || this.stepsMerged) {
      return this.intl.t('onboarding.home.steps.set-up-messenger.completion-text');
    }
    return this.intl.t('onboarding.home.steps.set-up-messenger.messenger-installed');
  }),

  descriptionText: computed(
    'development',
    'intl.locale',
    'selectedAudience',
    'stepsMerged',
    function () {
      if (this.development) {
        return this.intl.t('onboarding.home.steps.set-up-messenger.build-on-those');
      } else if (this.selectedAudience === AUDIENCE.LOGGED_OUT) {
        return this.intl.t('onboarding.home.steps.set-up-messenger.description-text');
      } else {
        return this.intl.t(
          'onboarding.home.steps.set-up-messenger.logged-in-users-step-description',
        );
      }
    },
  ),

  dropdownItems: computed('selectedAudience', 'selectedThirdPartyInstallType', function () {
    if (this.selectedThirdPartyInstallType) {
      return THIRD_PARTY_DROPDOWN_ITEMS;
    }
    switch (this.selectedAudience) {
      case AUDIENCE.LOGGED_OUT:
        return LOGGED_OUT_CODE_DROPDOWN_ITEMS;
      default:
        return LOGGED_IN_CODE_DROPDOWN_ITEMS;
    }
  }),

  selectedCodeInstallType: equal('selectedInstallMethod', INSTALL_TYPE.CODE),
  selectedThirdPartyInstallType: equal('selectedInstallMethod', INSTALL_TYPE.THIRD_PARTY),

  markAsDoneText: computed('intl.locale', 'selectedAudience', function () {
    return this.selectedAudience === AUDIENCE.LOGGED_OUT
      ? this.intl.t('onboarding.home.steps.set-up-messenger.mark-as-done-text')
      : this.intl.t('onboarding.home.steps.set-up-messenger.mark-as-done-text-logged');
  }),

  selectedInstallTypeAnalyticsName: ternary('selectedCodeInstallType', 'code', 'third_party_app'),
  baseAnalyticsMetadata: computed(
    'step.analyticsMetadata',
    'selectedInstallTypeAnalyticsName',
    function () {
      return {
        ...this.step.analyticsMetadata,
        install_with: this.selectedInstallTypeAnalyticsName,
      };
    },
  ),
  linkAnalyticsMetadata: computed('step.analyticsMetadata', function () {
    return {
      ...this.step.analyticsMetadata,
      context: 'completed_step',
    };
  }),

  sendAnalyticsEvent(object) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.step.analyticsMetadata,
      action: 'clicked',
      object,
    });
  },

  setInstallMethod: action(function (method) {
    this.set('selectedInstallMethod', method);
  }),

  actions: {
    markAsCompleted() {
      this.sendAnalyticsEvent('mark_as_done');
      this.completionCallback();
    },
  },
});
