/* import __COLOCATED_TEMPLATE__ from './channel-targeting.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    isEditing: boolean;
    title: string;
    channels: $TSFixMe;
    isIntercomChannels: boolean;
  };
}

export default class ChannelTargeting extends Component<Signature> {
  @service declare contentEditorService: $TSFixMe;
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;

  @tracked installButtonChannel: string | null = null;

  get targetChannels() {
    return this.contentEditorService.activeObject.targetChannels;
  }

  @action
  updateTargetChannel(channel: string) {
    if (this.targetChannels.includes(channel)) {
      this.removeChannel(channel);
    } else {
      this.addChannel(channel);
    }
    this.resetClientPredicateGroup();
  }

  @action
  addChannel(channel: string) {
    this.contentEditorService.activeObject.set('targetChannels', [...this.targetChannels, channel]);
  }

  @action
  removeChannel(channel: string) {
    let updatedTargetChannels = this.targetChannels.filter(
      (previousChannel: string) => channel !== previousChannel,
    );
    this.contentEditorService.activeObject.set('targetChannels', updatedTargetChannels);
  }

  @action
  resetClientPredicateGroup() {
    this.contentEditorService.ruleset.set('clientPredicateGroup', { predicates: [] });
  }

  @action
  showInstallButton(channel: string) {
    this.installButtonChannel = channel;
  }

  @action
  openMessengerInstallation() {
    let url = this.router.urlFor('apps.app.settings.channels.messenger.install');
    safeWindowOpen(url, '_blank');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'click',
      object: 'channel_install_button',
      place: 'channel_targeting',
      channel: 'messenger',
    });
  }

  @action
  openAppStore(channel: string, appId: string) {
    let url = this.router.urlFor('apps.app.appstore', {
      queryParams: { search: channel, app_package_code: appId },
    });
    safeWindowOpen(url, '_blank');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'click',
      object: 'channel_install_button',
      place: 'channel_targeting',
      channel,
    });
  }

  @action
  openSmsSettings() {
    let url = this.router.urlFor('apps.app.settings.channels.sms');
    safeWindowOpen(url, '_blank');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'click',
      object: 'channel_install_button',
      place: 'channel_targeting',
      channel: 'sms',
    });
  }

  get isMessengerInstalled() {
    return (
      !!this.appService.app.first_anonymous_request_at ||
      !!this.appService.app.first_identified_request_at ||
      !!this.appService.iosSdkApp?.is_activated ||
      !!this.appService.androidSdkApp?.is_activated
    );
  }

  get areMessengerSwitchesDisabled() {
    return this.args.isIntercomChannels && !this.isMessengerInstalled;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::ChannelTargeting': typeof ChannelTargeting;
  }
}
