/* import __COLOCATED_TEMPLATE__ from './collapsed.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import templateOnlyComponent from '@ember/component/template-only';
import { type Step } from 'embercom/objects/onboarding/checklist/step';

interface Args {
  completedSteps: number;
  totalSteps: number;
  nextStep: Step | undefined;
}

let CollapsedChecklist = templateOnlyComponent<Args>();
export default CollapsedChecklist;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::Checklist::Collapsed': typeof CollapsedChecklist;
  }
}
