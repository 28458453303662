/* import __COLOCATED_TEMPLATE__ from './tab.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type DomainService from 'embercom/services/domain-service';
import { type Domain } from 'embercom/services/domain-service';
import type Brand from 'embercom/models/brand';
interface Args {
  senderEmailAddressSettings: SenderEmailAddressSettings[];
  step: string | null;
  email: string | null;
  changeStep: (step: string | null, place: string) => void;
  setEmail: (email: string | null) => Promise<void>;
  domain: Domain | null;
  setDomainName: (domainName: string | null) => void;
  previousStepForDomainSetup: string | null;
  setPreviousStepForDomainSetup: (step: string | null) => void;
  brands: Brand[];
}

interface Signature {
  Args: Args;
}

export default class Tab extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare domainService: DomainService;
  @tracked emailSettings: SenderEmailAddressSettings | null = null;

  get showEmptyState() {
    return !this.domains.some((domain) => domain.addresses.length > 0);
  }

  get shouldShowEmailSetupSidebar() {
    if (this.args.step) {
      return true;
    } else {
      return false;
    }
  }

  get domains() {
    return this.domainService.domains;
  }

  @action
  async closeEmailSetupSidebar() {
    this.args.changeStep(null, 'setup-sidebar');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::Tab': typeof Tab;
  }
}
