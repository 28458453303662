/* import __COLOCATED_TEMPLATE__ from './fallback-option-hand-off.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: never;
  Args: {
    disableContinue: boolean;
    continueCallback: () => void;
    triggerSave: () => void;
  };
}

enum HandOverModeEnum {
  zendesk = 'zendesk',
  othertool = 'othertool',
}

export default class FallbackOptionHandOff extends Component<Signature> {
  @service declare intl: any;

  @tracked selectedHandoverMode: HandOverModeEnum = HandOverModeEnum.zendesk;
  @tracked continueButtonEnabled = false;

  get isHandOffToOtherToolSelected() {
    return this.selectedHandoverMode === HandOverModeEnum.othertool;
  }

  get handoverDropdownItems() {
    return [
      {
        value: HandOverModeEnum.zendesk,
        text: this.intl.t('onboarding.home.steps.route-conversation-to-human.handoff-to-zendesk'),
        icon: 'zendesk',
      },
      {
        value: HandOverModeEnum.othertool,
        text: this.intl.t(
          'onboarding.home.steps.route-conversation-to-human.handoff-to-another-tool',
        ),
        icon: 'webhook',
      },
    ];
  }

  @action
  updateHandoverMode(mode: HandOverModeEnum) {
    this.selectedHandoverMode = mode;
  }

  @action
  onZendeskEnabled() {
    this.args.continueCallback(); // Add Fin Reload here if required
    this.continueButtonEnabled = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::AiBotWizard::EwChatbotFinFallbackComponents::FallbackOptionHandOff': typeof FallbackOptionHandOff;
  }
}
