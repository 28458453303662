/* import __COLOCATED_TEMPLATE__ from './talk-to-card.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  title: string;
  description: string;
  image: any;
  buttonCta: string;
  intercomBotTarget: string;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class TalkToCard extends Component<Signature> {
  @service intercomEventService: $TSFixMe;

  @action
  triggerAnalytics() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'product_specialist_cta',
      context: 'ask_product_specialist',
      section: 'onboarding.getting-started',
      place: 'onboarding.getting-started',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GettingStarted::Common::TalkToCard': typeof TalkToCard;
  }
}
