/* import __COLOCATED_TEMPLATE__ from './toll-free-verification-form.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import {
  mainUseCases,
  mainUseCaseCategories,
  mainUseCaseCategoryNames,
  optInTypes,
  estimatedMonthlyVolumes,
} from 'embercom/models/data/sms/constants';
import { VERIFICATION_STATUS } from 'embercom/models/data/sms/constants';
import PhoneCountryCodes from 'embercom/lib/phone-codes';

const NUMBER_OF_CONTENT_TYPES_TO_DISPLAY = 2;
export default class TollFreeVerificationForm extends Component {
  @service appService;
  @service notificationsService;
  @service store;
  @service intl;
  @service router;
  @tracked openSectionId = 'company-information';
  @tracked showSectionErrors = {
    companyInformation: !this.args.tollFreeVerification.isNew,
    useCaseAndVolume: !this.args.tollFreeVerification.isNew,
    collectingPhoneNumbersAndConsent: !this.args.tollFreeVerification.isNew,
    privacyPolicy: !this.args.tollFreeVerification.isNew,
  };
  @tracked hasBlurred = {};
  @tracked showAddressLine2 = !!this.args.tollFreeVerification.businessContactAddress.addressLine2;

  mainUseCases = mainUseCases;

  get country() {
    return this.args.tollFreeVerification.businessContactAddress.country;
  }

  set country(country) {
    this.args.tollFreeVerification.businessContactAddress.country = country;
  }

  get companyInformationSectionIsValid() {
    if (!this.args.tollFreeVerification) {
      return false;
    } else {
      return (
        this.args.tollFreeVerification.validations.attrs.companyName.isValid &&
        this.args.tollFreeVerification.validations.attrs.companyWebsite.isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactAddress.addressLine1
          .isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactAddress.city.isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactAddress.country.isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactAddress.state.isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactAddress.zipCode.isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactFirstName.isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactLastName.isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactEmail.isValid &&
        this.args.tollFreeVerification.validations.attrs.businessContactPhone.isValid
      );
    }
  }
  get useCaseAndVolumeSectionIsValid() {
    if (!this.args.tollFreeVerification) {
      return false;
    } else {
      return (
        this.args.tollFreeVerification.validations.attrs.estimatedMontlyVolume.isValid &&
        this.args.tollFreeVerification.validations.attrs.mainUseCases.length.isValid &&
        this.args.tollFreeVerification.validations.attrs.smsUseCasesDescription.isValid &&
        this.args.tollFreeVerification.validations.attrs.productionMessageSample.isValid
      );
    }
  }

  get collectingPhoneNumbersAndConsentSectionIsValid() {
    if (!this.args.tollFreeVerification) {
      return false;
    } else {
      return (
        this.args.tollFreeVerification.validations.attrs.collectionMethod.isValid &&
        this.args.tollFreeVerification.validations.attrs.collectUsingIntercomConsent.isValid &&
        this.args.tollFreeVerification.validations.attrs.collectionOutsideOfIntercomDescription
          .isValid &&
        this.args.tollFreeVerification.validations.attrs.collectionOutsideOfIntercomAttachements
          .length.isValid &&
        this.args.tollFreeVerification.validations.attrs.optInType.isValid
      );
    }
  }

  get privacyPolicySectionIsValid() {
    if (!this.args.tollFreeVerification) {
      return false;
    } else {
      return this.args.tollFreeVerification.validations.attrs.privacyPolicyLink.isValid;
    }
  }

  get optionsForCountry() {
    return [
      {
        text: this.intl.t(PhoneCountryCodes['CA'].nameTranslationKey),
        value: 'CA',
      },
      {
        text: this.intl.t(PhoneCountryCodes['US'].nameTranslationKey),
        value: 'US',
      },
    ];
  }

  get optionsForEstimatedMonthlyVolume() {
    return Object.entries(estimatedMonthlyVolumes).map((entry) => {
      let [key, value] = entry;
      return {
        text: this.intl.t(
          `outbound.sms.onboarding.toll-free-verification-form.estimated-monthly-volume-optioins.${key}`,
        ),
        value,
      };
    });
  }

  get optionsForAllMainUseCaseTypes() {
    return Object.entries(mainUseCases).map((entry) => {
      let [key, value] = entry;
      return {
        text: this.intl.t(
          `outbound.sms.onboarding.toll-free-verification-form.main-use-case-types-options.${key}`,
          { htmlSafe: true },
        ),
        value,
      };
    });
  }

  get optionsForActiveMainUseCaseTypes() {
    return this.optionsForAllMainUseCaseTypes.filter((element) => {
      return [mainUseCases.marketingAndPromotional, mainUseCases.otherNonPromotional].includes(
        element.value,
      );
    });
  }

  get useCaseItems() {
    let items = [];
    Object.keys(mainUseCaseCategories).forEach((category) => {
      items.pushObject({
        text: this.intl.t(
          `outbound.sms.onboarding.toll-free-verification-form.main-use-cases.categories.${category}`,
        ),
        value: mainUseCaseCategories[category],
        isSelected: this.args.tollFreeVerification?.mainUseCases.includes(
          mainUseCaseCategories[category],
        ),
      });
    });
    return items;
  }

  get selectedItemsToDisplay() {
    return this.args.tollFreeVerification.mainUseCases
      ?.slice(0, NUMBER_OF_CONTENT_TYPES_TO_DISPLAY)
      .map((category) =>
        this.intl.t(
          `outbound.sms.onboarding.toll-free-verification-form.main-use-cases.categories.${mainUseCaseCategoryNames[category]}`,
        ),
      )
      .join(', ');
  }

  get countOtherItems() {
    let otherItems =
      this.args.tollFreeVerification.mainUseCases.length - NUMBER_OF_CONTENT_TYPES_TO_DISPLAY;
    return otherItems > 0 ? otherItems : 0;
  }

  get optInTypeItems() {
    let items = [];
    Object.keys(optInTypes).forEach((type) => {
      items.pushObject({
        text: this.intl.t(
          `outbound.sms.onboarding.toll-free-verification-form.collection-methods.opt-in-types.${type}`,
        ),
        value: optInTypes[type],
        isSelected: this.args.tollFreeVerification?.optInTypes === optInTypes[type],
      });
    });
    return items;
  }

  get stampParams() {
    let textMap = {
      [VERIFICATION_STATUS.DRAFT]: this.intl.t(
        'outbound.sms.onboarding.toll-free-verification-form.stamp-text.draft',
      ),
      [VERIFICATION_STATUS.PENDING]: this.intl.t(
        'outbound.sms.onboarding.toll-free-verification-form.stamp-text.in-review',
      ),
      [VERIFICATION_STATUS.SUBMITTED]: this.intl.t(
        'outbound.sms.onboarding.toll-free-verification-form.stamp-text.in-review',
      ),
      [VERIFICATION_STATUS.VERIFIED]: this.intl.t(
        'outbound.sms.onboarding.toll-free-verification-form.stamp-text.active',
      ),
      [VERIFICATION_STATUS.REJECTED]: this.intl.t(
        'outbound.sms.onboarding.toll-free-verification-form.stamp-text.rejected',
      ),
    };
    let colorMap = {
      [VERIFICATION_STATUS.DRAFT]: 'gray',
      [VERIFICATION_STATUS.PENDING]: 'sky',
      [VERIFICATION_STATUS.SUBMITTED]: 'sky',
      [VERIFICATION_STATUS.VERIFIED]: 'green',
      [VERIFICATION_STATUS.REJECTED]: 'red',
    };
    if (this.args.tollFreeVerification) {
      return {
        text: textMap[this.args.tollFreeVerification.status],
        color: colorMap[this.args.tollFreeVerification.status],
      };
    } else {
      return {
        text: textMap[VERIFICATION_STATUS.DRAFT],
        color: colorMap[VERIFICATION_STATUS.DRAFT],
      };
    }
  }

  constructor() {
    super(...arguments);

    if (!this.optionsForCountry.map((option) => option.value).includes(this.country)) {
      this.updateCountry('US');
    }
  }

  @action
  openSectionChange(sectionId) {
    this.openSectionId = sectionId;
  }

  @action
  onSectionDidClose(sectionId) {
    this.updateSectionShowErrorsStatus(sectionId);
  }

  @action
  updateCountry(country) {
    this.country = country;
    return this.saveTollFreeVerificationDraft.perform();
  }

  @action
  updateSectionShowErrorsStatus(sectionId) {
    let sectionStatus = { ...this.showSectionErrors };
    if (sectionId === 'company-information') {
      sectionStatus.companyInformation = true;
    } else if (sectionId === 'use-case-and-volume') {
      sectionStatus.useCaseAndVolume = true;
    } else if (sectionId === 'collecting-phone-numbers-and-consent') {
      sectionStatus.collectingPhoneNumbersAndConsent = true;
    } else if (sectionId === 'privacy-policy') {
      sectionStatus.privacyPolicy = true;
    }
    this.showSectionErrors = sectionStatus;
  }

  @action
  setEstimatedMonthlyVolume(id) {
    this.args.tollFreeVerification.estimatedMontlyVolume = id;
    this.saveTollFreeVerificationDraft.perform();
  }

  @action
  toggleUseCase(id) {
    this.args.tollFreeVerification.toggleUseCase(id);
    this.saveTollFreeVerificationDraft.perform();
  }

  @action
  setCollectionMethod(id) {
    this.args.tollFreeVerification.collectionMethod = id;
    this.saveTollFreeVerificationDraft.perform();
  }

  @action
  toggleCollectUsingIntercomConsent() {
    this.args.tollFreeVerification.toggleProperty('collectUsingIntercomConsent');
    this.saveTollFreeVerificationDraft.perform();
  }

  @action
  appendImageUrl(url) {
    this.args.tollFreeVerification.collectionOutsideOfIntercomAttachements.pushObject(url);
    this.saveTollFreeVerificationDraft.perform();
  }

  @action
  updateUseCases(selectedValues) {
    this.args.tollFreeVerification.mainUseCases = selectedValues;
    this.saveTollFreeVerificationDraft.perform();
  }

  @action
  setOptInType(id) {
    this.args.tollFreeVerification.optInType = id;
    this.saveTollFreeVerificationDraft.perform();
  }

  @action
  removeImageUrl(url) {}

  @task({ keepLatest: true })
  *saveTollFreeVerificationDraft() {
    if (this.args.tollFreeVerification.draft) {
      yield this.args.tollFreeVerification.save();
    }
  }

  @task({ drop: true })
  *submitTollFreeVerification() {
    let errorHandler = (e) => {
      this.notificationsService.notifyError(
        this.intl.t('outbound.sms.onboarding.toll-free-verification-form.sending-failed'),
      );
    };

    this.args.tollFreeVerification.draft = false;
    this.args.tollFreeVerification.submittedOn = moment().format('L');

    try {
      yield this.args.tollFreeVerification
        .save()
        .then(() => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('outbound.sms.onboarding.toll-free-verification-form.sending-succeed'),
          );
          this.router.transitionTo('apps.app.settings.channels.sms');
        })
        .catch(errorHandler);
    } catch (e) {
      errorHandler.call;
    }
  }

  get tollFreeVerificationFormHeaderWormholeElement() {
    return document.getElementById('toll-free-verification-form-header-wormhole');
  }
}
