/* import __COLOCATED_TEMPLATE__ from './track-your-data-attributes.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  INSTALL_METHODS as MESSENGER_INSTALL_METHODS,
  INSTALL_METHOD_ANALYTICS_NAMES,
} from 'embercom/lib/messenger/installation-constants';
import { captureException } from 'embercom/lib/sentry';
import ajax from 'embercom/lib/ajax';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';

export const INSTALL_TYPES = [
  {
    text: 'with-code',
    value: 'code',
  },
  {
    text: 'with-app',
    value: 'third-party',
  },
];

export const INSTALL_METHODS = {
  code: [
    MESSENGER_INSTALL_METHODS.JAVASCRIPT,
    MESSENGER_INSTALL_METHODS.SINGLE_PAGE,
    MESSENGER_INSTALL_METHODS.RAILS,
    MESSENGER_INSTALL_METHODS.IOS,
    MESSENGER_INSTALL_METHODS.ANDROID,
    MESSENGER_INSTALL_METHODS.CORDOVA,
  ],
  'third-party': [
    MESSENGER_INSTALL_METHODS.SEGMENT,
    MESSENGER_INSTALL_METHODS.WORDPRESS,
    MESSENGER_INSTALL_METHODS.SHOPIFY,
  ],
};

const ATTRIBUTE_TYPES_TO_HIDE = ['role', 'anonymous'];

export default class TrackYourDataAttributesComponent extends Component {
  @service intl;
  @service router;
  @service appService;
  @service attributeService;
  @service intercomEventService;

  @tracked selectedInstallType = 'code';
  @tracked selectedInstallMethod = null;
  @tracked verified = false;
  @tracked errorMessage = null;

  constructor() {
    super(...arguments);
    this.selectedInstallMethod = this.defaultSelectedInstallMethod;
  }

  get app() {
    return this.appService.app;
  }

  get noAttributes() {
    return this.attributes.length === 0;
  }

  get noLoggedInInstall() {
    return !this.loggedInInstallTimestamp;
  }

  get needsDependentStep() {
    return this.noAttributes || this.noLoggedInInstall;
  }

  get installTypes() {
    return INSTALL_TYPES;
  }

  get attributes() {
    return this.attributeService.allUserAttributes
      .filter(
        (setting) =>
          !ATTRIBUTE_TYPES_TO_HIDE.includes(setting.attribute.type) &&
          setting.attribute.tracked === false,
      )
      .map((setting) => {
        return { name: setting.attribute.name };
      });
  }

  get loggedInInstallTimestamp() {
    return this.app.first_identified_request_at || this.app.verifiedLoggedInInstallAt;
  }

  get dependentStepIdentifier() {
    return this.noLoggedInInstall ? 'set_up_messenger_logged_in' : 'define_the_data_you_need';
  }

  get dependentStepTitle() {
    return this.noLoggedInInstall ? 'messenger-installed' : 'data-attributes';
  }

  get installMethodComponentName() {
    return `onboarding/home/steps/setup-custom-data-attributes/install-methods/${this.selectedInstallType}`;
  }

  get installMethodOptions() {
    return INSTALL_METHODS[this.selectedInstallType];
  }

  get defaultSelectedInstallMethod() {
    return INSTALL_METHODS[this.selectedInstallType][0].value;
  }

  get installationMethodAnalyticsMetadata() {
    return {
      ...this.args.step.analyticsMetadata,
      install_language: this.showCodeInstructions
        ? INSTALL_METHOD_ANALYTICS_NAMES[this.selectedInstallMethod]
        : undefined,
      install_with: this.selectedInstallType,
    };
  }

  @action
  showCode() {
    this.trackClickedAnalyticsEvent('show_code_button', { context: this.useCase });
    this.showCodeInstructions = true;
  }

  @action
  onInstallTypeChange(installMethod) {
    this.selectedInstallType = installMethod;
    this.selectedInstallMethod = this.defaultSelectedInstallMethod;
  }

  @action
  complete() {
    this.args.completionCallback?.();
  }

  @restartableTask
  *verify() {
    this.errorMessage = null;
    this.verified = false;

    try {
      let result = yield ajax({
        url: '/ember/onboarding/home/steps/setup_custom_data_attributes/verify',
        type: 'GET',
        data: {
          app_id: this.app.id,
        },
      });

      this.verified = result.verified;

      if (!this.verified) {
        this.errorMessage = this.intl.t(
          `onboarding.home.steps.track-your-data-attributes.verification-error-text`,
        );
      }
    } catch (e) {
      this.errorMessage = this.intl.t(
        `onboarding.home.steps.track-your-data-attributes.verification-request-failed-text`,
      );
      this.verified = false;
      captureException(e);
    }

    this.trackInstallationMethodAnalyticsEvent('check_tracking');
  }

  @action
  trackInstallationMethodAnalyticsEvent(object) {
    this.trackClickedAnalyticsEvent(object, this.installationMethodAnalyticsMetadata);
  }

  trackClickedAnalyticsEvent(object, params = {}) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      ...params,
      action: 'clicked',
      object,
    });
  }
}
