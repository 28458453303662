/* import __COLOCATED_TEMPLATE__ from './discovery-banner.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

const PreviewToolbarCircle = templateOnlyComponent();
export default PreviewToolbarCircle;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DiscoveryBanner': typeof PreviewToolbarCircle;
    'new-settings/channels/email/discovery-banner': typeof PreviewToolbarCircle;
  }
}
