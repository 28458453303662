/* import __COLOCATED_TEMPLATE__ from './forwarding.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { isValidEmail as isValidEmailAddress } from 'embercom/lib/email';
import storage from 'embercom/vendor/intercom/storage';
import { dropTask } from 'ember-concurrency-decorators';
import type MailForwardingValidationService from 'embercom/services/mail-forwarding-validation-service';

const MANUALLY_ADDED = 0;
const LOCALSTORAGE_EMAIL_KEY = 'forward-emails-component-email';

interface Args {}

export default class Forwarding extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: Router;
  @service declare realTimeEventService: $TSFixMe;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare paywallService: any;
  @service declare purchaseAnalyticsService: any;
  @service declare mailForwardingValidationService: MailForwardingValidationService;

  @tracked addCompanyEmailAddressFormExpanded = false;
  @tracked email = '';
  @tracked emailSet = false;
  @tracked newCompanyEmailAddress = '';
  @tracked openSectionId = 'test';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.restoreEmailFromStorage();
  }

  @action
  handleMount() {
    this.mailForwardingValidationService.unset();
  }

  get app() {
    return this.appService.app;
  }

  get companyEmailAddresses() {
    return this.app.companyEmailAddresses;
  }

  get hasCompanyEmailAddresses() {
    return this.companyEmailAddresses.length > 0;
  }

  get isValidEmail() {
    return isValidEmailAddress(this.email);
  }

  get verification() {
    return this.mailForwardingValidationService.verification;
  }

  get hasForwardingEmail() {
    return this.email !== '';
  }

  get inboxAddress() {
    return this.app.inbox_email;
  }

  get analyticsMetadata() {
    return {
      place: 'settings',
      owner: 'team-channels',
      object: 'email_forwarding_steps',
    };
  }

  duplicateEmailAddress(emailAddress: string): boolean {
    let companyEmailAddresses = this.companyEmailAddresses;
    let duplicateFound = false;
    companyEmailAddresses.forEach(function (item: $TSFixMe) {
      if (item.get('email_address') === emailAddress) {
        duplicateFound = true;
      }
    });
    return duplicateFound;
  }

  @action
  expandAddCompanyEmailAddressForm() {
    this.addCompanyEmailAddressFormExpanded = true;
    this._trackAnalyticsEvent({
      action: 'expand',
      object: 'accordion-section_add_company_email_address',
      context: 'company_address_to_ignore_in_group_conversation',
    });
  }

  @action
  collapseAddCompanyEmailAddressForm() {
    this.addCompanyEmailAddressFormExpanded = false;
    this._trackAnalyticsEvent({
      action: 'collapse',
      object: 'accordion-section_add_company_email_address',
      context: 'company_address_to_ignore_in_group_conversation',
    });
  }

  @action
  setEmail() {
    if (this.isValidEmail) {
      storage.set(LOCALSTORAGE_EMAIL_KEY, this.email);
      this.emailSet = true;
      this._trackAnalyticsEvent({
        action: 'clicked',
        object: 'set_email',
        context: 'setup_email_forwarding',
      });
    }
  }

  @action
  unsetEmail() {
    storage.remove(LOCALSTORAGE_EMAIL_KEY);
    this.mailForwardingValidationService.unsetVerification();
    this.emailSet = false;
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'unset_email',
      context: 'setup_email_forwarding',
    });
  }

  @action trackOpenSection(sectionName: string) {
    this.trackAnalyticsEvent('open', `accordion-section_${sectionName}`);
  }

  @action trackCloseSection(sectionName: string) {
    this.trackAnalyticsEvent('close', `accordion-section_${sectionName}`);
  }

  trackAnalyticsEvent(action: string, object: string) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      context: 'email-forwarding-experiment',
      place: 'settings_channels_email',
      object,
    });
  }

  restoreEmailFromStorage() {
    if (this.email !== '') {
      return;
    }
    let email = storage.get(LOCALSTORAGE_EMAIL_KEY);
    if (!email) {
      return;
    }
    this.email = email;
    this.emailSet = true;
  }

  _trackAnalyticsEvent(options: { action?: string; object?: string; context?: string }) {
    this.intercomEventService.trackAnalyticsEvent({
      email: this.email,
      ...options,
      place: 'settings_channels_email',
    });
  }

  @dropTask
  *addNewCompanyEmailAddress(emailAddress: string) {
    if (!isValidEmailAddress(emailAddress)) {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.settings.email-forwarding.enter-valid-email'),
      );
      this._trackAnalyticsEvent({
        action: 'invalid',
        object: 'add_company_email_address',
        context: 'company_address_to_ignore_in_group_conversation',
      });
    } else if (this.duplicateEmailAddress(emailAddress)) {
      this.notificationsService.notifyWarning(
        this.intl.t('apps.app.settings.email-forwarding.email-already-added'),
      );
      this._trackAnalyticsEvent({
        action: 'duplicate',
        object: 'add_company_email_address',
        context: 'company_address_to_ignore_in_group_conversation',
      });
    } else {
      let companyEmailAddress = this.store.createRecord('company-email-address', {
        email_address: emailAddress,
        source: MANUALLY_ADDED,
      });
      this.collapseAddCompanyEmailAddressForm();
      try {
        yield companyEmailAddress.save();
        this.newCompanyEmailAddress = '';
        this.notificationsService.notifyConfirmation(
          this.intl.t('apps.app.settings.email-forwarding.registered'),
        );
        this._trackAnalyticsEvent({
          action: 'completed',
          object: 'add_company_email_address',
          context: 'company_address_to_ignore_in_group_conversation',
        });
      } catch (e) {
        this.notificationsService.notifyError(
          this.intl.t('apps.app.settings.email-forwarding.email-not-saved'),
        );
        this.expandAddCompanyEmailAddressForm();
        this.store.deleteRecord(companyEmailAddress);
        this._trackAnalyticsEvent({
          action: 'failed',
          object: 'add_company_email_address',
          context: 'company_address_to_ignore_in_group_conversation',
        });
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Forwarding': typeof Forwarding;
  }
}
