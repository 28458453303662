/* import __COLOCATED_TEMPLATE__ from './wizard-close-button.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type StepData } from '../wizard';
import { inject as service } from '@ember/service';
interface Args {
  step: any;
  stepData: StepData;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class WizardCloseButton extends Component<Args> {
  @service declare intercomEventService: any;

  @action
  closeWizard(): any {
    this._trackAnalyticsEvent();
    this.args.stepData.onSkipCallback();
  }

  _trackAnalyticsEvent() {
    switch (this.args.step.id) {
      case 'ew_chatbot_meet_fin':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'skipped',
          object: 'meet_fin_skip_button',
          context: 'onboarding_guide_library.wizard.ai_bot.meet_fin_step',
          place: 'ai_bot_wizard',
          section: 'ai_bot_meet_fin_step',
        });
        break;
      case 'ew_chatbot_try_fin_with_your_content':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'skipped',
          object: 'preview_fin_with_your_content_skip_button',
          context: 'onboarding_guide_library.wizard.ai_bot.preview_fin_with_your_content',
          place: 'ai_bot_wizard',
          section: 'ai_bot_preview_fin_with_your_content',
        });
        break;
      case 'ew_chatbot_fin_fallback':
        this.intercomEventService.trackAnalyticsEvent({
          action: 'skipped',
          object: `fin_fallback_skip_button`,
          place: 'ai_bot_wizard',
          context: 'onboarding_guide_library.wizard.ai_bot.fin_fallback',
          section: 'ai_bot_fin_fallback',
        });
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Common::WizardCloseButton': typeof WizardCloseButton;
  }
}
