/* import __COLOCATED_TEMPLATE__ from './channel-targeting-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  item: {
    text: string;
    isDisabled: boolean;
    icon: $TSFixMe;
    componentAttrs: {
      channel: $TSFixMe;
      isIntercomChannel: boolean;
      openAppStore: (channel: string, appId: string) => void;
    };
  };
}

export default class ChannelTargetingDropdownItem extends Component<Args> {
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;

  @action
  openInstallLink(smsPaywall: $TSFixMe, whatsappPaywall: $TSFixMe) {
    let channel = this.args.item.componentAttrs.channel;

    if (this.args.item.componentAttrs.isIntercomChannel) {
      this._openMessengerInstallation();
    } else if (channel.value === 'whatsapp' && whatsappPaywall.isActive) {
      whatsappPaywall.openUpgradeModal();
    } else if (channel.value === 'sms' && smsPaywall.isActive) {
      smsPaywall.openUpgradeModal();
    } else if (channel.requiresAppStoreInstallation && !channel.isInstalled) {
      this.args.item.componentAttrs.openAppStore(channel.value, channel.appId);
    } else if (!channel.isSetup && channel.value === 'sms') {
      this._openSmsSettings();
    } else if (!channel.isSetup) {
      this.args.item.componentAttrs.openAppStore(channel.value, channel.appId);
    }
  }

  _openMessengerInstallation() {
    let url = this.router.urlFor('apps.app.settings.channels.messenger.install');
    safeWindowOpen(url, '_blank');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'click',
      object: 'channel_install_button',
      place: 'channel_targeting',
      channel: 'messenger',
    });
  }

  _openSmsSettings() {
    let url = this.router.urlFor('apps.app.settings.channels.sms');
    safeWindowOpen(url, '_blank');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'click',
      object: 'channel_install_button',
      place: 'channel_targeting',
      channel: 'sms',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::ChannelTargetingDropdownItem': typeof ChannelTargetingDropdownItem;
  }
}
