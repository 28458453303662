/* import __COLOCATED_TEMPLATE__ from './trigger-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import {
  TRIGGER_CONTEXT_TYPES,
  WORKFLOWS_SETUP_TRIGGER_TYPES,
} from 'embercom/lib/operator/custom-bots/constants';
import type CustomBotConfig from 'embercom/objects/operator/configuration/custom-bot';

interface WorkflowsSetupTriggerConfig extends CustomBotConfig {
  workflowsSetupTriggerType: string[];
  onSelect: () => void;
}

interface Args {
  triggers: ((CustomBotConfig & { onSelect: () => void }) | WorkflowsSetupTriggerConfig)[];
  shouldShowPaywallBadges: boolean;
  onClose: () => void;
  createWorkflow: () => void;
  transitionBack: () => void;
  recordAnalyticsEvent: (actionLabel: string) => void;
  showBackButton?: boolean;
}

export default class TriggerSelector extends Component<Args> {
  @service declare intl: IntlService;

  @tracked selectedCategory: string = this.workflowsSetupTriggerContextGroups[0].translationKey;

  get triggerContextGroups() {
    return TRIGGER_CONTEXT_TYPES.map((contextType) => ({
      contextType,
      triggers: this.args.triggers.filter((trigger) => trigger.triggerContextType === contextType),
    })).filter((group) => group.triggers.length > 0);
  }

  get workflowsSetupTriggerContextGroups() {
    return WORKFLOWS_SETUP_TRIGGER_TYPES.map((contextType: string) => ({
      translationKey: contextType,
      contextType,
      triggers: this.args.triggers.filter((trigger: WorkflowsSetupTriggerConfig) =>
        trigger.workflowsSetupTriggerType.includes(contextType),
      ),
    })).filter((group: { triggers: $TSFixMe[] }) => group.triggers.length > 0);
  }

  get selectedCategoryContext() {
    return this.workflowsSetupTriggerContextGroups.find(
      (group: { contextType: string }) => group.contextType === this.selectedCategory,
    );
  }

  @action
  setSelectedCategory(category: string) {
    this.selectedCategory = category;
    this.args.recordAnalyticsEvent(`${category}-selected`);
  }

  @action
  getTriggerTitleKey(trigger: CustomBotConfig): string {
    return trigger.title;
  }

  @action
  getTriggerDescKey(trigger: CustomBotConfig): string {
    return trigger.description;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::TriggerSelector': typeof TriggerSelector;
  }
}
