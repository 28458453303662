/* import __COLOCATED_TEMPLATE__ from './answer.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  onClick: () => void;
  selected: boolean;
  answer: any;
  questionIdentifier: string;
  setFreeTextValue: (answer: any, value: string) => void;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class Answer extends Component<Signature> {
  @service declare intl: IntlService;

  get buttonText() {
    return this.intl.t(this.translationKey('label'));
  }

  get textboxPlaceholder() {
    return this.intl.t(this.translationKey('textbox_placeholder'));
  }

  get tooltipText() {
    return this.intl.t(this.translationKey('tooltip'), {
      htmlSafe: true,
    });
  }

  translationKey(key: string) {
    return `onboarding.guide-library.profiling-survey.questions.${this.args.questionIdentifier}.answers.${this.args.answer.identifier}.${key}`;
  }

  @action
  handleFreeTextInput(event: any) {
    this.args.setFreeTextValue(this.args.answer, event.target.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::ProfilingSurvey::Answer': typeof Answer;
  }
}
