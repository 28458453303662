/* import __COLOCATED_TEMPLATE__ from './tour-your-inbox.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import Conversation from 'embercom/models/conversation';
import { taskFor } from 'ember-concurrency-ts';
import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';

export default class TourYourInboxComponent extends Component {
  @service router;
  @service appService;
  @service onboardingHomeExternalStepService;
  @service intercomEventService;
  @service intl;

  @tracked hasOpenConversations = true;

  constructor() {
    super(...arguments);
    this.dependentStepIdentifier = 'customer_support_channels';
    this.openStepCallback = this.args.openStepCallback || (() => {});
    taskFor(this.checkOpenConversationsExists).perform();

    registerDestructor(this, () => {
      taskFor(this.checkOpenConversationsExists).cancelAll();
    });
  }

  get app() {
    return this.appService.app;
  }

  get showDependentStepWarning() {
    return !this.hasOpenConversations;
  }

  @action
  trackClickedAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.analyticsMetadata,
      action: 'clicked',
      object: 'tour_your_inbox',
      section: 'inline_step',
    });
  }

  @action
  async transitionToTour() {
    this.trackClickedAnalyticsEvent();

    this.onboardingHomeExternalStepService.startAfterTransition(this.args.step.identifier, {
      source: 'home/tour-your-inbox',
      method: 'startAfterTransition',
    });
    this.onboardingHomeExternalStepService.completeExternalStep();
    this.router.transitionTo('apps.app.inbox.inbox', {
      queryParams: {
        external_step: 'tour_your_inbox',
      },
    });
  }

  @action
  openStep(stepIdentifier) {
    this.openStepCallback(stepIdentifier);
  }

  @dropTask
  *checkOpenConversationsExists() {
    let response = yield Conversation.newSearch({
      app_id: this.appService.app.id,
      scope: 'all',
      state: 0,
    });
    this.hasOpenConversations = response.conversations.some(
      (conversation) => conversation.state === 0,
    );
  }
}
