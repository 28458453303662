/* import __COLOCATED_TEMPLATE__ from './install-method.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { notImplemented } from '@intercom/pulse/lib/computed-properties';
import { computed } from '@ember/object';
import { underscore } from '@ember/string';
import { mapBy, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

export default Component.extend({
  attributeBindings: ['data-test-install-method'],
  'data-test-install-method': readOnly('selectedInstallMethod'),
  intercomEventService: service(),
  intl: service(),

  verify: null,
  completionCallback: null,
  disableVerifyButton: false,
  onCopyCode: null,

  attributes: notImplemented('Must pass attributes to install-method'),

  attributeNames: mapBy('attributes', 'name'),

  formattedAttributes: computed('attributeNames', function () {
    return this.attributeNames.map(underscore).map((name) => name.replace(/\W*/g, ''));
  }),

  actions: {
    openMessenger() {
      showNewMessageInIntercomWidget(
        this.intl.t(
          'onboarding.home.steps.setup-custom-data-attributes.install-method.need-some-help',
        ),
      );
    },
  },
});
