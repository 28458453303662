/* import __COLOCATED_TEMPLATE__ from './step.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type PaletteColor } from '@intercom/pulse/template-registry';
import type IntlService from 'ember-intl/services/intl';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { type SafeString } from 'handlebars';

export type CtaType = 'primary' | 'secondary' | 'secondary-destructive';
export const HELP_LINKS = {
  cloudflare:
    'https://www.intercom.com/help/en/articles/9744849-connect-your-email-support-channel#h_f271f90e57',
  squarespace:
    'https://www.intercom.com/help/en/articles/9744849-connect-your-email-support-channel#h_e25628f7bd',
  godaddy:
    'https://www.intercom.com/help/en/articles/9744849-connect-your-email-support-channel#h_b6f515bd90',
  namecheap:
    'https://www.intercom.com/help/en/articles/9744849-connect-your-email-support-channel#h_39a02d0cd6',
  generic:
    'https://www.intercom.com/help/en/articles/9744849-connect-your-email-support-channel#h_b761c867b8',
} as { [key: string]: string };

interface Signature {
  Args: {
    name: string;
    title: string | SafeString;
    description: string | SafeString;
    stepNumber?: number;
    stepCompleted: boolean;
    stepIcon?: InterfaceIconName;
    stepIconColor?: PaletteColor;
    ctaLabel?: string;
    ctaType?: CtaType;
    ctaLink: string;
    ctaOnClick?: () => void;
    ctaDisabled?: boolean;
    inlineLinkText?: string;
    inlineLinkOnClick?: () => void;
    disabledButtonTooltipText?: string;
  };
  Element: HTMLElement;
  Blocks: any;
}

export default class StepComponent extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: any;

  get grouplist() {
    return [
      {
        items: [
          {
            value: 'cloudflare',
            text: this.intl.t(
              'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.drowdown-items.cloudflare',
            ),
            icon: 'article',
          },
          {
            value: 'squarespace',
            text: this.intl.t(
              'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.drowdown-items.squarespace',
            ),
            icon: 'article',
          },
          {
            value: 'godaddy',
            text: this.intl.t(
              'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.drowdown-items.godaddy',
            ),
            icon: 'article',
          },
          {
            value: 'namecheap',
            text: this.intl.t(
              'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.drowdown-items.namecheap',
            ),
            icon: 'article',
          },
          {
            value: 'generic',
            text: this.intl.t(
              'new-settings.channels.email.connect-email.email-setup.setup-domain-manually-step.find-your-domain-records.drowdown-items.generic',
            ),
            icon: 'article',
          },
        ],
      },
    ];
  }

  @action
  onSelectItem(provider: string) {
    let url = HELP_LINKS[provider];
    safeWindowOpen(url);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `select_provider_${provider}`,
      place: 'setup-domain',
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::Step': typeof StepComponent;
    'new-settings/channels/email/domains-and-addresses/step': typeof StepComponent;
  }
}
