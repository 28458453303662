/* import __COLOCATED_TEMPLATE__ from './add-email-address.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* eslint-disable @intercom/intercom/no-bare-strings */

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { isValidEmail as isValidEmailAddress } from 'embercom/lib/email';
import type Brand from 'embercom/models/brand';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import { type DomainEmailAddress } from 'embercom/services/domain-service';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type BrandService from 'embercom/services/brand-service';
import type IntlService from 'embercom/services/intl';

interface Args {
  changeStep: (step: string | null, origin: string) => void;
  emailAddress: DomainEmailAddress | undefined;
  setEmail: (email: string | null) => Promise<void>;
  brands: Brand[];
}

interface Signature {
  Args: Args;
}

export default class AddEmailAddressComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare brandService: BrandService;
  @service declare intercomEventService: any;

  @tracked newEmail = '';
  @tracked selectedBrandId = '0';
  @tracked allowForwardingEnabled = true;

  place = 'add-email-address';

  constructor(owner: any, args: Args) {
    super(owner, args);
    if (args.emailAddress) {
      this.newEmail = args.emailAddress.email;
      this.allowForwardingEnabled = !args.emailAddress.outboundOnly;
    }
  }

  get trimmedEmail() {
    return this.newEmail.trim();
  }

  get canSaveEmail() {
    return this.isValidEmail && !this.isPersonalEmail;
  }

  get isValidEmail() {
    return isValidEmailAddress(this.trimmedEmail);
  }

  get isPersonalEmail() {
    let personalDomains = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'hotmail.com',
      'aol.com',
      'icloud.com',
      'protonmail.com',
      'mail.com',
      'zoho.com',
      'yandex.com',
      'example.com',
    ];

    let emailDomain = this.trimmedEmail.split('@')[1]?.toLowerCase();
    return emailDomain && personalDomains.includes(emailDomain);
  }

  get saveTooltipText() {
    if (!this.canSaveEmail) {
      if (!this.isValidEmail) {
        return this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.add-address-step.invalid-email-error',
        );
      }

      if (this.isPersonalEmail) {
        return this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.add-address-step.personal-email-error',
        );
      }
    }
    return '';
  }

  private buildSenderEmailAddresses(): CustomEmailAddress[] {
    let senderEmailAddresses: CustomEmailAddress[] = [];

    senderEmailAddresses.push(
      this.store.createRecord('custom-email-address', {
        email: this.trimmedEmail,
        name: this.selectedBrandName,
        status: 'Unverified email',
      }),
    );
    return senderEmailAddresses;
  }

  get selectedBrandName() {
    let brand = this.args.brands.find((brand) => brand.id === this.selectedBrandId);
    return brand?.name ?? this.appService.app.name;
  }

  get showBrands() {
    return this.brandService.isAppOnRightMultibrandPlan;
  }

  private pruneAddresses(settings: SenderEmailAddressSettings) {
    // seems to be a bug in our version of ember-data where we end up with both the
    // saved & unsaved records here, need to look at whether we can fix this with a local ID?
    // https://github.com/emberjs/data/issues/7354
    let unsaved = settings.senderEmailAddresses.filter((x) => x.isNew);
    settings.senderEmailAddresses.removeObjects(unsaved);
  }

  @action
  setAllowForwarding() {
    let newValue = !this.allowForwardingEnabled;
    this.allowForwardingEnabled = newValue;
    this.trackEvent('clicked', `toggle_forwarding_${newValue ? 'on' : 'off'}`);
  }

  @action
  saveAddress() {
    this.trackEvent('clicked', 'add_email_address_button');
    taskFor(this.createTask).perform();
  }

  @action
  onBrandSelect(brandId: string) {
    this.selectedBrandId = brandId;
  }

  @dropTask
  *createTask(): TaskGenerator<void> {
    try {
      let brandId = this.selectedBrandId === '0' ? null : this.selectedBrandId;
      let outboundOnly = !this.allowForwardingEnabled;
      let newSettings = this.store.createRecord('sender-email-address-settings', {
        email: this.trimmedEmail,
        senderEmailAddresses: this.buildSenderEmailAddresses(),
        emailSetup: 'overview',
        allowForwarding: String(this.allowForwardingEnabled),
        brandId,
        outboundOnly,
        sendVerificationEmail: outboundOnly,
      });

      yield newSettings.save();
      this.pruneAddresses(newSettings);

      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.add-address-step.success-message',
        ),
      );
      yield this.args.setEmail(this.trimmedEmail);
      this.args.changeStep('overview', this.place);
      this.trackEvent('success', 'add_email_address');
    } catch (error) {
      let errorStatus = error.jqXHR?.status;
      let notificationMessage: string;

      if (errorStatus === 422) {
        notificationMessage = error.jqXHR?.responseJSON?.errors[0]?.message;

        // If email already exists, just redirect to overview without showing error
        // The error code is not returned here so I'm checking the message. https://github.com/intercom/intercom/blob/253a8a1a2cc0c848abb7dc7cc0f2f6bac1fd707f/app/controllers/api/ember/embercom_controller.rb#L21-L29
        // This will fail if the message changes.
        if (notificationMessage?.includes('has already been added')) {
          this.notificationsService.notifyConfirmation(
            this.intl.t(
              'new-settings.channels.email.connect-email.email-setup.add-address-step.duplicate-email-success-message',
            ),
          );
          yield this.args.setEmail(this.trimmedEmail);
          this.trackEvent('success', 'email_already_exists');
          this.args.changeStep('overview', this.place);
          return;
        }
      } else {
        notificationMessage = this.intl.t(
          'new-settings.channels.email.connect-email.email-setup.add-address-step.error-message',
        );
      }
      this.notificationsService.notifyResponseError(error, {
        default: notificationMessage,
      });
      this.trackEvent('failed', 'add_email_address');
      return;
    }
  }

  @action
  trackEvent(action: string, object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: 'add-email-address-step',
      context: 'email-setup-sidebar',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::DomainsAndAddresses::AddEmailAddress': typeof AddEmailAddressComponent;
  }
}
