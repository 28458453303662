/* import __COLOCATED_TEMPLATE__ from './choose-target-go-live.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { copy } from 'ember-copy';
import {
  INSTALL_TYPE,
  AUDIENCE,
  LOGGED_IN_CODE_DROPDOWN_ITEMS,
  THIRD_PARTY_DROPDOWN_ITEMS,
  EXTRA_USER_ATTRIBUTES,
} from 'embercom/lib/messenger/installation-constants';
import { FIN_GENERAL_RELEASE } from 'embercom/lib/home/guides';

export default class ChooseTargetGoLive extends Component {
  @service appService;
  @service store;
  @service attributeService;
  @service messengerSettingsTargetUserProvider;
  @service onboardingHomeExternalStepService;
  @service intl;
  @service intercomEventService;
  @service onboardingHomeService;
  @service intercomConfirmService;
  @service realTimeEventService;

  @tracked selectedAudience = 'to-everyone';
  @tracked selectedInstallMethod = null;
  @tracked isSetLiveButtonVisible = false;

  @tracked messengerSettings = null;

  numberOfPredicates = 0;
  lastPredicateEditorAction = '';
  customAnalyticsMetadata = {
    place: 'onboarding_home',
    object: 'tech_setup_invite.set_up_messenger_primary',
    context: 'choose-target-go-live',
  };

  constructor() {
    super(...arguments);
    this.loadMessengerSettings.perform();

    this.realTimeEventService.on('MessengerFirstPing', this, 'handleMessengerFirstPingEvent');
  }

  get messengerVisibilitySettings() {
    return this.messengerSettings.get('visibility');
  }

  get messengerSettings() {
    return this.args.step.modelData.messengerSettings;
  }

  get isFinGeneralReleaseGuide() {
    return this.onboardingHomeService.guide.identifier === FIN_GENERAL_RELEASE;
  }

  get isUserType() {
    return this.messengerSettingsTargetUserProvider.isUserType;
  }

  get app() {
    return this.appService.app;
  }

  get baseAnalyticsMetadata() {
    return this.args.step.analyticsMetadata;
  }

  get selectedCodeInstallType() {
    return this.selectedInstallMethod === INSTALL_TYPE.CODE;
  }

  get dropdownItems() {
    if (this.selectedInstallMethod === INSTALL_TYPE.THIRD_PARTY) {
      return THIRD_PARTY_DROPDOWN_ITEMS;
    }
    return LOGGED_IN_CODE_DROPDOWN_ITEMS;
  }

  get loggedInAudience() {
    return AUDIENCE.LOGGED_IN;
  }

  get launcherPredicates() {
    return this.messengerVisibilitySettings.launcherPredicates;
  }

  get attributeGroupList() {
    return this.attributeGroupListForVisitors;
  }

  get isMessengerLive() {
    return this.app.first_identified_request_at || this.app.verifiedLoggedInInstallAt;
  }

  get attributeGroupListForVisitors() {
    return [
      {
        heading: this.intl.t(
          'composer.if-else-statement-block-editor.predicates-editor.person-data',
        ),
        attributes: this.attributeService.messengerConditionalDisplayAttributes.filter(
          (attribute) => {
            if (EXTRA_USER_ATTRIBUTES.includes(attribute.name)) {
              return true;
            }

            return attribute.isUser && attribute.visitor_triggered;
          },
        ),
      },
    ];
  }

  @dropTask
  *loadMessengerSettings() {
    this.messengerSettings = yield this.store.findRecord('messenger-settings/all', this.app.id);
    if (this.launcherPredicates.length > 0) {
      this.selectedAudience = 'to-target-audience';
      this.numberOfPredicates = this.launcherPredicates.length;
    }
    this.messengerSettingsTargetUserProvider.settings = this.messengerSettings;
  }

  async willDestroy() {
    super.willDestroy(...arguments);
    this.realTimeEventService.off('MessengerFirstPing', this, 'handleMessengerFirstPingEvent');
    if (this.isSetLiveButtonVisible) {
      try {
        let isConfirmed = await this.intercomConfirmService.confirm({
          title: this.intl.t(
            'onboarding.home.steps.choose-target-audience-go-live.confirmation-modal.title-target-audience',
          ),
          body: this.intl.t(
            'onboarding.home.steps.choose-target-audience-go-live.confirmation-modal.desc-target-audience',
          ),
          confirmButtonText: this.intl.t(
            'onboarding.home.steps.choose-target-audience-go-live.save-and-live',
          ),
        });
        if (!isConfirmed) {
          return;
        }
        this.saveSettings.perform();
      } catch (error) {
        this.args.step.modelData.messengerSettings = this.store.findRecord(
          'messenger-settings/all',
          this.app.id,
        );
      }
    }
  }

  @action
  handleMessengerFirstPingEvent() {
    this.markStepAsCompleted();
  }

  @action
  setInstallMethod(method) {
    this.selectedInstallMethod = method;
  }

  @action
  onPredicatesEditorClosed() {
    let allowedPredicateActions = ['added', 'edited'];
    if (allowedPredicateActions.includes(this.lastPredicateEditorAction)) {
      let emptyPredicates = [];
      if (this.launcherPredicates) {
        emptyPredicates = this.launcherPredicates.filter((predicate) => isEmpty(predicate.value));
      }
      if (isEmpty(emptyPredicates)) {
        if (this.args.step.completed) {
          this.setLiveButtonVisibility(true);
        } else {
          this.saveSettings.perform();
        }
      }
    }
  }

  @action
  markStepAsCompleted() {
    this.onboardingHomeExternalStepService.completeExternalStep();
    this.args.completionCallback();
  }

  _setPredicatesInStore(predicates) {
    let newPredicates = copy(predicates);
    this.messengerSettings.visibility.launcherPredicatesForAnonymous.clear();
    this.messengerSettings.visibility.launcherPredicatesForAnonymous.pushObjects(newPredicates);
    this.messengerSettings.visibility.launcherPredicatesForUsers.clear();
    this.messengerSettings.visibility.launcherPredicatesForUsers.pushObjects(newPredicates);
  }

  @action
  updatePredicates(predicates, meta) {
    this._setPredicatesInStore(predicates);
    this.lastPredicateEditorAction = meta.action;
    if (meta.action === 'deleted') {
      this.setLiveButtonVisibility(true);
    }
  }

  @action
  setLiveButtonVisibility(isVisible) {
    this.isSetLiveButtonVisible = isVisible;
  }

  @action
  async toggleTargetSelection(selectedTarget) {
    if (selectedTarget === 'to-everyone') {
      if (this.args.step.completed) {
        this.setLiveButtonVisibility(true);
      } else {
        try {
          let confirmOptions = {
            title: this.intl.t(
              'onboarding.home.steps.choose-target-audience-go-live.confirmation-modal.title',
            ),
            body: this.intl.t(
              'onboarding.home.steps.choose-target-audience-go-live.confirmation-modal.desc',
            ),
            confirmButtonText: this.intl.t(
              'onboarding.home.steps.choose-target-audience-go-live.confirmation-modal.confirm-button',
            ),
          };
          if (this.isMessengerLive) {
            let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
            if (!isConfirmed) {
              this.selectedAudience = 'to-target-audience';
              return;
            }
          }
          this.messengerSettings.visibility.launcherPredicatesForAnonymous.clear();
          this.saveSettings.perform();
          this.selectedAudience = selectedTarget;
        } catch (error) {
          this.selectedAudience = 'to-target-audience';
        }
      }
    }
  }

  @action
  async saveSettingsAndGoLive() {
    this.saveSettings.perform();
    this.setLiveButtonVisibility(false);
  }

  @dropTask
  *saveSettings() {
    try {
      yield this.messengerSettings.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'completed',
        object: `messenger_and_go_live.${
          this.selectedAudience === 'to-everyone' ? 'all_audience' : 'target_audience'
        }`,
        place: 'onboarding_home',
        section: 'choose-target-go-live',
      });
    } catch (error) {
      if (error) {
        console.error(error);
      }
    }
  }
}
