/* import __COLOCATED_TEMPLATE__ from './increase-conversions-with-chatbots.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default Component.extend({
  router: service(),
  onboardingHomeExternalStepService: service(),
  intercomEventService: service(),
  setupCustomBot: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'tour_started',
      object: 'increase_conversions_with_chatbots',
      ...this.step.analyticsMetadata,
    });
    this.onboardingHomeExternalStepService.start('increase_conversions_with_chatbots');
    this.router.transitionTo('apps.app.automation.workflows-overview', {
      queryParams: { target: 'non-user' },
    });
  }),
});
