/* import __COLOCATED_TEMPLATE__ from './learn.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { LEARN_MORE_CONTENT_IDS } from 'embercom/lib/outbound/constants';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class Learn extends Component {
  @service intercomEventService;
  @service intl;
  @service appService;
  @tracked showNewContentModal = false;
  @tracked newContentModalEntryPoint;

  @action
  hideModal() {
    this.showNewContentModal = false;
    this.newContentModalEntryPoint = undefined;
  }

  trackAnalytics(contentType, id) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_link',
      place: 'outbound_all',
      content_type: contentType,
      link_to: id,
    });
  }

  showGetStartedArticle = () => {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.getStarted);
    this.trackAnalytics(objectNames[objectTypes.article], LEARN_MORE_CONTENT_IDS.getStarted);
  };

  @action
  showViewPricingArticle() {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.pricing); // https://www.intercom.com/help/en/articles/9061614-intercom-plans-explained
    this.trackAnalytics(objectNames[objectTypes.article], LEARN_MORE_CONTENT_IDS.pricing);
  }

  @action
  showDemoModal() {
    this.showNewContentModal = true;
    this.newContentModalEntryPoint = 'demo_outbound_messages';
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'try_demo_link',
      place: 'outbound_all',
      section: 'learn_dropdown',
    });
  }

  @action
  showAskCommunity() {
    let link =
      'https://forum.intercom.com/s/topic/0TO2G000000XdO7WAK/messages-series-smart-campaigns';

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'ask_in_our_community_link',
      place: 'outbound_all',
      section: 'learn_dropdown',
    });

    safeWindowOpen(link, '_blank');
  }

  trackLearningOpened = () => {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_dropdown',
    });
  };
}
