/* import __COLOCATED_TEMPLATE__ from './address-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Brand from 'embercom/models/brand';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator, timeout } from 'ember-concurrency';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import type RouterService from '@ember/routing/router-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { isValidEmail as isValidEmailAddress } from 'embercom/lib/email';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import ENV from 'embercom/config/environment';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { type DomainEmailAddress } from './tab';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type SenderEmailVerificationService from 'embercom/services/sender-email-verification-service';
import type Admin from 'embercom/models/admin';

interface Args {
  onClose: () => void;
  brands: Brand[];
  updateMode: boolean;
  updateModeInitialState?: DomainEmailAddress;
  namesPerEmail?: any;
}

interface Signature {
  Args: Args;
}

interface EmailProviderOption {
  value: string;
  label: string;
}

interface AliasEmailBlocker {
  message: string;
  admins: Array<Admin>;
}

export type reasonData = {
  brands?: {
    brand_name: string;
  };
  proactive_support?: {
    total: string;
  };
};

interface AnalyticsEventOptions {
  action?: string;
  object?: string;
  context?: string;
  from_brand_id?: number | null;
  to_brand_id?: number | null;
  from_default?: boolean;
  to_default?: boolean;
  [key: string]: any; // Allows additional properties
}

export default class AddressSidebar extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @service declare realTimeEventService: $TSFixMe;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare senderEmailVerificationService: SenderEmailVerificationService;

  private DEFAULT_BRAND_ID = -1;
  @tracked updateMode = this.args.updateMode;
  @tracked openSectionId: null | string = null;
  @tracked selectedBrandId: number | null = this.DEFAULT_BRAND_ID;
  @tracked emailAddress = '';
  @tracked sendingEnabled = false;
  @tracked nameInputs: { id?: string; name: string }[] = [];
  @tracked senderEmailsBeforeUpdate: $TSFixMe[] = [];
  @tracked initiallySetBrand: Brand | undefined = undefined;
  @tracked nameIndexQueuedForCreation: number[] = [];
  @tracked emailIdsQueuedForDeletion: number[] = [];
  @tracked emailProviderOptions: EmailProviderOption[] = [];
  @tracked selectedDomain = '';
  @tracked forwardMailSetupAttempt: $TSFixMe | null = null;
  @tracked retryAttempts = 0;
  @tracked verification: boolean | null = null;
  @tracked confirmationLink = '';
  @tracked canSave = true;
  @tracked showDependencyModal = false;
  @tracked reasonsEntityCantBeDeleted: reasonData[] = [];
  @tracked finishedCheckingDependencies = true;
  @tracked initialBrandId: number | null = null;
  @tracked showAliasEmailAddressModal = false;
  @tracked admins = [];

  maxRetryAttempts = 20;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!this.updateMode) {
      this.nameInputs[0] = { name: '' };
    }
    if (this.updateMode) {
      this.populateSidebarWithInitialState();
      this.initialBrandId = this.args.updateModeInitialState?.brandId || this.DEFAULT_BRAND_ID;
    }
    this.emailProviderOptions = [
      { value: 'google', label: this.intl.t('onboarding.home.steps.forward-emails.Google') },
      { value: 'microsoft', label: this.intl.t('onboarding.home.steps.forward-emails.Microsoft') },
      { value: 'other', label: this.intl.t('onboarding.home.steps.forward-emails.Other') },
    ];
    this.selectedDomain = this.emailProviderOptions[0].value;
    this.realTimeEventService.on('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
    this.realTimeEventService.on(
      'ReceivedForwardEmailConfirmationLink',
      this,
      'initializeConfirmationLink',
    );
    this.realTimeEventService.on(
      'ReceivedForwardEmailConfirmationCode',
      this,
      'initializeConfirmationLink',
    );
  }

  willDestroy() {
    this.realTimeEventService.off('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
    this.realTimeEventService.off(
      'ReceivedForwardEmailConfirmationLink',
      this,
      'initializeConfirmationLink',
    );
    this.realTimeEventService.off(
      'ReceivedForwardEmailConfirmationCode',
      this,
      'initializeConfirmationLink',
    );
    super.willDestroy();
  }

  populateSidebarWithInitialState() {
    if (this.args.updateModeInitialState?.brandId) {
      this.selectedBrandId = this.args.updateModeInitialState.brandId;
    }
    this.emailAddress = this.args.updateModeInitialState?.email || '';

    if (this.args.updateModeInitialState?.senderEmailAddresses.length) {
      this.senderEmailsBeforeUpdate = this.args.updateModeInitialState.senderEmailAddresses;
      this.sendingEnabled = true;
      this.nameInputs = this.args.updateModeInitialState.senderEmailAddresses.map((sea) => ({
        name: sea.name,
        id: sea.id,
      }));
    }

    this.verification = this.args.updateModeInitialState?.forwardingEnabled || false;
  }

  get isVerifyEmailForwardingRunning() {
    return taskFor(this.verifyEmailForwarding).isRunning;
  }

  // handle the case where a user has selected a brand, but the list has not been loaded yet
  // and fall back to the default for the moment, as ic-select explodes if you give it a value it doesn't know about
  get selectedBrandIdFromList() {
    let foundBrand = this.brandsList.find((brand) => brand.value === this.selectedBrandId);
    if (foundBrand) {
      return foundBrand.value;
    } else {
      return this.DEFAULT_BRAND_ID;
    }
  }

  get brandsList() {
    let defaultBrandOption = {
      text: this.appService.app.name,
      value: this.DEFAULT_BRAND_ID,
    };

    return [
      defaultBrandOption,
      ...this.customBrands
        .map((brand: Brand) => ({
          text: brand.name,
          value: brand.id,
        }))
        .sort((a: any, b: any) => {
          return a.text.localeCompare(b.text);
        }),
    ];
  }

  get selectedBrand() {
    if (this.selectedBrandId != null) {
      let selectedBrand = this.brandsList.find((brand) => brand.value === this.selectedBrandId);
      return selectedBrand?.text;
    }
    let defaultBrand = this.brandsList.find((brand) => brand.value === this.DEFAULT_BRAND_ID);
    return defaultBrand?.text;
  }

  get customBrands() {
    return this.args.brands.filter((brand) => !brand.isDefault);
  }

  get inboxEmailAddress() {
    return this.appService.app.inbox_email;
  }

  get isValidEmail() {
    return isValidEmailAddress(this.emailAddress);
  }

  get hasForwardingEmail() {
    return this.emailAddress !== '';
  }

  get analyticsMetadata() {
    return {
      place: 'settings',
      owner: 'team-product-exploration',
    };
  }

  get showBanner() {
    if (!this.args.updateMode) {
      return false;
    }

    return this.args.updateModeInitialState?.senderEmailAddressSettings?.verified === false;
  }

  get senderEmailAddress() {
    let senderEmailAddress;
    if (this.args.updateModeInitialState?.senderEmailAddresses) {
      senderEmailAddress = this.args.updateModeInitialState?.senderEmailAddresses.filter(
        (sea: $TSFixMe) => {
          return sea.email === this.emailAddress;
        },
      )[0];
    }
    return senderEmailAddress;
  }

  get customizationUrl() {
    return this.router.urlFor('apps.app.settings.channels.email', this.appService.app.id, {
      queryParams: { tab: 'customisation' },
    });
  }

  @action
  closeSidebar() {
    this.nameIndexQueuedForCreation = [];
    this.emailIdsQueuedForDeletion = [];
    this.args.onClose();
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'close_address_sidebar',
      place: 'address_sidebar',
      context: this.updateMode ? 'update' : 'create',
      brand_id: this.normalizedBrandId,
      email: this.emailAddress,
      sending_enabled: this.sendingEnabled,
    });
  }

  @action
  onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action
  onBrandSelect(brandId: number) {
    this.selectedBrandId = brandId;
  }

  @action
  toggleSending() {
    this.sendingEnabled = !this.sendingEnabled;
    this._updateCanSave();

    if (this.sendingEnabled && this.nameInputs.length === 0) {
      let selectedBrand = this.brandsList.find((brand) => brand.value === this.selectedBrandId);
      this.nameInputs = [{ name: selectedBrand?.text || this.appService.app.name }];
    }
  }

  @action
  addNewNameInput() {
    this.nameInputs = [...this.nameInputs, { name: '' }];
    this._updateCanSave();
  }

  @action
  removeNameAtIndex(index: number) {
    this.nameInputs = this.nameInputs.filter((_, i) => i !== index);
    this._updateCanSave();
  }

  @action
  updateNameInput(index: number, event: $TSFixMe) {
    this.nameInputs[index].name = event.target.value;
    this._updateCanSave();
  }

  @action
  handleSave() {
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'save_email_address',
      context: this.updateMode ? 'update' : 'create',
    });

    if (this.updateMode) {
      if (this.initialBrandId !== this.selectedBrandId) {
        this._trackAnalyticsEvent({
          action: 'changed',
          object: 'brand',
          context: 'update',
          from_brand_id: this.initialBrandId,
          to_brand_id: this.selectedBrandId,
          from_default: this.initialBrandId === this.DEFAULT_BRAND_ID,
          to_default: this.selectedBrandId === this.DEFAULT_BRAND_ID,
        });
      }

      taskFor(this.updateTask).perform();
    } else {
      taskFor(this.createTask).perform();
    }
  }

  @action
  handleDelete(): Promise<void> {
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'delete_email_address',
      context: 'settings',
      email: this.emailAddress,
      brand_id: this.normalizedBrandId,
      names: this.sendingEnabled ? this.nameInputs : [],
    });
    return taskFor(this.deleteTask).perform();
  }

  @action
  toggleCustomDeletionModal() {
    return (this.showAliasEmailAddressModal = !this.showAliasEmailAddressModal);
  }

  @action
  setSelectedDomain(value: string) {
    if (this.selectedDomain !== value) {
      this.selectedDomain = value;
    }
  }

  @action
  handleInstructionsButton() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/articles/6522819-automatically-forward-emails-to-the-help-desk',
      '_blank',
    );
  }

  @action
  openConfirmationLink() {
    safeWindowOpen(this.confirmationLink, '_blank');
  }

  _trackAnalyticsEvent(options: AnalyticsEventOptions) {
    this.intercomEventService.trackAnalyticsEvent({
      email: this.emailAddress,
      ...options,
      ...this.analyticsMetadata,
    });
  }

  async _createSetupAttempt() {
    let forwardMailSetupAttempt = await this.store
      .createRecord('forward-mail-setup-attempt', {
        appId: this.appService.id,
        forwardingEmailAddress: this.emailAddress,
      })
      .save();

    this.forwardMailSetupAttempt = forwardMailSetupAttempt;
  }

  async _sendForwardingEmail() {
    await ajax({
      url: '/ember/forward_emails',
      data: JSON.stringify({
        app_id: this.appService.app.id,
        email: this.emailAddress,
      }),
      type: 'POST',
    });
  }

  async _verifyEmailForwarding() {
    return await ajax({
      url: '/ember/forward_mail_setup_attempts/verify',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
        forwarding_email_address: this.emailAddress,
      },
    });
  }

  _handleForwardMailVerifiedMessage() {
    this._updateVerification(true);
    if (this.guideLibraryService.canUseGuideLibraryService) {
      this.guideLibraryService.markStepCompleted(
        'guide_library_foundational_steps_setup_omnichannel_v2',
      );
    }
    this._trackAnalyticsEvent({
      action: 'completed',
      object: 'send_test_email',
      context: 'nexus_event',
    });
  }

  @action
  updateDependencyCheckStatus(status: boolean) {
    this.finishedCheckingDependencies = status;
  }

  async initializeConfirmationLink() {
    let response = await this._fetchConfirmationCodeOrLink();
    this.confirmationLink = response['confirmation_code'];
  }

  async _fetchConfirmationCodeOrLink() {
    return await ajax({
      url: '/ember/forward_email_confirmation_code',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    });
  }

  _updateVerification(status: boolean) {
    this.verification = status;
  }

  _updateCanSave() {
    this.canSave = !this.sendingEnabled || this.nameInputs.every((input) => input.name !== '');
  }

  @action
  openBrandsSettingsPage() {
    this._trackAnalyticsEvent({
      action: 'clicked',
      place: 'address_sidebar',
      object: 'manage_brands_page',
      context: 'email_address_sidebar',
    });
    let url = this.router.urlFor('apps.app.settings.workspace.brands', this.appService.app.id);
    safeWindowOpen(url, '_blank');
  }

  get normalizedBrandId(): null | string {
    if (!this.selectedBrandId) {
      return null;
    }
    if (this.selectedBrandId === this.DEFAULT_BRAND_ID) {
      return null;
    }
    return this.selectedBrandId.toString();
  }

  private pruneAddresses(settings: SenderEmailAddressSettings) {
    // seems to be a bug in our version of ember-data where we end up with both the
    // saved & unsaved records here, need to look at whether we can fix this with a local ID?
    // https://github.com/emberjs/data/issues/7354
    let unsaved = settings.senderEmailAddresses.filter((x) => x.isNew);
    settings.senderEmailAddresses.removeObjects(unsaved);
  }

  private buildSenderEmailAddresses(): CustomEmailAddress[] {
    let names = this.sendingEnabled ? this.nameInputs : [];
    let settings = this.args.updateModeInitialState?.senderEmailAddressSettings;
    let existing = settings?.senderEmailAddresses.toArray() || [];
    let existingById = new Map(existing.map((x) => [x.id, x]));

    let senderEmailAddresses: CustomEmailAddress[] = [];

    names.forEach((nameInput) => {
      if (!nameInput.id) {
        senderEmailAddresses.push(
          this.store.createRecord('custom-email-address', {
            name: nameInput.name,
            status: 'Unverified email',
          }),
        );
      } else {
        let existingRecord = existingById.get(nameInput.id);
        if (existingRecord) {
          existingRecord.name = nameInput.name;
          senderEmailAddresses.push(existingRecord);
        }
      }
    });
    return senderEmailAddresses;
  }

  @dropTask
  *updateTask(): TaskGenerator<void> {
    try {
      let settings = this.args.updateModeInitialState?.senderEmailAddressSettings;

      // we won't have settings if the email the user is editing only has a
      // company email address, in which case we need to create the settings
      if (settings) {
        settings.brandId = this.normalizedBrandId;

        let addresses = this.buildSenderEmailAddresses();
        settings.senderEmailAddresses.clear();
        settings.senderEmailAddresses.pushObjects(addresses);

        yield settings.save();
        this.pruneAddresses(settings);
      } else {
        let newSettings = this.store.createRecord('sender-email-address-settings', {
          email: this.emailAddress,
          brandId: this.normalizedBrandId,
          senderEmailAddresses: this.buildSenderEmailAddresses(),
        });

        yield newSettings.save();
        this.pruneAddresses(newSettings);
      }

      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.channels.email.addresses.sidebar.updated-email-successfully'),
      );

      this.intercomEventService.trackAnalyticsEvent(
        {
          action: 'clicked',
          object: 'update_email_address',
          app_id: this.appService.app.id,
          admin_id: Number(this.appService.app.currentAdmin.id),
          email: this.emailAddress,
          names: this.sendingEnabled ? this.nameInputs : [],
          brand_id: this.normalizedBrandId,
        },
        true,
      );

      this.closeSidebar();
    } catch (error) {
      console.error('updateTask() error', error);
      let message =
        this.getErrorMessage(error) ||
        this.intl.t('new-settings.channels.email.addresses.sidebar.failed-to-update-address');
      this.notificationsService.notifyResponseError(error, {
        default: message,
      });
      return;
    }
  }

  @dropTask
  *createTask(): TaskGenerator<void> {
    let settings;
    try {
      settings = this.store.createRecord('sender-email-address-settings', {
        email: this.emailAddress,
        brandId: this.normalizedBrandId,
        senderEmailAddresses: this.buildSenderEmailAddresses(),
      });

      yield settings.save();
      this.pruneAddresses(settings);

      this.notificationsService.notifyConfirmation(
        this.intl.t('new-settings.channels.email.addresses.sidebar.successfully-created-address'),
      );

      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_email_address',
        non_default_brand: this.selectedBrandId !== this.DEFAULT_BRAND_ID,
        app_id: this.appService.app.id,
        admin_id: this.appService.app.currentAdmin.id,
        brand_id: this.selectedBrandId,
        names: this.sendingEnabled ? this.nameInputs : [],
        email: this.emailAddress,
      });

      this.closeSidebar();
    } catch (error) {
      let message =
        this.getErrorMessage(error) ||
        this.intl.t('new-settings.workspace.brands.new-address-modal.failed-to-create-address');
      this.notificationsService.notifyResponseError(error, {
        default: message,
      });
      settings?.unloadRecord?.();
    }
  }

  @dropTask
  *deleteTask(): TaskGenerator<void> {
    this.updateDependencyCheckStatus(false);
    try {
      let settings = this.args.updateModeInitialState?.senderEmailAddressSettings;

      if (settings) {
        yield settings.destroyRecord();
        this.pruneAddresses(settings);

        if (this.reasonsEntityCantBeDeleted.length === 0) {
          this.handleEmailDeletionSuccess();
          // refresh company email addresses
          // This is because the backend deletes the company email address if present
          yield this.appService.app.refreshCompanyEmailAddresses();
        }
      }
    } catch (error) {
      let response = error.jqXHR.responseJSON;
      if (response.statusCode === 428) {
        let aliasEmailBlocker = response.reasons?.find(
          (reason: { alias_email_blocker?: AliasEmailBlocker }) => reason.alias_email_blocker,
        )?.alias_email_blocker;

        if (aliasEmailBlocker?.message === 'Matching alias email found') {
          this.toggleCustomDeletionModal();
          this.admins = aliasEmailBlocker.admins ?? [];
        } else {
          this.reasonsEntityCantBeDeleted = response.reasons ?? [];
        }
      } else {
        console.error('Error deleting the record:', error);
        this.updateDependencyCheckStatus(true);
      }
      return;
    }
  }

  private handleEmailDeletionSuccess() {
    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.channels.email.addresses.sidebar.deleted-email-successfully'),
    );

    this.intercomEventService.trackAnalyticsEvent(
      {
        action: 'clicked',
        object: 'delete_email_address',
        app_id: this.appService.app.id,
        admin_id: Number(this.appService.app.currentAdmin.id),
        email: this.emailAddress,
        names: this.sendingEnabled ? this.nameInputs : [],
        brand_id: this.normalizedBrandId,
      },
      true,
    );

    this.closeSidebar();
  }

  private getErrorMessage(error: any) {
    // If `error` doesn't conform to this shape, just fall back to a default
    // error message (as determined by the callsite).
    try {
      if (error.jqXHR?.responseJSON?.errors?.length) {
        return error.jqXHR.responseJSON.errors[0].message;
      }
    } catch {
      return null;
    }
  }

  @action
  async resendVerificationEmail() {
    if (this.args.updateModeInitialState?.senderEmailAddressSettings?.id) {
      await this.senderEmailVerificationService.resendVerificationEmail({
        senderEmailAddressSettingsId:
          this.args.updateModeInitialState?.senderEmailAddressSettings?.id,
        appId: this.appService.app.id,
        adminId: this.appService.app.currentAdmin.id,
      });
    }
  }

  @dropTask
  *verifyEmailForwarding(): TaskGenerator<any> {
    this._trackAnalyticsEvent({ action: 'clicked', object: 'send_test_email' });
    let verification;
    yield this._createSetupAttempt();
    yield this._sendForwardingEmail();

    this.retryAttempts = 0;
    verification = yield this._verifyEmailForwarding();
    try {
      while (!verification && this.retryAttempts < this.maxRetryAttempts) {
        yield timeout(ENV.APP._1000MS); // wait for nexus event
        this.retryAttempts += 1;
        verification = yield this._verifyEmailForwarding();
      }
    } catch (e) {
      captureException(e, {
        fingerprint: ['forward-emails', 'verify_email_forwarding'],
      });
      this.retryAttempts = 0;
    }

    if (!this.verification) {
      this._updateVerification(verification);
      this._trackAnalyticsEvent({
        action: verification ? 'completed' : 'failed',
        context: 'verify_endpoint',
        object: 'send_test_email',
      });
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::AddressSidebar': typeof AddressSidebar;
  }
}
