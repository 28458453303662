/* import __COLOCATED_TEMPLATE__ from './survey.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action, set } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import { inject as service } from '@ember/service';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Args {
  closeModal: () => void;
  survey: any;
}

interface Signature {
  Element: any;
  Args: Args;
}

export default class Survey extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare guideLibraryService: GuideLibraryService;

  @tracked currentStep = 1;
  @tracked selectedAnswers: string[] = [];
  @tracked selectedFollowupQuestion: any | null = null;
  @tracked selectedFollowupAnswers: string[] = [];
  @tracked freeTextValues: { identifier: string; value: string }[] = [];

  get currentQuestion() {
    return this.args.survey?.questions[this.currentStep - 1];
  }

  get questions() {
    return this.args.survey?.questions;
  }

  get totalSteps() {
    return this.questions ? this.questions.length : 0;
  }

  get isContinueDisabled() {
    if (this.currentQuestion.type === 'multiple_choice') {
      return this.selectedAnswers.length === 0;
    } else if (this.currentQuestion.type === 'single_choice') {
      if (this.selectedFollowupQuestion) {
        return this.selectedFollowupAnswers.length === 0;
      } else {
        return this.selectedAnswers.length === 0;
      }
    }
    return false;
  }

  sendUpdateAnswer(url: string, data: any) {
    try {
      post(url, data);
    } catch (error) {
      console.error(error);
      captureException(error, {
        fingerprint: ['lead_profile_survey.update_answer', error.message],
        extra: {
          admin_id: data.app_id,
        },
        tags: {
          responsibleTeam: 'team-self-serve',
        },
      });
    }
  }

  @action
  submitAnswer() {
    this.sendUpdateAnswer('/ember/onboarding/lead_profile_survey/update_answer', {
      app_id: this.args.survey.id,
      answers: this.selectedAnswers,
      survey_identifier: this.args.survey.identifier,
      question_identifier: this.currentQuestion.identifier,
      state: 'answered',
      free_text_values: this.freeTextValues,
    });

    if (this.selectedFollowupQuestion) {
      this.sendUpdateAnswer('/ember/onboarding/lead_profile_survey/update_answer', {
        app_id: this.args.survey.id,
        answers: this.selectedFollowupAnswers,
        survey_identifier: this.args.survey.identifier,
        question_identifier: this.selectedFollowupQuestion.identifier,
        state: 'answered',
        free_text_values: this.freeTextValues,
      });
      if (
        this.selectedFollowupQuestion.identifier ===
          'do_you_have_an_existing_support_solution_yes' &&
        this.selectedFollowupAnswers.firstObject === 'zendesk'
      ) {
        this.guideLibraryService.selectedExistingSupportSolutionZendesk = true;
      }
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'profiling_survey_question_continue_btn',
      context: 'lead_profile_survey_modal.question_answered',
      survey_identifier: this.args.survey.identifier,
      question_identifier: this.currentQuestion.identifier,
      section: 'app.components.onboarding.guide-library.profiling-survey.survey',
    });

    if (this.currentStep === this.totalSteps) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'closed',
        object: 'profiling_survey',
        context: 'lead_profile_survey_modal',
        survey_identifier: this.args.survey.identifier,
        section: 'app.components.onboarding.guide-library.profiling-survey.survey',
      });
      this.args.closeModal();
      return;
    }
    this.currentStep += 1;
    this.selectedAnswers = [];
    this.selectedFollowupAnswers = [];
    this.selectedFollowupQuestion = null;
    this.freeTextValues = [];
  }

  @action
  skipQuestion() {
    this.sendUpdateAnswer('/ember/onboarding/lead_profile_survey/update_answer', {
      app_id: this.args.survey.id,
      survey_identifier: this.args.survey.identifier,
      question_identifier: this.currentQuestion.identifier,
      state: 'skipped',
    });
    this.intercomEventService.trackAnalyticsEvent({
      action: 'skipped',
      object: 'profiling_survey_question_skip_btn',
      context: 'lead_profile_survey_modal.question_answered',
      survey_identifier: this.args.survey.identifier,
      question_identifier: this.currentQuestion.identifier,
      section: 'app.components.onboarding.guide-library.profiling-survey.survey',
    });

    if (this.selectedFollowupQuestion) {
      this.sendUpdateAnswer('/ember/onboarding/lead_profile_survey/update_answer', {
        app_id: this.args.survey.id,
        survey_identifier: this.args.survey.identifier,
        question_identifier: this.selectedFollowupQuestion.identifier,
        state: 'skipped',
      });
    }

    if (this.currentStep === this.totalSteps) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'closed',
        object: 'profiling_survey',
        context: 'lead_profile_survey_modal',
        survey_identifier: this.args.survey.identifier,
        section: 'app.components.onboarding.guide-library.profiling-survey.survey',
      });
      this.args.closeModal();
      return;
    }
    this.currentStep += 1;
    this.selectedAnswers = [];
    this.selectedFollowupAnswers = [];
    this.selectedFollowupQuestion = null;
    this.freeTextValues = [];
  }

  @action
  answerClicked(answer: any, followup = false) {
    if (followup) {
      if (this.selectedFollowupQuestion.type === 'single_choice') {
        set(this, 'selectedFollowupAnswers', [answer.identifier]);
      } else if (this.selectedFollowupAnswers.includes(answer.identifier)) {
        set(
          this,
          'selectedFollowupAnswers',
          this.selectedFollowupAnswers.filter((a) => a !== answer.identifier),
        );
      } else {
        set(this, 'selectedFollowupAnswers', [...this.selectedFollowupAnswers, answer.identifier]);
      }
    } else {
      if (this.currentQuestion.type === 'single_choice') {
        set(this, 'selectedAnswers', [answer.identifier]);
      } else if (this.selectedAnswers.includes(answer.identifier)) {
        set(
          this,
          'selectedAnswers',
          this.selectedAnswers.filter((a) => a !== answer.identifier),
        );
      } else {
        set(this, 'selectedAnswers', [...this.selectedAnswers, answer.identifier]);
      }
      if (answer.follow_up_question) {
        set(this, 'selectedFollowupQuestion', answer.follow_up_question);
        set(this, 'selectedFollowupAnswers', []);
      } else {
        set(this, 'selectedFollowupQuestion', null);
      }
    }
  }

  @action
  setAnswerFreeTextValue(answer: any, value: string) {
    if (!answer.is_free_text_field) {
      return;
    }
    let existingValue = null;
    if (this.selectedAnswers.includes(answer.identifier)) {
      existingValue = this.freeTextValues.find((e) => e.identifier === answer.identifier);
    } else if (this.selectedFollowupAnswers.includes(answer.identifier)) {
      existingValue = this.freeTextValues.find((e) => e.identifier === answer.identifier);
    }

    if (!existingValue) {
      this.freeTextValues.push({ identifier: answer.identifier, value });
    } else if (value === '') {
      this.freeTextValues.removeObject(existingValue);
    } else {
      existingValue.value = value;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::ProfilingSurvey::Survey': typeof Survey;
  }
}
