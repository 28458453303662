/* import __COLOCATED_TEMPLATE__ from './set-up-your-macro-component.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import moment from 'moment-timezone';

const MACRO_EXAMPLE = {
  blocks: [
    { type: 'paragraph', text: '👋🏼 Hi {{first_name|fallback:"you,"}}', class: 'no-margin' },

    { type: 'paragraph', text: '', class: 'no-margin' },
    {
      type: 'paragraph',
      text: 'Thanks for letting us know. I&#x27;ve logged this issue for further investigation with our engineers and will keep you posted on any updates!',
      class: 'no-margin',
    },
  ],
  name: 'Bug report [Example]',
  visibleToTeamIds: null,
  visibility: 'everyone',
  has_updates: false,
  types: ['opener', 'reply', 'note'],
  actions: [
    {
      id: '1',
      type: 'snooze-conversation',
      actionData: { snoozed_until: 'tomorrow', author_timezone: moment.tz.guess() },
    },
  ],
};

export default class SetUpYourMacroComponent extends Component {
  @service appService;
  @service store;
  @service notificationsService;
  @service onboardingHomeService;
  @service intercomEventService;
  @service permissionsService;
  @service intl;
  @service onboardingHomeExternalStepService;
  @service router;

  @tracked editorDirty = false;
  @tracked macro;
  @tracked showMacroEditor = false;
  @tracked allowEditing = true;

  @dropTask
  *setupMacroOnLoad() {
    try {
      if (this.isMarkedCompleted) {
        this.macro = MACRO_EXAMPLE;
        this.allowEditing = false;
      } else {
        let allMacros = yield this.store.findAll('saved-reply');
        this.macro = allMacros.find((macro) => macro.name === MACRO_EXAMPLE.name);
        if (!this.macro) {
          this.macro = yield this.createNewMacro();
        }
      }
      this.editorDirty = false;
      this.showMacroEditor = true;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: 'onboarding_home_setup_your_first_macro',
        place: 'onboarding-home/set-up-your-first-macro-step',
      });
    } catch (e) {
      console.error(e);
      this.notificationsService.notifyError(
        this.intl.t('inbox.manage-macros.saved-replies.export-error'),
      );
    }
  }

  get isMergedMacroStep() {
    return this.args.step?.modelData && this.args.step.modelData.showMergedStep;
  }

  get app() {
    return this.appService.app;
  }

  get isMarkedCompleted() {
    let step = this.onboardingHomeService.guide.findStepByIdentifier('setup_first_macro');
    return step && step.completed;
  }

  get loadMacroEditor() {
    return this.macro && this.showMacroEditor;
  }

  async createNewMacro() {
    let macro = MACRO_EXAMPLE;
    macro.app_id = this.app.id;
    let macroActiveRecord = await this.store.createRecord('saved-reply', macro);
    await macroActiveRecord.save();
    return macroActiveRecord;
  }

  @action
  saveSelectedReply(macro, newName, composer, selectedTeams, newVisibility, newTypes) {
    let blocks = composer.getBlocks();
    macro.blocks = blocks;
    macro.name = newName;
    macro.visibleToTeamIds = selectedTeams;
    macro.visibility = newVisibility;
    if (newTypes) {
      macro.types = newTypes;
    }

    return this.saveMacro.perform(macro);
  }

  @dropTask
  *saveMacro(reply) {
    if (reply.validationMessage !== undefined) {
      this.notificationsService.notifyError(reply.validationMessage);
      return;
    }

    if (!reply.availabilityValid) {
      this.notificationsService.notifyError(
        this.intl.t('inbox.manage-macros.saved-replies.availability-error'),
      );
      return;
    }

    try {
      yield reply.save();
      this.app.refreshSavedReplies();
    } catch (error) {
      console.error(error);

      if (error.jqXHR && error.jqXHR.status === 422) {
        if (error.jqXHR.responseJSON.errors.name[0] === 'has already been taken') {
          this.notificationsService.notifyError(
            this.intl.t('inbox.manage-macros.saved-replies.name-taken-error'),
          );
          return;
        }
      }

      this.notificationsService.notifyError(
        this.intl.t('inbox.manage-macros.saved-replies.save-error', {
          type: this.intl.t('inbox.manage-macros.saved-replies.update'),
        }),
      );
      return;
    }

    this.args.completionCallback();
    this.notificationsService.notifyConfirmation(
      this.intl.t('inbox.manage-macros.saved-replies.save-success'),
    );
    this.editorDirty = false;
  }

  @action
  updateDirtyState(newState) {
    if (newState !== this.editorDirty) {
      this.editorDirty = newState;
    }
  }

  @action
  closeStep() {
    this.args.accordionAPI.closeSection('setup_first_macro');
  }

  @action
  markAsCompleted() {
    this.args.completionCallback();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'mark_as_completed',
    });
  }

  @dropTask
  *startTour() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      action: 'clicked',
      object: `${this.args.step.identifier}_tour_${this.args.step.productTourId}`,
    });

    let params = {
      app_id: this.appService.app.id,
    };
    let response = yield post(
      '/ember/onboarding/customer_support_channels/create_demo_conversation',
      params,
    );

    this.onboardingHomeExternalStepService.startAfterTransition(this.args.step.identifier, {
      source: 'home/set-up-your-macro-component',
      method: 'startAfterTransition',
    });

    return this.router.transitionTo(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      this.appService.app.id,
      'shared',
      'all',
      response.conversation_id,
      {
        queryParams: {
          external_step: 'setup_first_macro', // 'merged_macro_step',
          shouldShowStepBanner: true,
        },
      },
    );
  }
}
