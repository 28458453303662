/* import __COLOCATED_TEMPLATE__ from './question.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  question: any;
  followup: boolean;
  selectedAnswers?: string[];
  selectedFollowupQuestion?: any;
  selectedFollowupAnswers?: string[];
  answerClicked: (answer: any, followup: boolean) => void;
  setAnswerFreeTextValue: (answer: any, value: string) => void;
}
interface Signature {
  Element: any;
  Args: Args;
}
export default class Question extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: `question_${args.question.identifier}`,
      context: 'survey_question',
      section: 'app.components.onboarding.guide-library.profiling-survey.question',
    });
  }

  get answers() {
    if (!this.args.question.randomise_answers) {
      return this.args.question.answers;
    }

    return this.randomiseAnswers();
  }

  randomiseAnswers() {
    let answers: $TSFixMe[] = [];
    let freeTextAnswers: $TSFixMe[] = [];

    this.args.question.answers.forEach((answer: $TSFixMe) => {
      if (answer.is_free_text_field) {
        freeTextAnswers.push(answer);
      } else {
        answers.push(answer);
      }
    });

    for (let i = answers.length; i > 1; i--) {
      let j = Math.floor(Math.random() * i);
      [answers[i], answers[j]] = [answers[j], answers[i]];
    }
    answers.push(...freeTextAnswers);
    return answers;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::ProfilingSurvey::Question': typeof Question;
  }
}
