/* import __COLOCATED_TEMPLATE__ from './expanded-body.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type Step } from 'embercom/objects/onboarding/checklist/step';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

export interface Args {
  steps: Step[];
  collapseChecklist: () => void;
  animateStepId: string;
  onCTASuccess?: (stepIdentifier: string) => void;
}

export default class ExpandedBody extends Component<Args> {
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare intercomEventService: $TSFixMe;

  @action
  onOpenSectionChange(stepId: string) {
    if (this.greatGuidanceService.currentStepId) {
      this.trackAnalyticsEvent(this.greatGuidanceService.currentStepId, 'closed');
    }
    this.greatGuidanceService.currentStepId = stepId;
    this.trackAnalyticsEvent(stepId, 'opened');
  }

  @action
  sectionDidOpen() {}

  @action
  sectionDidClose() {}

  trackAnalyticsEvent(stepID: string, action: 'opened' | 'closed') {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: `widget.steps.${stepID}-section`,
      place: 'floating-widget',
      section: 'checklist',
      context: 'floating-widget.steps.step.opened',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::Checklist::ExpandedBody': typeof ExpandedBody;
    'onboarding/floating-widget/checklist/expanded-body': typeof ExpandedBody;
  }
}
